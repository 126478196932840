import { Typography, Paper, Grid, Divider } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Option from './Option';

const AdditionalOptions = ({ additionalOptions, setAdditionalOptions }) => {
  const { t } = useTranslation();

  const handleOptionSelected = (id, checked) => {
    const options = [...additionalOptions];
    const idx = options.findIndex(item => item.productId === Number(id));
    options[idx] = { ...options[idx], isChecked: checked };
    setAdditionalOptions(options);
  };
  return (
    <>
      <Typography
        component='span'
        variant='h6'
        style={{ marginBottom: 16, marginTop: 16, marginLeft: 16, display: 'inline-block' }}
      >
        {t('protection.additionalOptions')}
      </Typography>
      <Paper>
        <Grid container direction='column'>
          <Grid item style={{ padding: 16 }}>
            {additionalOptions.map((option, idx) => (
              <React.Fragment key={idx}>
                <Option option={option} handleOptionSelected={handleOptionSelected} />
                {idx !== additionalOptions.length - 1 && <Divider style={{ marginTop: 16 }} />}
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default AdditionalOptions;
