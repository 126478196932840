/**
 *
 * @param {*} currencyCode
 * @param {*} price
 * @param {*} options locale. Default locale is "en-GB"
 * @returns
 */
export const currencyFormatter = (currencyCode, price, options) => {
  if (!currencyCode) return price;
  else {
    const locale = options && options.locale ? options.locale : 'en-US';
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
    });
    return formatter.format(price);
  }
};
