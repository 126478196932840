import React from 'react';
import {
  faSnowflake,
  faSuitcase,
  faSuitcaseRolling,
} from '@fortawesome/free-solid-svg-icons';
import { faPerson } from '@fortawesome/free-solid-svg-icons/faPerson';
import { faChildDress } from '@fortawesome/free-solid-svg-icons/faChildDress';
import Seats from './featureIcons/seat.svg';
import Doors from './featureIcons/car-door.svg';
import Gearbox from './featureIcons/gearbox.svg';
import { Grid, Tooltip, useTheme, useMediaQuery } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/lab';
import CarFeatureNumber from './Components/CarFeatureNumber';
import CarFeatureToolTip from './Components/CarFeatureToolTip';
import {
  FeatureIcon,
  FeatureIconFontAwesome,
  IconWrapper,
} from './styles/CarFeatures.styled';
import { getFuelTypeAndACBySippCode } from '../../../../../../utils/utils';

const FeatureNumber = styled.div`
  vertical-align: middle;
  font-size: 16px;
  margin: 0 ${({ theme }) => (theme.direction === 'rtl' ? '4px' : 0)} 0
    ${({ theme }) => (theme.direction === 'ltr' ? '4px' : 0)};
`;

const FeatureComponent = ({ isLoading, icon, number, isImage, src }) => (
  <>
    {isLoading ? (
      <Skeleton animation="wave" variant="text" width={20} height={30} />
    ) : (
      <>
        {!isImage ? (
          <div style={{ display: 'flex' }}>
            <IconWrapper>
              <FeatureIconFontAwesome icon={icon} />
            </IconWrapper>
            <FeatureNumber>{number}</FeatureNumber>
          </div>
        ) : (
          <div style={{ display: 'flex' }}>
            <FeatureIcon src={src} /> <FeatureNumber>{number}</FeatureNumber>
          </div>
        )}
      </>
    )}
  </>
);

const CarFeatures = ({
  transmissionType,
  numberOfSeats,
  numberOfLargeBags,
  numberOfSmallBags,
  numberOfDoors,
  numberOfKids,
  numberOfAdults,
  isLoading,
  isMobileRow,
  sippCode,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const translations = {
    transmissionType:
      transmissionType === 'Manual'
        ? t('carcard.carfeatures.manualgearShort')
        : t('carcard.carfeatures.automaticgearShort'),
  };
  return (
    <>
      {!isMobile ? (
        <Grid container spacing={3}>
          {isLoading ? (
            <Skeleton width={430} height={40} style={{ marginLeft: 12 }} />
          ) : (
            <>
              {getFuelTypeAndACBySippCode(sippCode, t).ac && (
                <Grid item>
                  <CarFeatureToolTip
                    isMobile={isMobile}
                    title={t('carcard.carfeatures.ac')}
                    disableHoverListener={isMobile}>
                    <span
                      style={{
                        marginLeft: theme.direction === 'ltr' ? 4 : 0,
                        marginRight: theme.direction === 'rtl' ? 4 : 0,
                      }}>
                      <FeatureIconFontAwesome icon={faSnowflake} />
                      
                    </span>
                  </CarFeatureToolTip>
                </Grid>
              )}{' '}
              <Grid item iszero={numberOfSmallBags}>
                <Tooltip
                  disableHoverListener={isMobile}
                  title={
                    t('carcard.canhold') +
                    ` ${t('carcard.carfeatures.smallbags.smallbags', {
                      count: numberOfSmallBags,
                    })}`
                  }
                  arrow>
                  <div>
                    <FeatureIconFontAwesome icon={faSuitcaseRolling} />{' '}
                    <span>{numberOfSmallBags}</span>
                  </div>
                </Tooltip>
              </Grid>
              <CarFeatureToolTip
                isMobile={isMobile}
                disableHoverListener={isMobile}
                title={
                  t('carcard.canhold') +
                  ' ' +
                  `${t('carcard.carfeatures.largebag.largebag', {
                    count: numberOfLargeBags,
                  })}`
                }
                arrow>
                <Grid item iszero={numberOfLargeBags}>
                  <FeatureIconFontAwesome icon={faSuitcase} />
                  <CarFeatureNumber
                    direction={theme.direction}
                    number={numberOfLargeBags}
                  />
                </Grid>
              </CarFeatureToolTip>
              <Grid item>
                <Tooltip
                  disableHoverListener={isMobile}
                  title={t('carcard.carfeatures.gearboxtype', {
                    type:
                      transmissionType === 'Manual'
                        ? t('filters.manual')
                        : t('filters.automatic'),
                  })}
                  arrow>
                  <FeatureIcon src={Gearbox} alt={Gearbox} />
                </Tooltip>
                <span
                  style={{
                    margin: `0 ${theme.direction === 'rtl' ? '4px' : 0} 0 ${
                      theme.direction === 'ltr' ? '4px' : 0
                    }`,
                  }}>
                  {translations.transmissionType.charAt(0)}
                </span>
              </Grid>
              <Grid item>
                <CarFeatureToolTip
                  number={numberOfDoors}
                  isMobile={isMobile}
                  title={t('carcard.carfeatures.doors.doors', {
                    count: numberOfDoors,
                  })}>
                  <FeatureIcon src={Doors} />
                </CarFeatureToolTip>{' '}
              </Grid>
              {numberOfSeats > -1 && (
                <Grid item>
                  <CarFeatureToolTip
                    number={numberOfSeats}
                    isMobile={isMobile}
                    title={t(
                      'carcard.carfeatures.numberofseats.numberofseats',
                      {
                        count: parseInt(numberOfSeats),
                      }
                    )}>
                    <FeatureIcon src={Seats} />
                  </CarFeatureToolTip>
                </Grid>
              )}
              {numberOfAdults > -1 && (
                <Grid item>
                  <FeatureIconFontAwesome icon={faPerson} />
                  <CarFeatureNumber
                    direction={theme.direction}
                    number={numberOfAdults}
                  />
                </Grid>
              )}
              {numberOfKids > -1 && (
                <Grid item>
                  <FeatureIconFontAwesome icon={faChildDress} />
                  <CarFeatureNumber
                    direction={theme.direction}
                    number={numberOfKids}
                  />
                </Grid>
              )}
            </>
          )}{' '}
        </Grid>
      ) : (
        <Grid
          container
          justifyContent="center"
          spacing={3}
          direction={isMobileRow ? 'row' : 'column'}>
          <Grid item>
            <FeatureComponent
              isLoading={isLoading}
              isImage={true}
              src={Gearbox}
              number={translations.transmissionType.charAt(0)}
            />
          </Grid>
          <Grid item>
            <FeatureComponent
              icon={faSuitcaseRolling}
              number={numberOfSmallBags}
              isLoading={isLoading}
              isImage={false}
            />
          </Grid>

          <Grid item>
            <FeatureComponent
              icon={faSuitcase}
              number={numberOfLargeBags}
              isLoading={isLoading}
              isImage={false}
            />
          </Grid>

          <Grid item>
            <FeatureComponent
              isLoading={isLoading}
              number={numberOfDoors}
              isImage={true}
              src={Doors}
            />
          </Grid>
          {numberOfSeats > -1 && (
            <Grid item>
              <FeatureComponent
                isLoading={isLoading}
                src={Seats}
                number={numberOfSeats}
                isImage={true}
              />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default CarFeatures;
