import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { List, Box } from '@mui/material';
import NavMenuItem from './MenuItem';
import LanguageIcon from '@mui/icons-material/Language';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AgentContext } from '../../../../SharedState/SearchState';

const NavigationMenu = styled(List)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const WithLink = ({ link, children }) =>
  link ? (
    <Link
      style={{ color: 'white', fontSize: '20px', textDecoration: 'none' }}
      to={link}>
      {children}
    </Link>
  ) : (
    children
  );

const NavMenu = ({ menuItems }) => {
  const { t } = useTranslation();
  const [agent] = useContext(AgentContext);
  const [agentItemTitle, setAgentItemTitle] = useState(t('agent.agent'));

  useEffect(() => {
    if (agent) {
      setAgentItemTitle(agent.name);
    }
    if (agentItemTitle && !agent) {
      setAgentItemTitle(t('agent.agent'));
    }
  }, [agent]);
  const menu = [
    {
      role: 'menuitem',
      ariaLabel: 'Change language',
      navItemText: <LanguageIcon />,
      link: null,
      subMenu: [
        { lng: 'English', code: 'en' },
        { lng: 'Русский', code: 'ru' },
        { lng: 'עברית', code: 'he' },
      ],
      type: 'language',
    },
    {
      role: 'menuitem',
      ariaLabel: 'Reservations',
      navItemText: t('header.myReservations'),
      link: '/reservation',
      type: 'signin',
    },
    {
      navItemText: t('blog.blog'),
      link: '/blog',
      type: 'blog',
      role: 'menuitem',
      ariaLabel: 'Blog',
    },
    {
      navItemText: agentItemTitle,
      link: null,
      type: 'agent',
      role: 'menuitem',
      ariaLabel: 'Agent',
    },
  ];

  return (
    <NavigationMenu
      component="nav"
      aria-label="Main navigation"
      role="navigation">
      <WithLink link={menu[0].link}>
        <NavMenuItem {...menu[0]} />{' '}
      </WithLink>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <WithLink link={menu[1].link}>
          <NavMenuItem {...menu[1]} />{' '}
        </WithLink>
        <WithLink link={menu[2].link}>
          <NavMenuItem {...menu[2]} />{' '}
        </WithLink>
        <WithLink link={menu[3].link}>
          <NavMenuItem {...menu[3]} />{' '}
        </WithLink>
      </Box>
      {/* <Hidden xsDown>  <WithLink link={menu[3].link}><NavMenuItem {...menu[3]} /> </WithLink></Hidden> */}
    </NavigationMenu>
  );
};

export default NavMenu;
