// import { europcarLocations, trcLocations } from '../data/Locations';
// export const getDefaultLocationByCountryCode = countryCode => {
//   if (countryCode === 'bg') return trcLocations[0];
//   if (countryCode === 'il') return europcarLocations[0];
//   else return '';
import i18next from 'i18next';
import { TramOutlined } from '@mui/icons-material';
import { currencyFormatter } from './currencyHelpers';

const t = i18next.t.bind(i18next);

export const generateOrderId = () => {
  return Math.floor((new Date().valueOf() * Math.random()) % 1000000000);
};

/**
 *
 * @param {Number} price
 * @param {Boolean} VATIncluded
 * @param {Number} vatLevel example 17
 * @returns
 */
export const getDisplayPriceWithVat = (price, VATIncluded, vatLevel) => {
  if (VATIncluded === false) {
    return price * (1 + vatLevel / 100);
  } else return price;
};

// export const getCurrencySignByCode = (code) => {
//   switch (code) {
//     case "USD":
//       return "$";
//     case "EUR":
//       return "€";
//     case "ILS":
//       return "₪";
//     case "CHF":
//       return "CHF";
//     default:
//       return "$";
//   }
// };
export const getFuelTypeAndACBySippCode = (sippCode, t) => {
  switch (sippCode[3].toUpperCase()) {
    case 'R':
      return { ac: true, fuel: '' };
    case 'N':
      return { ac: false, fuel: '' };
    case 'D':
      return { ac: true, fuel: t('fuelType.diesel') };
    case 'Q':
      return { ac: false, fuel: t('fuelType.diesel') };
    case 'H':
      return { ac: true, fuel: t('fuelType.hybrid') };
    case 'I':
      return { ac: false, fuel: t('fuelType.hybrid') };
    case 'E':
      return { ac: true, fuel: t('fuelType.electric') };
    case 'C':
      return { ac: false, fuel: t('fuelType.electric') };
    case 'M':
      return { ac: true, fuel: t('fuelType.multi') };
    case 'F':
      return { ac: true, fuel: t('fuelType.multi') };
    case 'V':
      return { ac: true, fuel: t('fuelType.petrol') };
    case 'Z':
      return { ac: false, fuel: t('fuelType.petrol') };
    case 'U':
      return { ac: true, fuel: t('fuelType.ethanol') };
    case 'X':
      return { ac: false, fuel: t('fuelType.ethanol') };
    case 'S':
      return { ac: true, fuel: t('fuelType.special') };
    default:
      return { ac: false, fuel: '' };
  }
};
/**
 *
 * @param {String} en
 * @param {String} ru
 * @param {String} he
 * @param {String} lng
 * @returns
 */
const getName = (en, ru, he, lng) => {
  const name = {
    en,
    ru,
    he,
  };
  return name[lng];
};
const getCarType = (sippCode, lng) => {
  switch (sippCode.charAt(1)) {
    case 'B': {
      return getName('2/3 door', '2/3 Двери', '2/3 דלתות', lng);
    }
    case 'C':
      return getName('2/4 door', '2/4 Двери', '2/4 דלתות', lng);
    case 'D':
      return getName('4 door', '4/5 Двери', '4 דלתות', lng);
    case 'W':
      return getName('Wagon', 'Универсал', 'Wagon', lng);
    case 'V':
      return getName('Van', 'Пассажирский Ван', 'Van', lng);
    case 'L':
      return getName('Limousine', 'Лимузин', 'Limousine', lng);
    case 'S':
      return getName('Sport', 'Спорт', 'ספורט', lng);
    case 'T':
      return getName('Convertible', 'Кабриолет', 'קבריאולט', lng);
    case 'F':
      return getName('SUV', 'SUV', 'SUV', lng);
    case 'J':
      return getName('All terrain', 'All Terrain', 'All Terrain', lng);
    case 'X':
      return getName('Special', 'Специальный', 'מיוחד', lng);
    case 'E':
      return getName('Coupe', 'Купе', 'קופה', lng);
    case 'M':
      return getName('Monospace', 'Моноширинный', 'Monospace', lng);
    case 'G':
      return getName('Crossover', 'Кроссовер', 'קרוסאובר', lng);
    case 'K':
      return getName(
        'Commercial Van/Truck',
        'Коммерческий Ван',
        'Commercial Van/Truck',
        lng
      );
    case 'P':
      return getName(
        'Pick up regular cab',
        'Регулярный Пикап',
        'Pick up regular cab',
        lng
      );
    case 'Q':
      return getName(
        'Pick up extended cab',
        'Большой Пикап',
        'Pick up extended cab',
        lng
      );
    case 'R':
      return getName(
        'Recreational vehicle',
        'Рекреационный',
        'Recreational vehicle',
        lng
      );
    case 'Z':
      return getName(
        'Special offer car',
        'Специальное предложение',
        'Special offer car',
        lng
      );

    default:
      return '';
  }
};
export const getCarClassBySippCode = (sippCode, carGroup) => {
  if (carGroup === 'S1' || carGroup === 'S2' || carGroup === 'S')
    return t('carclasses.comercial');
  else {
    switch (sippCode.charAt(0)) {
      case 'M':
        return t('carclasses.mini');
      case 'N':
        return t('carclasses.mini-elite');
      case 'E':
        return t('carclasses.economy');
      case 'H':
        return t('carclasses.economy-elite');
      case 'C':
        return t('carclasses.compact');
      case 'D':
        return t('carclasses.compact-elite');
      case 'I':
        return t('carclasses.intermediate');
      case 'J':
        return t('carclasses.intermediate-elite');
      case 'S':
        return t('carclasses.standard');
      case 'R':
        return t('carclasses.standard-elite');
      case 'F':
        return t('carclasses.fullSize');
      case 'G':
        return 'Fullsize Elite';
      case 'P':
        return t('carclasses.premium');
      case 'U':
        return 'Premium Elite';
      case 'L':
        return t('carclasses.luxury');
      case 'W':
        return t('carclasses.luxuryElite');
      case 'O':
        return t('carclasses.oversize');
      case 'X':
        return t('carclasses.special');
      default:
        return '';
    }
  }
};

export const getSippCodeFullDescription = (sippCode, t, lng) => {
  const names = [];

  if (!sippCode || !t || !lng) {
    return names;
  }
  const carClass = getCarClassBySippCode(sippCode, undefined, t);
  names.push({ letter: sippCode.charAt(0), name: carClass });
  names.push({
    letter: sippCode.charAt(1),
    name: getCarType(sippCode, lng),
  });
  names.push({
    letter: sippCode.charAt(2),
    name: t('carcard.carfeatures.gearboxtype', {
      type: getTypeOfGearBoxBySippCode(sippCode, lng),
    }),
  });
  const acAndFuel = getFuelTypeAndACBySippCode(sippCode, t);
  names.push({
    letter: sippCode.charAt(3),
    name: `${acAndFuel.fuel + acAndFuel.fuel ? '|' : ''}  ${t(
      `carcard.carfeatures.${acAndFuel.ac ? 'withAC' : 'withoutAC'}`
    )}`,
  });
  return names;
};

export const suppliersStringMapping = (supplierString) => {
  switch (supplierString) {
    case 'europcar':
      return 'Europcar';
    case 'toprentacar':
      return 'Top rent a car';
    default:
      return '';
  }
};
export const nameMapping = (name, t) => {
  switch (name) {
    case 'vat':
      return t('common.vat');
    case 'discount':
      return t('common.discount');
    case 'saturdayInsurance':
      return t('protection.saturdayInsurance');
    case 'childBoosterPrice':
      return t('extras.childBooster');
    case 'childSeatPrice':
      return t('extras.childSeat');
    case 'additionalDriverPrice':
      return t('extras.additionalDriver');
    case 'toddlerSeat':
      return 'Toddler seat';
    case 'smartphonePrice':
      return t('extras.smartphone');
    case 'airportFee':
      return t('extras.airportFee');
    case 'roadsafeAssistance':
      return 'Road safe assistance';
    case 'underageDriver':
      return t('extras.underageDriver');
    case 'dropOffService':
      return 'Drop off service';
    case 'totalBasePrice':
    case 'totalNetAmount':
      return t('extras.totalBasePrice');
    case 'carBasePrice':
      return t('extras.carBasePrice');
    case 'superCDW':
      return t('protection.superCDW');
    case 'superTP':
      return t('protection.superTP');
    case 'oneWayFee':
      return t('extras.oneWayFee');
    case 'CDW':
      return t('protection.CDW');
    case 'TP':
      return t('protection.TP');
    case 'three_PLC':
      return t('protection.threePLC');
    case 'cbsPrice':
      return t('extras.CBS');
    default:
      return name;
  }
};
/**
 *
 * @param {Number} price
 * @param {Number} maxPrice
 * @param {Number} numberOfDays
 * @param {Boolean} isPricePerRent
 * @param {Number} count
 * @param {String} code
 */
export const getMaxPrice = (
  price,
  maxPrice,
  numberOfDays,
  isPricePerRent,
  count,
  code
) => {
  // console.log('price', price, 'maxprice', maxPrice, 'numberOfDays', numberOfDays);
  if (isPricePerRent) {
    return price * count;
  } else {
    if (maxPrice < 1) {
      return price * count * numberOfDays;
    } else {
      if (price * numberOfDays < maxPrice) {
        return price * numberOfDays * count;
      } else {
        return maxPrice * count;
      }
    }
  }
};

export const getNumberOfDays = (
  pickUpDate,
  dropOffDate,
  pickUpTime,
  dropOffTime
) => {
  const diffTime = Math.abs(dropOffDate - pickUpDate);
  let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return !isBefore(dropOffTime, pickUpTime) ? ++diffDays : diffDays;
};

export const isBefore = (timebefore, timeafter) => {
  const hourAndMinutesBefore = timebefore
    .split(':')
    .map((item) => Number(item));
  const hourAndMinutesAfter = timeafter.split(':').map((item) => Number(item));
  if (hourAndMinutesBefore[0] === hourAndMinutesAfter[0])
    return hourAndMinutesBefore[1] <= hourAndMinutesAfter[1];
  else return hourAndMinutesBefore[0] < hourAndMinutesAfter[0];
};

export const addDays = (date, numberOfDays) => {
  var result = new Date(date);
  result.setDate(result.getDate() + numberOfDays);
  return result;
};
/**
 *
 * @param {object} userOrder
 * @param {object} extrasPrices
 * @param {number} saturdayInsurance
 * @param {boolean} returnVAT
 * @param {boolean} returnIncludeVAT
 */
export const getTotalUserPrice = (
  userOrder,
  extrasPrices,
  saturdayInsurance,
  country,
  returnVAT,
  returnIncludeVAT,
  additionalOptions,
  vatLevel
) => {
  const { numberOfDays, numberOfFreeDays, insurances } = userOrder;
  let prices = [];
  //add saturday insurance to array if needed //
  if (numberOfFreeDays) {
    prices.push(-numberOfFreeDays * userOrder.carBasePrice); //discount
    if (saturdayInsurance) {
      // for ILS only
      prices.push(numberOfFreeDays * saturdayInsurance);
    }
  }

  for (let [key, value] of Object.entries(userOrder)) {
    if (
      value &&
      key !== 'numberOfDays' &&
      key !== 'numberOfFreeDays' &&
      key !== 'insurances' &&
      key !== 'carBasePrice'
    ) {
      prices.push(
        key !== 'OWF' && key !== 'totalNetAmount' ? value * numberOfDays : value
      );
    }
  }
  insurances.forEach((insurance) => {
    if (insurance.isChecked && !insurance.isPayOnArrival) {
      prices.push(
        insurance.price * (insurance.isPricePerRent ? 1 : numberOfDays)
      );
    }
  });
  extrasPrices.forEach((extra) => {
    if (extra.isChecked) {
      if (!extra.isPayOnArrival) {
        prices.push(
          extra.count * extra.price * (extra.isPricePerRent ? 1 : numberOfDays)
        );
      }
    }
  });
  additionalOptions.forEach((option) => {
    if (option.isChecked) {
      prices.push(option.totalPrice);
    }
  });

  const sum = +prices.reduce((acc, val) => acc + val, 0).toFixed(2);
  if (returnIncludeVAT) {
    return +(sum * (1 + vatLevel / 100)).toFixed(2);
  }
  if (returnVAT) return +(sum * (vatLevel / 100)).toFixed(2);
  else return +sum.toFixed(2);
};

/**
 *
 * @param {String} type
 * @param {String} value
 */
export const validation = (type, value, language, country) => {
  switch (type) {
    case 'email':
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(value).toLowerCase());
    case 'firstName':
    case 'lastName':
      let regex = /^[a-zA-Z]+[%s -]*[a-zA-Z]*$/;
      if (country === 'il') {
        regex = /^(?:[a-zA-Z\s]+)|(?:[א-ת\s]+)$/;
      }
      return regex.test(value);
    case 'phone':
      const exp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/im;
      return exp.test(value);

    case 'age':
      return value > 17;
    default:
      return false;
  }
};
const hostname =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? 'http://localhost:4000'
    : 'https://www.we4rent.com';

/**
 * @param {String} url
 */
export const apiUrl = (params) => {
  return `${hostname}/api/${params}`;
};

export const checkOutUrl = (params) => {
  return `${hostname}/payment/${params}`;
};
/**
 *
 * @param {CharacterData} currencySign
 * @param {Number} price
 * @param {String} pricePerPeriod
 */
const getPriceString = (
  currencyCode,
  price,
  count,
  pricePerPeriod,
  boldPrice
) => {
  return (
    <>
      <span style={{ fontWeight: boldPrice ? 500 : 'auto' }}>
        {displayPriceWithCurrency(
          '',
          count > 0 ? parseFloat((price * count).toFixed(2)) : price,
          currencyCode
        )}
      </span>{' '}
      {pricePerPeriod ? pricePerPeriod : ''}
    </>
  );
};

export const displayPriceWithCurrency = (
  currencySign,
  price,
  currencyCode,
  extraCode
) => {
  if (!currencyCode) {
    return price;
  }

  if (price === 0 && extraCode !== 'CRB') {
    return t('common.included');
  }

  return currencyFormatter(currencyCode, price);
  // const formatter = new Intl.NumberFormat('en-US', {
  //   style: 'currency',
  //   currency: currencyCode,
  // });
  // return formatter.format(price);
};
/**
 *
 * @param {String} extraCode
 * @param {CharacterData} currencySign
 * @param {Number} price
 * @param {Number} count
 * @param {Number} maxPrice
 * @param {Function} t
 */
export const getPrice = (
  extraCode,
  currencyCode,
  price,
  count,
  maxPrice,
  t,
  isPricePerRent,
  boldPrice
) => {
  if (isPricePerRent) {
    return (
      <>
        <span style={{ fontWeight: 500 }}>
          {displayPriceWithCurrency('', price, currencyCode, t, extraCode)}
        </span>{' '}
        {t('carcard.pricePerRental')}{' '}
      </>
    );
  }
  if (extraCode === 'UAD') {
    return getPriceString(currencyCode, price, t('carcard.priceperday'));
  }
  if (extraCode !== 'CRB' && price === 0) {
    return t('common.included');
  }
  if (extraCode === 'CRB' && price === -1)
    return `${t('common.crossBorderNotAvailable')}`;
  else {
    return (
      <>
        {t('common.only')}{' '}
        {getPriceString(
          currencyCode,
          price,
          count,
          t('carcard.priceperday'),
          TramOutlined
        )}
      </>
    );
  }
};

export const getTypeOfGearBoxBySippCode = (sipp, language) => {
  let typeOfGearBox = '';
  switch (sipp[2]) {
    case 'M':
      typeOfGearBox = { en: 'Manual', ru: 'Ручная', he: 'גיר ידני' };
      break;
    case 'A':
      typeOfGearBox = {
        en: 'Automatic',
        ru: 'Автоматическая',
        he: 'גיר אוטומטי',
      };
      break;
    case 'C':
      typeOfGearBox = { en: 'Manual AWD', ru: 'Ручная', he: 'גיר ידני' };
      break;
    case 'N':
      typeOfGearBox = { en: 'Manual 4WD', ru: 'Ручная', he: 'גיר ידני' };
      break;
    case 'D':
      typeOfGearBox = {
        en: 'Auto AWD	',
        ru: 'Автоматическая',
        he: 'גיר אוטומטי',
      };
      break;
    case 'B':
      typeOfGearBox = {
        en: 'Auto 4WD',
        ru: 'Автоматическая',
        he: 'גיר אוטומטי',
      };
      break;
    default:
      typeOfGearBox = { en: '', ru: '', he: '' };
  }
  return language ? typeOfGearBox[language] : typeOfGearBox.en;
};
