import React from 'react';
import PaymentDetails from '../../PaymentDetails';
import UserOrderSummary from '../../../UserOrderSummary/UserOrderSummary';
import { useContext } from 'react';
import { CurrencyContext } from '../../../../../../../SharedState';

const OrderSummarySection = ({
  requestedCar,
  userOrder,
  totalPrice,
  additionalOptions,
  extrasPrices,
  policies,
}) => {
  const { apiType } = requestedCar;
  const { insurances } = userOrder;
  const [currency] = useContext(CurrencyContext);
  switch (apiType) {
    case 4:
      return (
        <PaymentDetails
          policies={policies}
          currency={currency}
          insurances={insurances}
          totalPrice={totalPrice}
          requestedCar={requestedCar}
          additionalOptions={additionalOptions}
          extras={extrasPrices}
        />
      );
    default:
      return (
        <UserOrderSummary
          amountToPay={
            requestedCar.apiType !== 3 &&
            requestedCar.paymentInformation &&
            requestedCar.paymentInformation.isPaymentRequired
              ? requestedCar.paymentInformation.amount
              : 0
          }
          saturdayInsurance={
            requestedCar.prices.protection_coverage.saturdayInsurance
          }
          userOrder={userOrder}
          currency={currency.code}
          extrasPrices={extrasPrices}
          additionalOptions={additionalOptions}
          totalPrice={totalPrice}
          requestedCar={requestedCar}
        />
      );
  }
};

export default OrderSummarySection;
