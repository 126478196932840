import React from 'react';
import { Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import DatePicker from '../Components/DatePicker/DatePicker';
import DifferentLocationCheckBox from '../Components/DifferentLocationCheckBox/DifferentLocationCheckBox';
import { useTranslation } from 'react-i18next';
import SearchLocation from '../Components/SearchLocation/SearchLocation';
import AgeSelection from '../Components/AgeSelection';
import SaturdayDrivingCheckBox from '../Components/CheckBoxes/SaturdayDrivingCheckBox';
import { ButtonWithLoader } from '../../../../ReusableComponents/ButtonWithLoader';
import { SearchButton } from '../Components/CarDatePicker/styles/styledComponents';

const DesktopPicker = ({
  formik,
  locationProps,
  getDatePickerProps,
  handleDifferentLocation,
  isDifferentLocation,
  handleSaturdayDrivingChecked,
  isLoading,
  orderLoading,
  matches,
  handleSearch,
}) => {
  const {
    t,
    i18n: {
      languages: [lng],
    },
  } = useTranslation();
  const { direction } = useTheme();

  return (
    <Grid container spacing={1}>
      <Grid size={{ xs: 5 }}>
        <Grid container>
          {' '}
          <Grid
            size={{
              xs:
                isDifferentLocation ||
                formik.values.pickUpLocation.LocationCode !==
                  formik.values.dropOffLocation.LocationCode
                  ? 6
                  : 12,
            }}>
            <SearchLocation
              {...locationProps}
              isPickupDate={true}
              location={formik.values.pickUpLocation}
              isDifferentLocation={isDifferentLocation}
            />
          </Grid>
          {(isDifferentLocation ||
            formik.values.pickUpLocation.LocationCode !==
              formik.values.dropOffLocation.LocationCode) && (
            <Grid size={{ xs: 6 }}>
              <SearchLocation
                {...locationProps}
                isDifferentLocation={isDifferentLocation}
                isPickupDate={false}
                location={formik.values.dropOffLocation}
              />
            </Grid>
          )}
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid>
            <DifferentLocationCheckBox
              isDifferentLocation={isDifferentLocation}
              pickUpLocationCode={formik.values.pickUpLocation.LocationCode}
              dropOffLocationCode={formik.values.dropOffLocation.LocationCode}
              t={t}
              handleDifferentLocation={handleDifferentLocation}
            />
          </Grid>
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              style={{
                margin: `0 ${direction === 'ltr' ? '18px' : 0} 0 ${
                  direction === 'rtl' ? '18px' : 0
                }`,
              }}>
              {t('picker.carpicker.driverAge.driverAge')}
            </Typography>
            <AgeSelection
              // size='normal'
              isDesktop={true}
              error={
                formik.touched.driverAge && Boolean(formik.errors.driverAge)
              }
              helperText={formik.errors.driverAge}
              driverAge={formik.values.driverAge}
              handleAgeChange={formik.setFieldValue}
              lng={lng}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid size={{ xs: 6 }}>
        <Grid container spacing={1}>
          {/**pick up date */}
          <Grid size={{ xs: 6 }} style={{ display: 'flex' }}>
            <DatePicker {...getDatePickerProps(true)} />
          </Grid>
          {/**drop off date */}
          <Grid size={{ md: 6 }}>
            <Grid container>
              <DatePicker {...getDatePickerProps(false)} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          {formik.values.country.code === 'il' && (
            <Grid>
              <SaturdayDrivingCheckBox
                checked={!formik.values.drivingOnSaturday}
                onChange={handleSaturdayDrivingChecked}
                handleSaturdayDrivingChecked={handleSaturdayDrivingChecked}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid size={{ xs: 1 }}>
        {formik.values.country.isLanding ? (
          <ButtonWithLoader
            isLoading={orderLoading}
            name={t('common.orderShort')}
            onClickHandler={formik.handleSubmit}
            buttonColor="secondary"
            buttonVariant="contained"
          />
        ) : (
          <SearchButton
            className="search-button"
            id="search-button"
            style={{ fontSize: 16 }}
            disabled={isLoading}
            ispc={matches.valueOf.toString()}
            variant="contained"
            disableElevation
            color="secondary"
            onClick={handleSearch}
            lng={lng}>
            <span id="search-button">{t('picker.carpicker.searchButton')}</span>
          </SearchButton>
        )}
      </Grid>
    </Grid>
  );
};

export default DesktopPicker;
