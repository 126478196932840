import React, { useState, useEffect } from 'react';
import Title from './Components/DriverDetailsForm/Title';
import Grid from '@mui/material/Grid2';
import {
  Typography,
  Divider,
  Button,
  useMediaQuery,
  useTheme,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Link,
  FormHelperText,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
} from '@mui/material';
import SummaryCard from './SummaryCard';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import RentalConditionsDialog from '../../../Layouts/CarCard/Components/PCCarCard/Components/BottomSection/Components/RentalConditionsDialog/RentalConditionsDialog';
import {
  getAllCountries2,
  getCountryByPhoneCode,
  getInitialPhoneCode,
} from '../../../../apiRequests';
import { useFormik } from 'formik';
import validateDriverDetailsForm from './helpers/validateDriverDetailsForm';
import {
  DetailsWraper,
  InputField,
  PhoneCode,
} from './Components/styles/OrderSummary.styled';
import handleSubmitDriverDetaillsForm from './helpers/handleSubmitDriverDetaillsForm';
import { StyledSelect } from './style/OrderSummary.styled';

const OrderSummary = ({
  handleSaveNewOrder,
  handleBack,
  handleNext,
  setPersonalDetails,
  personalDetails,
  isLoading,
  searchDetails,
  requestedCar,
  extrasPrices,
  currencyCode,
}) => {
  // const navigate = useNavigate();
  // if (!sessionStorage.getItem('requestedCar') && Object.keys(requestedCar).length === 0) {
  //   navigate('/');
  // }
  const { apiType, paymentInformation } = requestedCar;
  const [open, setOpen] = React.useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [countries, setCountries] = useState([]);
  const [leadCreated, setLeadCreated] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const createLead = () => {
    handleSubmitDriverDetaillsForm({
      values: formik.values,
      handleSaveNewOrder,
      paymentRequired,
      setPersonalDetails,
      personalDetails,
      isLead: true,
    });
  };

  const getCountry = (phonecode) => {
    if (phonecode !== formik.values.phoneCode.code) {
      getCountryByPhoneCode(phonecode)
        .then(({ data }) => {
          if (data.status === 'success') {
            formik.setFieldValue('phoneCode', {
              code: data.country.phoneCode,
              flag: data.country.flag,
            });
            formik.setFieldTouched('phoneCode');
          } else {
            formik.setFieldValue('phoneCode', { code: '', flag: '' }, false);
            formik.setFieldError('phoneCode', t('errors.phoneCodeError'));
          }
        })
        .catch((err) => console.error(err));
    }
  };

  const descriptionElementRef = React.useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const paymentRequired =
    paymentInformation && paymentInformation.isPaymentRequired;

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      title: '',
      age: searchDetails.driverAge,
      phone: '',
      phoneCode: { code: '', flag: '' },
      email: '',
      flightNumber: '',
      customerComment: '',
      acceptedConditions: false,
      citizenship: { code: '', name: '' },
      numberOfPassengers: 0,
    },
    validateOnBlur: true,
    validateOnChange: false,
    validate: (values) => validateDriverDetailsForm(values, t, lng, apiType),
    onSubmit: (values) =>
      handleSubmitDriverDetaillsForm({
        values,
        handleSaveNewOrder,
        paymentRequired,
        setPersonalDetails,
        personalDetails,
        isLead: false,
        lng,
      }),
  });

  useEffect(() => {
    // console.log(formik.touched);
    if (!leadCreated) {
      if (
        formik.touched.firstName &&
        ((formik.touched.phone && formik.touched.phoneCode?.code) ||
          formik.touched.email)
      ) {
        if (
          formik.values.firstName &&
          ((formik.values.phone && formik.values.phoneCode?.code) ||
            formik.values.email)
        ) {
          setLeadCreated(true);
          createLead();
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getInitialPhoneCode().then((phoneCode) => {
      formik.setFieldValue('phoneCode', {
        code: phoneCode.code,
        flag: phoneCode.flag,
      });
    });
    getAllCountries2(lng).then(({ data }) => {
      setCountries(data);
    });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const handleAcceptConditions = (action) => {
    if (action === 'refresh') {
      window.location.reload();
    } else {
      formik.setFieldValue('acceptedConditions', true);
      setOpen(false);
    }
  };

  const theme = useTheme();
  const {
    t,
    i18n: {
      languages: [lng],
    },
  } = useTranslation();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getAdornment = (direction) => {
    if (direction === 'ltr')
      return {
        startAdornment: (
          <InputAdornment position={'start'}>
            <>{formik.values.phoneCode.flag} +</>
          </InputAdornment>
        ),
      };
    else
      return {
        endAdornment: (
          <InputAdornment position={'end'}>
            <>+ {formik.values.phoneCode.flag} </>
          </InputAdornment>
        ),
      };
  };
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      style={{ height: '100%' }}>
      <Grid>
        <SummaryCard
          searchDetails={searchDetails}
          requestedCar={requestedCar}
          extrasPrices={extrasPrices}
          currencyCode={currencyCode}
        />

        <Typography
          align="center"
          variant="h6"
          style={{ fontSize: 24, color: 'rgb(0,0,0,0.87)' }}>
          {t('driverDetailsForm.driverDetails')}
        </Typography>
        <DetailsWraper variant="outlined" elevation={0}>
          <form
            style={{
              width: '100%',
              padding: !isMobile ? '32px' : '20px',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              borderRadius: '4px',
            }}
            action=""
            noValidate>
            <Grid container direction="column" spacing={2}>
              <Grid>
                <Grid container spacing={1}>
                  <Grid size={{ xs: 6, sm: 3 }}>
                    <Title
                      handleChange={formik.handleChange}
                      id="title"
                      title={formik.values.title}
                      language={lng}
                      label={t('driverDetailsForm.title')}
                    />
                  </Grid>
                  {isMobile && (
                    <Grid size={{ xs: 6, md: 3 }}>
                      <FormControl fullWidth required>
                        <InputField
                          color="secondary"
                          variant="outlined"
                          label={t('driverDetailsForm.age')}
                          slotProps={{ input: { readOnly: true } }}
                          value={formik.values.age}
                          name="age"
                          required
                          onChange={formik.handleChange}
                          fullWidth
                        />
                        {/* {errorsValidation.age.error && <FormHelperText>{t('errors.ageError')}</FormHelperText>} */}
                      </FormControl>
                    </Grid>
                  )}
                  <Grid size={{ xs: 12, sm: 3 }}>
                    <InputField
                      color="secondary"
                      slotProps={{ input: { style: { fontWeight: 500 } } }}
                      variant="outlined"
                      fullWidth
                      required
                      label={t('driverDetailsForm.firstName')}
                      name="firstName"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 3 }}>
                    <InputField
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      required
                      label={t('driverDetailsForm.lastName')}
                      name="lastName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                        // t('errors.lastNameError', {
                        //   orHebrew: searchDetails.country === 'il' ? t('errors.orHebrew') : ''
                        // })
                      }
                    />
                  </Grid>
                  {!isMobile && (
                    <Grid size={{ xs: 4, md: 3 }}>
                      <FormControl fullWidth required>
                        <InputField
                          color="secondary"
                          variant="outlined"
                          label={t('driverDetailsForm.age')}
                          slotProps={{ input: { readOnly: true } }}
                          value={formik.values.age}
                          name="age"
                          required
                          onChange={formik.handleChange}
                          fullWidth
                        />
                        {/* {errorsValidation.age.error && <FormHelperText>{t('errors.ageError')}</FormHelperText>} */}
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid>
                <Grid container spacing={1}>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <InputField
                      variant="outlined"
                      color="secondary"
                      // InputLabelProps={{ style: { fontWeight: 400 } }}
                      fullWidth
                      required
                      label={t('driverDetailsForm.emailAddress')}
                      name="email"
                      defaultValue=""
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid
                    size={{ xs: 12, sm: 4, md: 6 }}
                    style={{ display: 'flex' }}>
                    <Grid
                      container
                      direction={
                        theme.direction === 'rtl' ? 'row-reverse' : 'row'
                      }>
                      <Grid size={{ xs: 4 }}>
                        <PhoneCode
                          $borderRadius="2px 0px 0px 2px"
                          variant="outlined"
                          type="number"
                          name="phoneCode"
                          value={formik.values.phoneCode.code}
                          onChange={formik.handleChange}
                          label={t('driverDetailsForm.phoneCode')}
                          slotProps={{ input: getAdornment(theme.direction) }}
                          error={
                            formik.touched.phoneCode &&
                            Boolean(formik.errors.phoneCode)
                          }
                          helperText={
                            formik.touched.phoneCode && formik.errors.phoneCode
                            //  && t('errors.phoneCodeError')
                          }
                          onBlur={(e) => {
                            getCountry(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid size={{ xs: 8 }}>
                        <InputField
                          $borderRadius="0px 2px 2px 0px"
                          color="secondary"
                          variant="outlined"
                          fullWidth
                          required
                          id="phone"
                          label={t('driverDetailsForm.phone')}
                          name="phone"
                          defaultValue=""
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.phone && Boolean(formik.errors.phone)
                          }
                          helperText={
                            formik.touched.phone && formik.errors.phone
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid>
                <Grid container spacing={1} justifyContent="flex-start">
                  <Grid size={{ xs: 6, sm: 4, md: 3 }}>
                    <InputField
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      label={t('driverDetailsForm.flightNumber')}
                      name="flightNumber"
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  {apiType === 4 && (
                    <>
                      <Grid size={{ xs: 6, md: 3 }}>
                        <InputField
                          InputLabelProps={{ shrink: true }}
                          placeholder={t('driverDetailsForm.number')}
                          type="number"
                          color="secondary"
                          variant="outlined"
                          label={t('driverDetailsForm.passengers')}
                          required
                          name="numberOfPassengers"
                          onChange={formik.handleChange}
                          error={
                            formik.touched.numberOfPassengers &&
                            Boolean(formik.errors.numberOfPassengers)
                          }
                          helperText={
                            formik.touched.numberOfPassengers &&
                            formik.errors.numberOfPassengers
                          }
                        />
                      </Grid>
                      <Grid size={{ xs: 6, md: 6 }}>
                        <FormControl
                          required
                          variant="outlined"
                          fullWidth
                          id="citizenship">
                          <InputLabel>
                            {t('driverDetailsForm.driverCitizenShip')}
                          </InputLabel>
                          <StyledSelect
                            label={t('driverDetailsForm.driverCitizenShip')}
                            labelId="citizenship"
                            value={formik.values.citizenship}
                            onChange={(e) =>
                              formik.setFieldValue(
                                'citizenship',
                                e.target.value
                              )
                            }>
                            {countries.map((country) => {
                              return (
                                <MenuItem key={country.code} value={country}>
                                  {country.flag} {country.name[lng]}
                                </MenuItem>
                              );
                            })}
                          </StyledSelect>
                        </FormControl>{' '}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <InputField
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={isMobile ? 2 : 3}
                  label={t('driverDetailsForm.comments')}
                  name="customerComment"
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid style={{ marginTop: 16 }}>
                <FormGroup name="acceptedConditions" onBlur={formik.handleBlur}>
                  <FormControl
                    required
                    error={
                      formik.touched.acceptedConditions &&
                      !formik.values.acceptedConditions
                    }>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {' '}
                      <FormControlLabel
                        style={{
                          margin: `0 ${
                            theme.direction === 'ltr' ? '5px' : '-11px'
                          } 0 ${theme.direction === 'ltr' ? '-11px' : '5px'}`,
                        }}
                        control={
                          <Checkbox
                            checked={formik.values.acceptedConditions}
                            onChange={formik.handleChange}
                            name="acceptedConditions"
                            color="secondary"
                          />
                        }
                        label={
                          <>
                            <label
                              onClick={() => {
                                formik.setFieldValue(
                                  'acceptedConditions',
                                  !formik.values.acceptedConditions
                                );
                              }}
                              style={{ cursor: 'pointer' }}>
                              <Typography
                                variant={isMobile ? 'caption' : 'body1'}>
                                {t('common.readAndAccept')}{' '}
                                <Link
                                  style={{
                                    marginBottom: 1,
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                    textUnderlineOffset: 3,
                                    color: theme.palette.secondary.main,
                                    fontWeight: 500,
                                  }}
                                  onClick={handleClickOpen}>
                                  {t('common.withRentalCoditions')}
                                </Link>
                              </Typography>

                              <RentalConditionsDialog
                                handleAcceptConditions={handleAcceptConditions}
                                apiType={apiType}
                                destinationID={
                                  searchDetails.pickUpLocation.stationID
                                }
                                handleClose={handleClose}
                                supplier={
                                  requestedCar ? requestedCar.supplier : ''
                                }
                                country={searchDetails.country}
                                t={t}
                                lng={lng}
                                open={open}
                              />
                            </label>
                          </>
                        }
                      />{' '}
                    </div>
                    {formik.touched.acceptedConditions &&
                      !formik.values.acceptedConditions && (
                        <FormHelperText>
                          {formik.errors.acceptedConditions}
                        </FormHelperText>
                      )}
                  </FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isSubscribed}
                        onChange={(event) =>
                          setIsSubscribed(event.target.checked)
                        }
                        name="subscribe"
                        color="secondary"
                      />
                    }
                    label={
                      <Typography variant={isMobile ? 'caption' : 'body1'}>
                        {t('common.subscribe')}
                      </Typography>
                    }
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </form>
          <Divider />
        </DetailsWraper>
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        style={{ margin: '16px 0px' }}
        alignItems="center">
        <Grid>
          <Button
            style={{ marginTop: 'auto', marginBottom: 'auto', fontSize: 14 }}
            size="small"
            onClick={handleBack}>
            {theme.direction === 'ltr' ? <NavigateBefore /> : <NavigateNext />}{' '}
            {isMobile ? t('common.back') : t('common.backToAdditionalOptions')}
          </Button>
        </Grid>
        <Grid style={{ position: 'relative' }}>
          <Button
            className="orderButton"
            id={paymentRequired ? 'proceedToPaymentButton' : 'orderButton'}
            disabled={isLoading}
            variant="contained"
            disableElevation={true}
            color="secondary"
            style={{ margin: '16px 0px', color: 'white', fontSize: 14 }}
            onClick={formik.handleSubmit}>
            <span id="orderButton">
              {paymentRequired
                ? t('common.proceedToPayment')
                : t('common.order')}
            </span>
          </Button>
          {isLoading && (
            <CircularProgress
              size={24}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -12,
                marginLeft: -12,
              }}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderSummary;
