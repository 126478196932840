import React from 'react';

const CarFeatureNumber = ({ direction, number }) => {
  return (
    <span
      style={{
        marginLeft: direction === 'ltr' ? 4 : 0,
        marginRight: direction === 'rtl' ? 4 : 0
      }}
    >
      {number}
    </span>
  );
};

export default CarFeatureNumber;
