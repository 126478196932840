const validateDriverDetailsForm = (
  {
    firstName,
    lastName,
    age,
    customerComment,
    email,
    flightNumber,
    phone,
    phoneCode,
    acceptedConditions,
    citizenship,
    numberOfPassengers,
  },
  t,
  lng,
  apiType
) => {
  const errors = {};
  const blankFieldError = t('forms.errors.blankField');

  //Email validation
  if (!email) {
    errors.email = blankFieldError;
  } else {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email.toLowerCase())) {
      errors.email = t('errors.emailError');
    }
  }
  if (apiType === 4 && !numberOfPassengers) {
    if (numberOfPassengers === 0) {
      errors.numberOfPassengers = t('forms.errors.numberOfPassengersIncDriver');
    } else {
      errors.numberOfPassengers = blankFieldError;
    }
  }
  //First Name and Last name validation
  let regex = /^[a-zA-Z]+[%s -]*[a-zA-Z]*$/;
  if (lng === 'il') {
    regex = /^(?:[a-zA-Z\s]+)|(?:[א-ת\s]+)$/;
  }
  if (!firstName) {
    errors.firstName = blankFieldError;
  } else {
    if (!regex.test(firstName)) {
      errors.firstName = t('errors.firstNameError');
    }
  }
  if (!lastName) {
    errors.lastName = blankFieldError;
  } else {
    if (!regex.test(lastName)) {
      errors.lastName = t('errors.lastNameError');
    }
  }
  //Phone code validation
  if (!phoneCode) {
    errors.phoneCode = blankFieldError;
  }

  //Phone validation
  if (!phone) {
    errors.phone = blankFieldError;
  } else {
    const phoneValidationRegExp = /^(?:[0-9] ?){6,14}[0-9]$/;
    if (!phoneValidationRegExp.test(phone)) {
      errors.phone = t('errors.phoneError');
    }
  }
  if (!citizenship) {
    errors.citizenship = blankFieldError;
  }
  //Age Check
  if (!age) {
    errors.age = blankFieldError;
  } else {
    if (age < 18) {
      errors.age = t('ageError');
    }
  }
  //Accepted conditions
  if (!acceptedConditions) {
    errors.acceptedConditions = t('common.acceptionError');
  }
  return errors;
};

export default validateDriverDetailsForm;
