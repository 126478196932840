import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  useTheme
} from '@mui/material';
import ReactHtmlParser from 'html-react-parser';
const fontFamily =
  'font-family: Montserrat, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;';

const options = {
  replace: node => {
    // console.log('NODE', node);
    if (!node.attribs) return;
    else {
      node.attribs.style = node.attribs.style || '' + fontFamily + 'line-height:1.5';
    }
  }
};
const getDescription = (description, htmlDescription) => {
  if (description.html) {
    return ReactHtmlParser(description.html, options);
  }
  if (htmlDescription) {
    return ReactHtmlParser(htmlDescription, options);
  }
  return description;
};

const Conditions = ({
  handleClose,
  open: {
    value,
    typeOfInsurance: { description, htmlDescription, name }
  }
}) => {
  // console.log('DESCRIPTION', description, 'htmldescription', htmlDescription);
  const theme = useTheme();
  return (
    <Dialog maxWidth='lg' onClose={handleClose} open={value}>
      <DialogTitle dir={theme.direction} onClose={handleClose}>
        {name}
      </DialogTitle>
      <DialogContent dir={theme.direction} dividers>
        <Typography component='span' gutterBottom>{getDescription(description, htmlDescription)}</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color='primary'>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Conditions;
