import styled from 'styled-components';

export const PerDay = styled.span`
  color: #14c2a9;
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
  text-align: ${({ $direction }) => ($direction === 'rtl' ? 'right' : 'left')};
  margin-left: 4px;
`;
