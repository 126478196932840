import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetch = ({ method, url, data = null, config = null }) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      axios[method](url)
        .then(res => {
          setResponse(res.data);
        })
        .catch(err => {
          setError(err.response ? err.response.status : err.response);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetchData();
  }, [method, url, data, config]);

  return { response, error, isLoading };
};

export default useFetch;
