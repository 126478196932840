import React from 'react';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { SwipeableDrawer } from '@mui/material';
import { Link } from 'react-router-dom';
import { StyledDrawer } from './NavBar/styles/LeftDrawer.styled';
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    backgroundColor: 'yellow',
  },
});


export default function TemporaryDrawer({
  toggleDrawer,
  isOpen,
  menuItems,
  onOpen,
}) {
  const classes = useStyles();

  const sideList = () => (
    <div
      className={classes.list}
      //role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}>
      <List style={{ color: 'black' }}>
        {menuItems.map((menuItem, index) => (
          <ListItem
            button
            key={menuItem.navItemText}
            component={Link}
            to={menuItem.link}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={menuItem.navItemText} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <StyledDrawer
      color="#fafafa"
      open={isOpen}
      onClose={toggleDrawer(false)}
      anchor="left"
      onOpen={toggleDrawer(true)}>
      {sideList()}
    </StyledDrawer>
  );
}
