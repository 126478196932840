import FormControlLabel from '@mui/material/FormControlLabel';
import CheckBox from '@mui/material/Checkbox';
import styled from 'styled-components';
import { TableCell } from '@mui/material';

export const StyledCheckBox = styled(CheckBox)`
  /* &.MuiCheckbox-root {
    &.MuiCheckbox-indeterminate {
      &:hover {
        background-color: black;
      }
    }
  } */

  &.MuiCheckbox-colorPrimary.Mui-checked {
    &.Mui-checked {
      color: ${({ checkboxcolor }) => checkboxcolor};
      &.Mui-disabled {
        color: ${({ $disabledColor }) => $disabledColor};
        opacity: 0.5;
      }
    }
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label.Mui-disabled {
    color: rgba(0, 0, 0, 0.58);
    &.MuiCheckbox-root {
      &:hover {
        background-color: black;
      }
    }
  }
`;
export const StyledTableCell = styled(TableCell)``

// export const StyledTableCell = styled(TableCell)`
  /* border-left: ${({ isHovered, id: selftId }) => {
    console.log(isHovered);
    let style = '';
    if (selftId === isHovered.id && isHovered.status) style = `1px solid #c4c4c4 !important`;
    else style = '1px solid transparent';
    return style;
  }};
    border-right: ${({ isHovered, id: selftId }) => {
    console.log(isHovered);
    let style = '';
    if (selftId === isHovered.id && isHovered.status) style = `1px solid #c4c4c4 !important`;
    else style = '1px solid transparent';
    return style;
  }}; */

//`;
