import {
  Accordion,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import React, {  useState, useCallback } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  StyledGridCell,
  StyledGridRow,
  StyledPaper,
} from '../styles/PaymentDetails.styled';
import { useTranslation } from 'react-i18next';
import { currencyFormatter } from '../../../../../../../utils/currencyHelpers';
import { StyledTooltip } from '../../../../../../ReusableComponents/StyledComponents';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


const PricesAndTaxes = ({
  fees,
  basePrice,
  summaryBackgroundColor,
  totalPrice,
  requestedCar,
  currency,
  insurances,
  getFeePrice,
}) => {
  const [expanded, setExpanded] = React.useState(true);
  const { t } = useTranslation();
  const addedInsurances = insurances.filter((ins) => ins.isChecked);
  // const feesRefs = useRef(fees.map(fee => React.createRef()));
  // const insurancesRefs = useRef(insurances.map((ins) => React.createRef()));
  const [widths, setWidths] = useState([]);

  // useEffect(() => {
  //   console.log('WIDTHS', widths);
  // },[widths]);

  const measureRef = useCallback((node, idx) => {
    if (node !== null && node.getBoundingClientRect().width !== 0) {
      // console.log(node.getBoundingClientRect().width);
      const currWidths = widths;
      currWidths[idx] = Math.ceil(node.getBoundingClientRect().width + 16);
      setWidths(currWidths);
    }
  }, []);
  const getInsurancePrice = (insurance) => {
    if (insurance.isIncludedInPrice) {
      if (insurance.price) {
        return (
          <span>
            {insurance.price} ({t('common.Included')})
          </span>
        );
      }
      return t('common.Included');
    } else {
      return (
        <span style={{ fontWeight: 500 }}>
          {currencyFormatter(currency.code, insurance.price)}
        </span>
      );
    }
    //TODO INSURANCES PRICES
    // currencyFormatter(currency.code, totalPrice);
  };
  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded((prevState) => setExpanded(!prevState))}>
      <AccordionSummary
        style={{ background: summaryBackgroundColor }}
        expandIcon={<ExpandMoreIcon />}>
        <Typography style={{ fontWeight: 600 }}>
          {t('paymentDetails.priceTaxesFees')}
          {!expanded && (
            <span style={{ marginLeft: 16 }}>
              {currencyFormatter(currency.code, totalPrice)}
            </span>
          )}
          {/* <br /> ({t('paymentDetails.payNow')}) */}
        </Typography>
      </AccordionSummary>
      <StyledPaper elevation={0}>
        <Grid container direction="column">
          <StyledGridRow container xs={12} justifyContent="space-between">
            <StyledGridCell item={true}>
              <Typography variant="body2">
                {t('paymentDetails.rentalPrice')}
              </Typography>
            </StyledGridCell>
            <StyledGridCell item>
              <Typography variant="body2" style={{ fontWeight: 500 }}>
                {currencyFormatter(currency.code, basePrice.totalBasePriceDisp)}
              </Typography>
            </StyledGridCell>
          </StyledGridRow>

          {fees.map((fee, idx) => {
            return (
              <StyledGridRow item xs={12} key={fee.code || idx}>
                <Grid container justifyContent="space-between">
                  <Grid
                    item
                    xs={8}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <Typography
                      // ref={measureR}
                      variant="body2"
                      style={{
                        width: widths[idx],
                      }}>
                      <span ref={(node) => measureRef(node, idx)}>
                        {fee.name}
                      </span>
                    </Typography>

                    {fee.description && (
                      <StyledTooltip
                        enterTouchDelay={0}
                        title={
                          <Typography
                            variant="body2"
                            style={{ color: 'black' }}>
                            {fee.description}
                          </Typography>
                        }
                        arrow
                        style={{ color: 'black' }}>
                        <HelpOutlineIcon
                          fontSize="small"
                          style={{ marginLeft: 4 }}
                        />
                      </StyledTooltip>
                    )}
                  </Grid>

                  <StyledGridCell item $align="right" xs={4}>
                    <Typography variant="body2">
                      {getFeePrice(fee, currency.code)}
                    </Typography>
                  </StyledGridCell>
                </Grid>
              </StyledGridRow>
            );
          })}
          {addedInsurances.map((insurance, idx) => {
            return (
              <StyledGridRow
                // $withoutBorder={idx === addedInsurances.length - 1}
                item
                xs={12}
                key={insurance.code}>
                <Grid container justifyContent="space-between">
                  <StyledGridCell item xs={8} $justifyContent="start">
                    <Typography variant="body2">{insurance.name}</Typography>
                  </StyledGridCell>
                  <StyledGridCell item xs={4} $align="right">
                    <Typography variant="body2">
                      {getInsurancePrice(insurance)}
                    </Typography>
                  </StyledGridCell>
                </Grid>
              </StyledGridRow>
            );
          })}
          <StyledGridRow item xs={12} style={{ padding: '16px 16px' }}>
            <Grid container justifyContent="space-between">
              <Grid item xs={8}>
                <Typography variant="body2" style={{ fontWeight: 700 }}>
                  {t('paymentDetails.totalPay')}{' '}
                  {t('common.days.days', { count: basePrice.numberOfDays })}
                </Typography>
              </Grid>
              <StyledGridCell item xs={4} $align="right">
                <Typography variant="body2" style={{ fontWeight: 700 }}>
                  {currencyFormatter(currency.code, totalPrice)}
                </Typography>
              </StyledGridCell>
            </Grid>
          </StyledGridRow>
        </Grid>
      </StyledPaper>
    </Accordion>
  );
};

export default PricesAndTaxes;
