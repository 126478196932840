import { getDisplayPriceWithVat } from "../../../../../utils/utils";

export const getFlagEmoji = (countryCode) => {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};

export const getPrice = (basePrice, vatLevel, isTotalPrice) => {
  const { displayPrice, price, pricePerPeriod, VATIncluded, totalBasePrice } =
    basePrice;
  if (isTotalPrice && totalBasePrice) {
    return getDisplayPriceWithVat(totalBasePrice, VATIncluded, vatLevel);
  }
  if (displayPrice) {
    return displayPrice;
  } else {
    if (price) {
      return getDisplayPriceWithVat(price, VATIncluded, vatLevel);
    } else return getDisplayPriceWithVat(pricePerPeriod, VATIncluded, vatLevel);
  }
};
