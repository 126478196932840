import './Preloader.css';
import React from 'react';

const Loader = () => {
  return (
    <div className='loadingio-spinner-blocks-mbzs3w1nah'>
      <div className='ldio-upgrjrss3q'>
        <div style={{ left: '8.36px', top: '8.36px', animationDelay: '0s' }}></div>
        <div
          style={{
            left: '58.51999999999996px',
            top: '8.36px',
            animationDelay: '0.08333333333333333s'
          }}
        ></div>
        <div
          style={{
            left: '108.6799999999999px',
            top: '8.36px',
            animationDelay: '0.16666666666666666s'
          }}
        ></div>
        <div
          style={{
            left: '158.8399999999997px',
            top: '8.36px',
            animationDelay: '0.25s'
          }}
        ></div>
        <div
          style={{
            left: '8.36px',
            top: '58.519999999999996px',
            animationDelay: '0.9166666666666666s'
          }}
        ></div>
        <div
          style={{
            left: '158.8399999999997px',
            top: '58.519999999999996px',
            animationDelay: '0.3333333333333333s'
          }}
        ></div>
        <div
          style={{
            left: '8.36px',
            top: '108.67999999999999px',
            animationDelay: '0.8333333333333334s'
          }}
        ></div>
        <div
          style={{
            left: '158.8399999999997px',
            top: '108.67999999999999px',
            animationDelay: '0.4166666666666667s'
          }}
        ></div>
        <div
          style={{
            left: '8.36px',
            top: '158.83999999999997px',
            animationDelay: '0.75s'
          }}
        ></div>
        <div
          style={{
            left: '58.51999999999996px',
            top: '158.83999999999997px',
            animationDelay: '0.6666666666666666s'
          }}
        ></div>
        <div
          style={{
            left: '108.6799999999999px',
            top: '158.83999999999997px',
            animationDelay: '0.5833333333333334s'
          }}
        ></div>
        <div
          style={{
            left: '158.8399999999997px',
            top: '158.83999999999997px',
            animationDelay: '0.5s'
          }}
        ></div>
      </div>
    </div>
  );
};

export default Loader;
