import { Divider } from '@mui/material';
import styled from 'styled-components';

export const StyledFooter = styled.div`
  position: relative;
  background-color: #16596b;
  margin-top: auto;
  padding-top: 24px;
  padding-bottom: 24px;
  width: 100%;
  overflow: hidden;
`;

export const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;
