import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Content, Description, Mark } from './AboutLocationSection/LocationDescription.styled';

const LocationDescription = ({ country, lng, title, ismobile }) => {
  const { t } = useTranslation();
  return (
    <Content $ismobile={ismobile}>
      {/* <Mark> {t('home.countrytitle', { country: t(`countries.${country}.${country}`) })}</Mark> */}
      <Mark>
        <Typography
          variant='h1'
          component={'h1'}
          style={{ fontWeight: ismobile ? 700 : 900, fontSize: ismobile ? '32px' : '56px' }}
        >
          {title}
        </Typography>
      </Mark>
      {!ismobile && (
        <Description style={{ margin: 0 }}>
          {' '}
          <Mark>
            {country
              ? t('home.locationdescription', {
                  location: t(`countries.${country}.${country}`, { count: 1 })
                })
              : t('pages.mainPage.descriptionWithoutLocation')}
          </Mark>
        </Description>
      )}
    </Content>
  );
};

export default LocationDescription;
