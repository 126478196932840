import { findLocationByLocationCode } from '../../../apiRequests';
import { initLocation } from './getInitialState';

export const getInitialLocation = async (urlParams, isPickUp) => {
  const LocationCode = urlParams.get(isPickUp ? 'pl' : 'dl') || null;
  try {
    const { data: location } = await findLocationByLocationCode(LocationCode);
    if (location) {
      return location;
    } else return initLocation;
  } catch (err) {
    console.error(err);
    return initLocation;
  }
};
