import React from 'react';
import logos from './SecureLogos/images/logos';
import styled from 'styled-components';

const Image = styled.img`
  margin-right: 8px;
`;

const SecureLogos = () => {
  return (
    <>
      {logos.map((logo, idx) => {
        return <Image src={logo.src} alt={logo.alt} key={idx} loading='lazy' />;
      })}
    </>
  );
};

export default SecureLogos;
