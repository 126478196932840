import { useEffect, useState } from 'react';

const useScroll = (distance = 0) => {
  const [crossed, setCrossed] = useState(false);
  useEffect(
    function () {
      const handler = () => setCrossed(window.pageYOffset > distance);
      handler();
      window.addEventListener('scroll', handler, true);
      return () => window.removeEventListener('scroll', handler);
    },
    [distance]
  );
  return { crossed };
};

export default useScroll;
