import axios from 'axios';
import React, { useState, createContext } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { apiUrl } from '../../utils';
import { getInitialState, initLocation } from './helpers/getInitialState';
import { addDays } from 'date-fns';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const { pathname } = window.location;
const loc = pathname.split('/');
let countryCode;

if (loc.find((l) => l === 'country')) {
  const idx = loc.findIndex((l) => l === 'country');
  countryCode = loc[idx + 1];
}

let sessionSearchData = JSON.parse(sessionStorage.getItem('searchDetails'));
if (
  countryCode &&
  sessionSearchData &&
  countryCode !== sessionSearchData.country.code
) {
  sessionStorage.clear();
  sessionSearchData = null;
}

if (sessionSearchData) {
  sessionSearchData.pickUpDate = new Date(sessionSearchData.pickUpDate);
  sessionSearchData.dropOffDate = new Date(sessionSearchData.dropOffDate);
}
/**
 *
 * @param {Array} locationsList
 * @param {String} locationCode
 * @returns
 */
const getLocationByLocationCode = (locationsList, locationCode, isPickup) => {
  const location = locationsList.find(
    (loc) => loc.LocationCode === locationCode
  );
  return location;
};

/**
 * Contexes
 */
export const SearchDetailsContext = createContext();
export const BackGroundImageContext = createContext();
export const LocationsContext = createContext();
export const ArticlesContext = createContext();
export const AgentContext = createContext();

const initState = () => {
  let sessionSearchData = sessionStorage.getItem('searchDetails');
  if (sessionSearchData) {
    const state = JSON.parse(sessionSearchData);
    state.pickUpDate = new Date(state.pickUpDate);
    state.dropOffDate = new Date(state.dropOffDate);
    return state;
  } else {
    const today = new Date();
    const defaultTime = `${('0' + ((today.getHours() + 4) % 24)).slice(-2)}:00`;

    return {
      currency: '',
      country: {
        code: '',
        name: { en: '', ru: '', he: '' },
      },
      pickUpLocation: initLocation,
      dropOffLocation: initLocation,
      pickUpDate: new Date(),
      pickUpTime: defaultTime,
      dropOffDate: addDays(new Date(), 3),
      dropOffTime: defaultTime,
      driverAge: '',
      drivingOnSaturday: true,
      landing: {
        isLanding: false,
        selectedCar: '',
      },
      orderId: null,
    };
  }
};

const Store = ({ children }) => {
  const [locations, setLocations] = useState({ countryCode: '', stations: [] });

  const [searchDetails, setSearchDetails] = useState(initState());
  const [backgroundImage, setBackgroundImage] = useState('');
  const [articles, setArticles] = useState({ posts: [], page: 1 });
  const [agent, setAgent] = useState(null);

  const {
    i18n: {
      languages: [lng],
    },
  } = useTranslation();
  const { search } = useLocation();

  useEffect(() => {
    console.log('Search details', searchDetails);
  }, [searchDetails]);

  useEffect(() => {
    getInitialState(urlParams)
      .then(({ state, shouldUpdate }) => {
        if (shouldUpdate) {
          setSearchDetails(state);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    // sessionStorage.clear();
    axios
      .get(apiUrl(`articles/getpaginatedposts?lng=${lng}&page=1`))
      .then((res) => {
        const fetchedPosts = res.data.articles;
        if (fetchedPosts.length > 0) {
          setArticles({ posts: fetchedPosts, page: 1 });
        }
      })
      .catch((err) => console.log(err));
  }, [lng]);

  return (
    <AgentContext.Provider value={[agent, setAgent]}>
      <SearchDetailsContext.Provider value={[searchDetails, setSearchDetails]}>
        <BackGroundImageContext.Provider
          value={[backgroundImage, setBackgroundImage]}>
          <LocationsContext.Provider value={[locations, setLocations]}>
            <ArticlesContext.Provider value={[articles, setArticles]}>
              {' '}
              {children}
            </ArticlesContext.Provider>
          </LocationsContext.Provider>
        </BackGroundImageContext.Provider>
      </SearchDetailsContext.Provider>
    </AgentContext.Provider>
  );
};
export default Store;
