import { Accordion, AccordionSummary, FormControlLabel, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StyledCheckBox } from '../../../Components/AddInsurance/Components/styles/Insuranse.styled';
import { CheckboxArea } from '../styles/Filters.styled';

const SuppliersPannel = ({ suppliersList, isPC, t, handleSupplierChecked }) => {
  const theme = useTheme();
  return (
    <Accordion elevation={0} expanded={isPC} style={{ marginTop: isPC ? '16px' : '0px' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
        <Typography style={{ fontWeight: 600 }}>
          {isPC ? t('leftPane.filterBySupplierLong') : t('leftPane.filterBySupplierShort')}
        </Typography>
      </AccordionSummary>
      <CheckboxArea style={{ display: 'flex', flexDirection: 'column' }}>
        {suppliersList.map((item, idx) => {
          return (
            <FormControlLabel
              key={idx}
              control={
                <StyledCheckBox
                  onChange={e => handleSupplierChecked(item.supplier, e.target.checked)}
                  key={item.supplier}
                  value={item.checked}
                  size='small'
                  checked={item.checked}
                  checkboxcolor={theme.palette.secondary.main}
                />
              }
              label={<Typography variant='body2'>{item.supplier}</Typography>}
            />
          );
        })}
      </CheckboxArea>
    </Accordion>
  );
};
export default SuppliersPannel;
