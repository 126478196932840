import React from 'react';
import { Typography, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

const OrderConfirmation = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();
  return (
    <Container style={styles.container}>
      <div>
        {' '}
        <Typography variant='h6'>{t('common.thankyou')}</Typography>
        <Typography paragraph>{t('common.stepfourdescription')}</Typography>
      </div>
    </Container>
  );
};
const styles = {
  container: {
    textAlign: 'center',
    padding: '20%',
    height: '75vh',
    display: 'flex',
    alignItems: 'center'
  }
};

export default OrderConfirmation;
