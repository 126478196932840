import { Button, Divider, Grid, InputAdornment, useTheme } from '@mui/material';
import React, { useState } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useTranslation } from 'react-i18next';
import TimePicker from './TimePicker/TimePicker';
import { getLocale } from './utils';
import Fade from '@mui/material/Fade';
import {
  StyledDateRange,
  StyledDateTextField,
  StyledPopover,
} from './styles/DatePicker.styled';
import DateRangeIcon from '@mui/icons-material/DateRange';

const DatePicker = ({
  datePickerLabel,
  lng,
  isPickupDate,
  pickUpDate,
  dropOffDate,
  setFieldValue,
  ismobile,
  ...props
}) => {
  const [set] = React.useState(false);
  const { t } = useTranslation();
  const locale = getLocale(lng);

  const range = {
    startDate: pickUpDate,
    endDate: dropOffDate,
    key: 'selection',
  };
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
    setDatePickerOpen(false);
  };
  const id = anchorEl ? 'calendar' : undefined;
  // const theme = useTheme();
  // const size = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const handleClear = () => {
    setFieldValue('pickUpDate', new Date());
    setFieldValue('dropOffDate', new Date());
  };
  const rangeColor = useTheme().palette.secondary.main;
  return (
    <>
      <Grid container spacing={ismobile ? 1 : 0}>
        <Grid item sm={7} xs={7}>
          <StyledDateTextField
            fullWidth
            aria-describedby={id}
            onClick={(e) => {
              setDatePickerOpen(true);
              setAnchorEl(e.currentTarget);
            }}
            InputProps={{
              style: { fontWeight: 500 },
              startAdornment: (
                <InputAdornment position="start">
                  <DateRangeIcon />
                </InputAdornment>
              ),
            }}
            // inputRef={inputRef}
            variant="outlined"
            label={datePickerLabel}
            value={
              isPickupDate
                ? new Date(pickUpDate).toLocaleDateString('en-GB')
                : new Date(dropOffDate).toLocaleDateString('en-GB')
            }
          />
        </Grid>
        <Grid item sm={5} xs={5}>
          <TimePicker
            {...props}
            // size={size}
            setFieldValue={setFieldValue}
            isPickupDate={isPickupDate}
            pickUpDate={pickUpDate}
            dropOffDate={dropOffDate}
          />
        </Grid>
      </Grid>
      <StyledPopover
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 2 }}
        $isMobile={ismobile}
        id={id}
        onClose={handleClose}
        anchorOrigin={{
          vertical: ismobile ? 'top' : 'bottom',
          horizontal: ismobile ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: isPickupDate ? 'center' : 'right',
        }}
        open={datePickerOpen}
        anchorEl={anchorEl}>
        <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
          <StyledDateRange
            $isMobile={ismobile}
            locale={locale}
            rangeColors={[rangeColor]}
            minDate={new Date()}
            set={set}
            onChange={(item) => {
              setFieldValue('pickUpDate', item.selection.startDate);
              setFieldValue('dropOffDate', item.selection.endDate);
            }}
            showSelectionPreview={true}
            showDateDisplay={false}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[range]}
            scroll={{ enabled: ismobile }}
            direction={ismobile ? 'vertical' : 'horizontal'} //phone vertical
          />
          <Divider />

          <Grid item style={{ textAlign: 'right' }}>
            <Button onClick={handleClear} variant="text">
              {t('buttons.clear')}
            </Button>

            <Button
              style={{ margin: 8, backgroundColor: '#14C2A9', color: 'white' }}
              variant="contained"
              onClick={() => {
                // setSet(true);
                setDatePickerOpen(false);
              }}>
              {t('buttons.done')}
            </Button>
          </Grid>
        </Grid>
      </StyledPopover>
    </>
  );
};

export default DatePicker;
