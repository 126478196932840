import React from 'react';

const StructuredDataSchema = ({ schema }) => {
  return (
    <script className='structured_data_schema' type='application/ld+json'>
      {JSON.stringify(schema)}
    </script>
  );
};

export default StructuredDataSchema;
