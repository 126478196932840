import { Chip } from '@mui/material';
import styled from 'styled-components';

export const Image = styled.img`
  object-fit: contain;
  filter: ${({ selected }) => (selected ? 'grayscale(0%)' : 'grayscale(100%)')};
`;
export const StyledChip = styled(Chip)`
  &.MuiChip-root {
    border-radius: 4px;
  }
`;
