import ADD from './ADD.svg';
import CST from './CST.svg';
import CRB from './CRB.svg';
import CSI from './CSI.svg';
import BST from './BST.svg';
import GPS from './GPS.svg';
import APF from './APF.svg';
import UAD from './UAD.svg';
import SSC from './SSC.png';
import SCH from './SCH.svg';
import ThreeG from './3G.png';
import CSB from './CSB.svg';
import CBS from './CBS.png';
import CNL from './CNL.svg';
import AFT from './AFT.svg';
import DEL from './DEL.svg';
import ONEW from './ONEW.svg';
import Ferry from './Ferry.svg';
import UNL from './UNL.png';
import STR from './STR.svg';
import WIFI from './WIFI.svg';
import defaultIcon from './default.svg';
import SKR from './SKR.png';
import SNT from './SNT.svg';
import DSL from './DSL.svg';
const icons = {
  DSL,
  SNT,
  SKR,
  Ferry,
  ONEW,
  ADD,
  CST,
  CSI,
  BST,
  GPS,
  APF,
  UAD,
  SSC,
  CRB,
  SCH,
  ThreeG,
  CSB,
  CBS,
  DEL,
  AFT,
  CNL,
  UNL,
  STR,
  WIFI,
};
//Ferry
/**
 * ONEW one way fee
 *
 */
export const getIcon = (code) => {
  switch (code) {
    case 'WIFI':
      return icons.WIFI;
    case 'AFTP':
    case 'AFTD':
      return icons.AFT;
    case 'Xboar':
      return icons.CRB;
    case 'YDF':
      return icons.UAD;
    case 'NAV':
      return icons.GPS;
    case 'CST':
      return icons.BST;
    case 'CBS':
      return icons.CBS;
    case '3G':
      return icons.ThreeG;
    case 'BBS':
      return icons.CSI;
    case 'BST':
      return icons.CBS;
    case 'OWF':
      return icons.ONEW;
    default:
      if (icons[code]) {
        return icons[code];
      } else return defaultIcon;
  }
};
