import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const FeatureIcon = styled.img`
  vertical-align: middle;
  width: 16px;
  height: 16px;
`;
export const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  display: inline-block;
`;
export const FeatureIconFontAwesome = styled(FontAwesomeIcon)`
  font-size: 16px;
`;
