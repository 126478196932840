import { Paper } from '@mui/material';
import styled from 'styled-components';
import { GreenBorderTextField } from '../../../../../ReusableComponents/StyledComponents';

export const DetailsWraper = styled(Paper)`
  margin-top: 16px;
  display: flex;
  justify-content: center;
`;

export const PhoneCode = styled(GreenBorderTextField)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .MuiOutlinedInput-root {
    border-radius: 2px 0px 0px 2px;
  }

  flex-basis: 140px;
`;

export const InputField = styled(GreenBorderTextField)`
  .MuiFormLabel-colorSecondary.Mui-focused {
    font-weight: 500;
  }
  .MuiOutlinedInput-root {
    border-radius: ${({ $borderRadius }) => $borderRadius || '2px'};
  }
`;
