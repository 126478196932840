import React from 'react';
import styled from 'styled-components';
import { Skeleton } from '@mui/lab';
import { Paper, useTheme } from '@mui/material';

const Image = styled.img`
  max-width: 100%;
  max-height: 60px;
  min-height: 30px;
`;
const SupplierImage = ({ supplier }) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const { direction } = useTheme();
  return (
    <Paper
      variant="outlined"
      elevation={0}
      style={{
        padding: 2,
        textAlign: direction === 'ltr' ? 'left' : 'right',
        maxWidth: 80,
        display: 'flex',
        alignItems: 'center',
      }}>
      {!imageLoaded && <Skeleton variant="rect" width={80} height={40} />}

      <Image
        src={supplier && supplier.image}
        alt={supplier && supplier.name}
        onLoad={() => setImageLoaded(true)}
      />
    </Paper>
  );
};

export default SupplierImage;
