import { SwipeableDrawer } from '@mui/material';
import styled from 'styled-components';

export const StyledDrawer = styled(SwipeableDrawer)`
  .MuiPaper-root {
    background-color: ${(props) => props.color};
  }
  .Mui-Selected {
    /* background-color:${(props) => props.buttonColor} */
  }
`;
