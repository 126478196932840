import CarCard from '../../../Layouts/CarCard/CarCard';

import React from 'react';

const LoadingSkeletonCards = () => (
  <>
    <CarCard isLoading={true} />
    <CarCard isLoading={true} />
    <CarCard isLoading={true} />
  </>
);
export default LoadingSkeletonCards;
