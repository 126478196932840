import { Accordion, AccordionSummary, FormControlLabel, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { StyledCheckBox } from '../../../Components/AddInsurance/Components/styles/Insuranse.styled';
import { CheckboxArea } from '../styles/Filters.styled';
import { useState } from 'react';
import { useEffect } from 'react';

const FuelTypesPanel = ({ fuelTypes, setFuelTypes, filteredCars, isPC, cars }) => {
  const { t } = useTranslation();
  const [types, setTypes] = useState(fuelTypes);

  useEffect(() => {
    setTypes(fuelTypes);
  }, [fuelTypes]);

  // const carsToFilter = filteredCars.length > 0 ? filteredCars : cars;
  const handleChecked = (name, value) => {
    const tempFuelTypes = [...fuelTypes];
    const idx = tempFuelTypes.findIndex(gb => gb.type === name);
    if (idx > -1) {
      tempFuelTypes[idx].isChecked = value;
      setFuelTypes(tempFuelTypes);
    }
  };
  const [expanded, setExpanded] = useState(true);

  const theme = useTheme();
  return (
    <Accordion
      elevation={0}
      expanded={expanded}
      onChange={(e, isExpanded) => setExpanded(isExpanded)}
      style={{ marginTop: isPC ? '16px' : '0px' }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography style={{ fontWeight: 600 }}>{isPC ? t('fuelType.name') : t('fuelType.name')}</Typography>
      </AccordionSummary>
      <CheckboxArea>
        {types &&
          types.length > 0 &&
          types.map(fuel => {
            const { type, isChecked } = fuel;
            return (
              <FormControlLabel
                key={type}
                control={
                  <StyledCheckBox
                    size='small'
                    key={type}
                    checked={isChecked}
                    name={type}
                    onChange={e => handleChecked(e.target.name, e.target.checked)}
                    value={type}
                    checkboxcolor={theme.palette.secondary.main}
                  />
                }
                label={<Typography variant='body2'>{type}</Typography>}
              />
            );
          })}
      </CheckboxArea>
    </Accordion>
  );
};

export default FuelTypesPanel;
