import React from 'react';
import { Container, Typography, Grid, Hidden, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Body from './Components/Body';
import CarPicker from '../../Layouts/Picker/CarPicker/CarPicker';
import { useTheme } from 'styled-components';

const PageNotFound = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    i18n: {
      languages: [lng]
    }
  } = useTranslation();

  const title =
    lng === 'en'
      ? '404 Page not found'
      : lng === 'ru'
      ? '404 Страница не найдена'
      : lng === 'he'
      ? 'שגיאה 404. מצטערים, דף זה לא נמצא'
      : '';
      
      
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container style={{ padding: isMobile ? '32px 16px' : 64 }}>
        <Grid container spacing={1} direction='row'>
          <Hidden smDown>
            {' '}
            <Grid item sm={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img
                alt='stop sign'
                src='https://res.cloudinary.com/www-we4rent-com/image/upload/c_fill,w_408/v1681776563/Page%20Images/traffic-sign-g9b77aeb7f_1280_ckzgkl.png'
              />
            </Grid>
          </Hidden>
          <Grid item sm={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography align='left' variant='h4'>
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Body language={lng} />
              </Grid>
            </Grid>
            <CarPicker isMain={false} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default PageNotFound;
