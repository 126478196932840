import { Container, Paper } from '@mui/material';
import styled from 'styled-components';

export const BlueHeader = styled.div`
  background: #16596b;
  padding-top: 100px;
  height: ${({ $ismobile }) => ($ismobile ? '250px' : '300px')};
  margin-top: -64px;
  color: white;
`;

export const BodyWrapper = styled(Container)`
  /* padding-bottom: 100px; */
  padding: ${({ ismobile }) => (ismobile ? '0 0 100px' : '0 16px 100px')} !important;
`;

export const ContentBackground = styled(Paper)`
  margin-top: -100px;
  padding: ${({ ismobile }) => (ismobile ? '8px' : '32px')};
  height: 100%;
`;
