import React from 'react';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { CurrencyContext } from '../../SharedState';

const TransparentHeader = ({ children }) => {
  const [currency, setCurrency] = React.useState({
    code: 'USD',
    sign: '$',
    icon: <FontAwesomeIcon style={{ fontSize: '20px' }} icon={faEuroSign} />,
  });

  return (
    <>
      <CurrencyContext.Provider value={[currency.icon, setCurrency]}>
        <Header isTransparent={false} withShadow={false} position={'static'} />
      </CurrencyContext.Provider>
      {children}
      <Footer />
    </>
  );
};

export default TransparentHeader;
