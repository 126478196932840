import React from 'react';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
// import PolicyIcon from '@mui/icons-material/Policy';
import AssignmentIcon from '@mui/icons-material/Assignment';
import InfoIcon from '@mui/icons-material/Info';
import LocationIcon from '@mui/icons-material/LocationOn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompass } from '@fortawesome/free-solid-svg-icons';
const ItemIcon = ({ icon, code }) => {
  if (code === 'RS') {
    return (
      <FontAwesomeIcon style={{ fontSize: 15, padding: '5px 5px 5px 0px' }} icon={faCompass} />
    );
  }
  switch (icon) {
    case 'deposit':
      return <CreditCardIcon fontSize="small" />;
    case 'fuelPolicy':
    case 'fuel':
      return <LocalGasStationIcon fontSize="small" />;
    case 'insurance':
      return <AssignmentIcon fontSize="small" />;
    case 'LK': //limited km
      return <InfoIcon fontSize="small" />;
    case 'location':
      return <LocationIcon fontSize="small" />;
    default:
      return <></>;
  }
};

export default ItemIcon;
