import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const SecondaryLayout = ({ children }) => {
  return (
    <>
      <Header withShadow={false} withoutLogo={false} headerWithBackground={true} isTransparent={false} />
      {children}
      <Footer />
    </>
  );
};

export default SecondaryLayout;
