import React from 'react';
import { Helmet } from 'react-helmet';
//logo url https://www.we4rent.com/images/logo.png
/**
 *
 * @param {*} param0 language | canonical url | type | title | description | image url
 */
export const SEOdata = ({ lang, canonicalUrl, type, title, description, image, noIndex, keywords }) => {
  return (
    <Helmet htmlAttributes={{ lang }}>
      <title>{title}</title>
      <link rel='canonical' href={canonicalUrl} />
      <meta name='description' content={description ? description.trim() : ''} />
      <meta name='keywords' content={keywords} />
      <meta property='og:url' content={canonicalUrl} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description ? description.trim() : ''} />
      <meta property='og:image' content={image} />
      <meta property='og:type' content={type} />
      <meta property='fb:app_id' content='108281041034261' />
      {noIndex && <meta name='robots' content='noindex' />}
    </Helmet>
  );
};
