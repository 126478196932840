import {
  Button,
  Dialog,
  Divider,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import ReactHtmlParser from 'html-react-parser';
import LocationMap from '../../OrderSummary/Components/StationDescriptionDialog/LocationMap';
import StationDescriptionContent from '../../OrderSummary/Components/StationDescriptionDialog/StationDescriptionContent';

const SingleStationDescriptionDialog = ({
  setDialogOpen, // {status, type, payload}
  status,
  payload: { station, apiType },
}) => {
  const coordinates = station.coordinates;

  const closeDialog = () => {
    setDialogOpen({ status: false, type: '', payload: {} });
  };
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { direction } = theme;
  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      dir={direction}
      fullScreen={isMobile}
      open={status}
      onClose={closeDialog}>
      <DialogTitle>
        <Typography component="span" variant="h5">
          {station.title}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent
        style={{
          padding: isMobile ? '8px 8px' : '24px',
          whiteSpace: 'normal',
        }}>
        {station.page ? (
          ReactHtmlParser(station.page)
        ) : (
          <StationDescriptionContent {...station} />
        )}
        {station.showMap && (
          <LocationMap
            title={station.title}
            isMobile={isMobile}
            locationName={station.title}
            // location={station}
            // zip={zip}
            address={station.address}
            city={station.city}
            state={station.state}
            coordinates={coordinates}
            phone={station.phone}
            openingHours={station.OpeningHours}
            supplier={station.supplier}
          />
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={closeDialog}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SingleStationDescriptionDialog;
