import { ru, he, enGB } from 'date-fns/locale';

// export const getDefuaultLocation = (pickUpDropOffLocation, tempSearchCountry) => {
//   return pickUpDropOffLocation === ''
//     ? tempSearchCountry === 'il'
//       ? europcarLocations[0]
//       : trcLocations[0]
//     : pickUpDropOffLocation;
// };

export const getLocaleByLanguage = language => {
  switch (language) {
    case 'ru':
      return ru;
    case 'he':
      return he;
    case 'en':
      return enGB;
    default:
      return enGB;
  }
};

export const dialogText = language => {
  switch (language) {
    case 'ru':
      return `Мы сожалеем, поиск по вашему запросу не дал результатов.
    Большинство пунктов проката имеют ограниченные часы работы.
    Мы рекомендуем ознакомиться с временем работы филиалов и изменить запрос соответственно:
    воскресенье  - четверг: с 08:00 до 17:00
    пятница: с 08:00 до 13:00
    суббота: закрыто
    Филиал в Аэропорту Бен Гурион работает круглосуточно 24/7( Исключение Судный день закрыто с 12:00 27 Сентября 2020 по 22:00 28 Сентября 2020 )
    `;
    case 'he':
      return `אנחנו מצטערים, לא נמצאו תוצאות לשאילתה שביקשת. 
    אי קבלת תוצאות עלול לנבוע עקב אחת מהסיבות הבאות, כך שבמידת האפשר אנו ממליצים לך לשנות את אחד הפרמטרים מטה ולנסות בשנית: 
    
    - התחנה אינה פעילה בשעת הלקיחה או ההחזרה בתאריכים אלו (יתכן שעקב חג או עונתיות) 
    - קבוצת הרכב אותה ביקשת אינה זמינה בתאריכים אלו או בתחנה זו. ניתן לנסות חיפוש לקטגוריה אחרת או ללא סינון קטגוריה. 
    - גיל הנהג לא רלוונטי לקבוצה המבוקשת, ניתן לנסות חיפוש לקטגוריה אחרת או ללא סינון קטגוריה. 
    - תאריך הלקיחה רחוק מדי וטרם זמין לסגירת הזמנה 
    
    אם עדיין לא התקבלו תוצאות, ניתן לפנות אלינו בטלפון 1-700-700-500 או למלא את טופס יצירת הקשר ונחזור אליכם בהקדם.
    `;
    default:
      return `We are sorry, it is no results for your query.
    Most car rental stations have limited opening hours.
    We recommend you to check opening hours below and change pick up / drop off station, dates or hours accordingly.
    Sun  - Thursday from 08:00 till 17:00
    Friday: from 08:00 till 13:00
    Saturday: closed
    Please note - Ben Gurion Airport Station is open 24/7 (an exception - Judgment day closed from 12:00 27 Sep 2020 till 22:00 28 Sep 2020)
    
    If you still need assistance for your query, please contact our call center by phone/whatsapp/viber +972-542041614 or send us an email reservations@we4rent.com   
    `;
  }
};
export const getURL = (searchDetails, sippCode, lng) => {
  const url =
    `/search/cc?pl=${
      searchDetails.pickUpLocation && searchDetails.pickUpLocation.LocationCode
        ? searchDetails.pickUpLocation.LocationCode
        : ''
    }&dl=${
      searchDetails.dropOffLocation && searchDetails.dropOffLocation.LocationCode
        ? searchDetails.dropOffLocation.LocationCode
        : ''
    }&age=${
      searchDetails.driverAge
    }&pickUpYear=${searchDetails.pickUpDate.getFullYear()}&pickUpMonth=${searchDetails.pickUpDate.getMonth()}&pickUpDay=${searchDetails.pickUpDate.getDate()}&pickUpHour=${
      searchDetails.pickUpTime
    }` +
    `&dropOffYear=${searchDetails.dropOffDate.getFullYear()}&dropOffMonth=${searchDetails.dropOffDate.getMonth()}&dropOffDay=${searchDetails.dropOffDate.getDate()}&dropOffHour=${
      searchDetails.dropOffTime
    }` +
    `&sd=${searchDetails.drivingOnSaturday}` +
    `${sippCode ? `&sippcode=${sippCode}` : ''}` +
    `&country=${searchDetails.country.code}&locationname=${
      searchDetails.pickUpLocation && searchDetails.pickUpLocation.LocationName
        ? searchDetails.pickUpLocation.LocationName[lng]
        : ''
    }`;
  return url;
};

export const validate = (values, t) => {
  const { driverAge } = values;
  let errors = {};
  if (values.pickUpTime === '-' || values.dropOffTime === '-') {
    errors.branchClosed = 'The branch is closed on this day';
  }
  if (!values.country) {
    errors.country = 'Please choose a country';
  }
  if (!values.pickUpLocation.LocationCode) {
    errors.pickUpLocation = t('picker.carpicker.errors.chooseLocation', {
      location: t('picker.carpicker.pickUpLocation').toLowerCase()
    });
  }
  if (!values.dropOffLocation.LocationCode) {
    errors.dropOffLocation = t('picker.carpicker.errors.chooseLocation', {
      location: t('picker.carpicker.dropOffLocation').toLowerCase()
    });
  }
  if (isNaN(driverAge) || driverAge < 18) {
    errors.driverAge = t('picker.carpicker.driverAge.error');
  }
  if (!values.pickUpTime) {
    errors.pickUpTime = t('picker.carpicker.chooseTime');
  }
  if (!values.dropOffTime) {
    errors.dropOffTime = t('picker.carpicker.chooseTime');
  }
  return errors;
};
