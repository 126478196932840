import axios from 'axios';

const host =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? 'http://localhost:4000'
    : 'https://www.we4rent.com';

/**
 * @param {String} url
 */
const apiUrl = params => {
  return `${host}/agent/${params}`;
};

export const loginAgent = (email, password) => {
  return axios.post(`${apiUrl('auth/login')}`, { email, password });
};
