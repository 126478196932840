import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Link
} from '@mui/material';
import React from 'react';
import Filters from '../../LeftPane/Filters/Filters';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Title = styled(DialogTitle)`
  .MuiTypography-root {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

const FiltersDialog = ({ open, setOpen, filteredCars, ...rest }) => {
  const cars = filteredCars.filteredCars;
  const { t } = useTranslation();
  return (
    <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
      <Title style={{ padding: 16 }}>
        <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>Filters</div>
        <IconButton onClick={() => setOpen(false)} style={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </Title>
      <Divider />

      <DialogContent>
        <Filters filteredCars={filteredCars} {...rest} />
      </DialogContent>
      <Divider />
      <Grid container style={{ padding: '16px' }}>
        <Grid xs={6} item style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <div>
              {t('filters.availableCars')} <span style={{ fontWeight: 500 }}>{cars.length}</span>
            </div>
            <Link color='primary'>{t('filters.resetFilters')}</Link>
          </div>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={() => setOpen(false)}
            variant='contained'
            color='secondary'
            style={{ zIndex: 1000, color: 'white' }}
          >
            {t('filters.applyFilters')}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default FiltersDialog;
