import { loginAgent } from '../../../../../../../utils/requests/agentRequests';

export const agentLogin = async (email, password) => {
  try {
    const {
      data: { accessToken, refreshToken }
    } = await loginAgent(email, password);
    if (accessToken && refreshToken) {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      return { accessToken, refreshToken, success: true, error: null };
    } else {
      return { seccess: false, error: 'Internal server error' };
    }
  } catch (err) {
    console.error(err);
    return { success: false, error: err.response.data.message };
  }
};


export const validateForm = values => {
  let errors = {};
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  if (!values.email) {
    errors.email = 'Cannot be blank';
  } else if (!regex.test(values.email)) {
    errors.email = 'Invalid email format';
  }
  if (!values.password) {
    errors.password = 'Cannot be blank';
  } else if (values.password.length < 4) {
    errors.password = 'Password must be more than 4 characters';
  }
  return errors;
};