import { Button } from '@mui/material';
import styled from 'styled-components';

export const SearchButton = styled(Button)`
  &.MuiButton-contained {
    color: white;
  }
  width: 100%;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  font-weight: 600;
  font-weight: ${({ lng }) => (lng === 'ru' ? 600 : 500)};
  height: ${({ ispc }) => (ispc ? '55.95px' : 'auto')};
`;
