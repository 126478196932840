import { FormControl, FormHelperText } from '@mui/material';
// import { useStyles } from '@mui/x-date-pickers/views/Calendar/SlideTransition';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GreenBorderTextField } from '../../../../../../ReusableComponents/StyledComponents';
import { StyledTimePicker } from '../styles/DatePicker.styled';
import { timeSlots } from '../utils';
import { isSameDay } from 'date-fns';

const TimePicker = ({
  ismobile,
  handleTimeSelected,
  size,
  location,
  isPickupDate,
  pickUpDate,
  pickUpTime,
  dropOffDate,
  date,
  time,
  country,
  timeError,
  timeErrorValue,
  helperText
}) => {
  // const classes = useStyles();
  let arrayOfDisabledOptions = [];
  let closed = time === '-';
  const { OpeningHours } = location;

  const firstAvailableTime = time => {
    if (!isPickupDate && isSameDay(pickUpDate, dropOffDate)) {
      let i = 0;
      do {
        arrayOfDisabledOptions.push(timeSlots[i]);
        i++;
      } while (timeSlots[i] !== pickUpTime && i < 48);
    }
    if (pickUpDate)
      if (arrayOfDisabledOptions.length === timeSlots) {
        return time;
      }
    if (arrayOfDisabledOptions.includes(time)) {
      let i = 0;
      while (timeSlots[i] === arrayOfDisabledOptions[i] && i < timeSlots.length) ++i;
      if (i === 48) {
        return '-';
      } else return timeSlots[i];
    } else return time;
  };

  if (OpeningHours && OpeningHours.length > 1) {
    const [open, close] = OpeningHours[date.getDay()].split('-');
    const openIdx = timeSlots.findIndex(slot => slot === open);
    const closeIdx = timeSlots.findIndex(slot => slot === close);

    if (open !== close) {
      timeSlots.forEach((slot, idx) => {
        if (idx < openIdx) {
          arrayOfDisabledOptions.push(timeSlots[idx]);
        }
        if (idx > closeIdx) {
          arrayOfDisabledOptions.push(timeSlots[idx]);
        }
      });
    }
  } else {
    // Default hours for all locations
    timeSlots.forEach((slot, idx) => {
      if (idx < 16 || idx > 46) {
        arrayOfDisabledOptions.push(timeSlots[idx]);
      }
    });
  }

  const defaultTime = firstAvailableTime(time);
  if (defaultTime !== time) {
    handleTimeSelected(defaultTime, isPickupDate);
  }
  // console.log('defaultTime', defaultTime, time);
  // useEffect(() => {
  //   if (defaultTime !== time) {
  //     handleTimeSelected(defaultTime, isPickupDate);
  //   }
  // }, [defaultTime, time]);
  const {
    t,
    i18n: {
      languages: [lng]
    }
  } = useTranslation();
  return (
    <FormControl fullWidth>
      <StyledTimePicker
        size={size ? 'small' : 'medium'}
        // classes={{
        //   option: classes.option
        // }}
        forcePopupIcon={!closed}
        disableClearable
        disabled={closed}
        options={timeSlots}
        freeSolo={true}
        getOptionDisabled={option => arrayOfDisabledOptions.indexOf(option) > -1}
        onChange={(event, value) => handleTimeSelected(value, isPickupDate)}
        value={
          arrayOfDisabledOptions.indexOf(defaultTime) > -1
            ? ''
            : closed
            ? t('picker.carpicker.closed')
            : defaultTime
        }
        renderInput={params => (
          <GreenBorderTextField
            {...params}
            $closed={closed}
            label={t('picker.carpicker.time')}
            variant='outlined'
            error={timeError}
            helperText={timeErrorValue}
            fullWidth
          />
        )}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default TimePicker;
