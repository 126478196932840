import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/lab/Autocomplete';
import Option from './Components/Option/Option';
import axios from 'axios';
import ListItem from '@mui/material/ListItem';
import { apiUrl } from '../../../../../../utils';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Search from '@mui/icons-material/Search';
import {
  SearchLocationInputTextField,
  StyledPopper,
} from './styles/SearchLocation.styled';
import debounce from 'lodash/debounce';
import { useCallback } from 'react';

const SearchLocation = ({
  isMobile,
  isDifferentLocation,
  locations,
  setLocations,
  helperText,
  setFieldValue,
  isPickupDate,
  country,
  location,
  error,
}) => {
  const {
    t,
    i18n: {
      languages: [lng],
    },
  } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getOptions = useCallback(
    debounce(async (term) => {
      if (term) {
        setLoading(true);
        setLocations({ countryCode: '', stations: [] });
        try {
          const response = await axios.get(
            apiUrl(`searchlocation?term=${term}&lng=${lng}`)
          );
          if (response.data) {
            setLocations({ countryCode: country, stations: response.data });
            setLoading(false);
          }
        } catch (err) {
          console.log('Error in getting the stations', err);
          setLoading(false);
        }
      }
    }, 250),
    []
  );

  useEffect(() => {
    getOptions(searchTerm);
  }, [searchTerm, getOptions]);

  const PopperComponent = (props) => {
    return (
      <StyledPopper
        {...props}
        placement="bottom-start"
        $isDifferentLocation={isDifferentLocation}
        $isMobile={isMobile}
        // transition
      />
    );
  };
  const label = isPickupDate
    ? t('picker.carpicker.pickUpLocation')
    : t('picker.carpicker.dropOffLocation');
  return (
    <Autocomplete
      slots={{
        popper: PopperComponent,
      }}
      selectOnFocus={true}
      // PopperComponent={PopperComponent}
      value={location}
      noOptionsText={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Search />
          <span style={{ marginLeft: 4 }}>
            {t('picker.carpicker.searchText')}
          </span>
        </div>
      }
      freeSolo={true}
      filterOptions={(x) => x}
      // size={autocompleteSize ? 'small' : 'medium'}
      options={locations.stations || []}
      loading={loading}
      loadingText={t('common.loading') + '...'}
      groupBy={(option) => {
        return t(
          `picker.carpicker.locationSelection.${option.LocationType.toLowerCase()}.${option.LocationType.toLowerCase()}`,
          {
            count: 0,
          }
        );
      }}
      // renderGroup={params => {
      //   console.log('PARAMS', params);
      //   return params.Group;
      // }}

      getOptionLabel={(option) =>
        option.LocationName ? option.LocationName[lng] : null
      }
      isOptionEqualToValue={(option, value) => {
        if (option.LocationCode === '') {
          return true;
        }
        return option && value
          ? option.LocationCode === value.LocationCode
          : null;
      }}
      // getOptionSelected={(option, value) => {
      //   console.log(option, value);
      //   if (option.LocationCode === '') {
      //     return true;
      //   }
      //   return option && value
      //     ? option.LocationCode === value.LocationCode
      //     : null;
      // }}

      onChange={(event, selectedLocation) => {
        if (selectedLocation) {
          if (isPickupDate) {
            setFieldValue('country', selectedLocation?.country);
          }
          return isPickupDate
            ? (setFieldValue('pickUpLocation', selectedLocation),
              setFieldValue('dropOffLocation', selectedLocation),
              setFieldValue(
                'currency',
                selectedLocation.country.code === 'il' && lng === 'he'
                  ? 'ILS'
                  : selectedLocation.currency?.using
              ))
            : setFieldValue('dropOffLocation', selectedLocation);
        } else return null;
      }}
      renderOption={(props, option) => {
        const { LocationType, LocationName, country } = option;
        return (
          <ListItem {...props} key={option.LocationCode}>
            <Option
              LocationType={LocationType}
              LocationName={LocationName ? LocationName[lng] : ''}
              country={country ? country.name[lng] : ''}
              code={country?.code}
            />
          </ListItem>
        );
      }}
      renderInput={(params) => {
        return (
          <SearchLocationInputTextField
            // $fontWeight={lng === 'he' ? 400 : 500}
            $isdifferentlocation={isDifferentLocation}
            ispickupdate={isPickupDate ? 1 : 0}
            {...params}
            onChange={(e) => setSearchTerm(e.target.value)}
            error={error}
            helperText={helperText}
            variant="outlined"
            label={location.LocationCode ? label : ''}
            margin="normal"
            fullWidth
            placeholder={label}
            slotProps={{
              input: {
                ...params.InputProps,
                // label: label,
                startAdornment: <LocationOnIcon />,
                endAdornment: <>{params.InputProps.endAdornment}</>,
              },
            }}
          />
        );
      }}
    />
  );
};

export default SearchLocation;
