import React, { useState } from 'react';
import NavBar from './NavBar/NavBar';
import LeftDrawer from './LeftDrawer';
import { Hidden } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Header = ({ withShadow, withoutLogo, isTransparent, position, headerWithBackground }) => {
  const { t } = useTranslation();
  const menuItems = [
    { navItemText: 'Language', link: '/' },
    { navItemText: 'Currency', link: '/' },
    { navItemText: t('header.myReservations'), link: '/reservation' },
    { navItemText: t('blog.blog'), link: '/blog' }
  ];
  const drawerMenuItems = [
    { navItemText: t('header.myReservations'), link: '/reservation' },
    { navItemText: t('blog.blog'), link: '/blog' },
    { navItemText: t('footer.contact'), link: '/contact' }
  ];
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = open => event => {
    // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //     return;
    // }
    setIsDrawerOpen(open);
  };

  return (
    <>
      <NavBar
        headerWithBackground={headerWithBackground}
        position={position}
        isFixed={true}
        isTransparent={isTransparent}
        menuItems={menuItems}
        toggleDrawer={toggleDrawer}
        withShadow={withShadow}
        withoutLogo={withoutLogo}
      />
      <Hidden smUp>
        <LeftDrawer
          menuItems={drawerMenuItems}
          isOpen={isDrawerOpen}
          toggleDrawer={toggleDrawer}
          // onOpen={handleOpen}
        />
      </Hidden>
    </>
  );
};

export default Header;
