import { Breadcrumbs, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import BreadcrumbsSchema from '../../../../../../utils/SEO/schemas/BreadcrumbsSchema';

const StyledBreadcrumbs = styled(Breadcrumbs)`
  padding: ${({ $ismobile }) => $ismobile && '16px 16px 0px 16px'};

  .MuiBreadcrumbs-separator {
    color: #7da0a3;
  }
`;

const crumb = (position, name, url) => {
  return {
    '@type': 'ListItem',
    position: position,
    name,
    ...(url && { item: url }),
  };
};

export const BreadCrumb = ({ link, children, fontSize }) => {
  const style = { color: '#7da0a3', fontSize: fontSize || 11 };
  return link ? (
    <Link to={link} style={{ ...style }}>
      <Typography style={style}>{children}</Typography>
    </Link>
  ) : (
    <Typography style={style}>{children}</Typography>
  );
};

const BreadCrumbs = ({ locationName, country, isMobile }) => {
  const { location } = useParams();
  const {
    t,
    i18n: {
      languages: [lng],
    },
  } = useTranslation();
  const homepage = t('breadcrumbs.carHire');

  const getCrumbs = ({ country, countryName, location, locationName, lng }) => {
    const crumbs = [];
    crumbs.push(crumb(1, homepage, `https://www.we4rent.com/${lng}`));
    if (country) {
      crumbs.push(
        crumb(
          2,
          countryName,
          `https://www.we4rent.com/${lng}/country/${country}`
        )
      );
    }
    if (location) {
      crumbs.push(crumb(3, locationName, ''));
    }
    return crumbs;
  };
  // console.log(params);
  let countryName = '';
  if (country) {
    countryName = t(`countries.${country}.${country}`);
  }
  const crumbs = getCrumbs({
    country,
    countryName,
    location,
    locationName,
    lng,
  });

  return (
    <>
      {country && (
        <>
          <StyledBreadcrumbs
            aria-label="breadcrumb"
            separator="›"
            $ismobile={isMobile}>
            <BreadCrumb link="/">{homepage}</BreadCrumb>
            <BreadCrumb link={location ? `/country/${country}` : ''}>
              {countryName}
            </BreadCrumb>
            {location && <BreadCrumb link={''}>{locationName}</BreadCrumb>}
          </StyledBreadcrumbs>
          <BreadcrumbsSchema crumbs={crumbs} />
        </>
      )}
    </>
  );
};

export default BreadCrumbs;
