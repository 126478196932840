import Grid from '@mui/material/Grid2';
import { Skeleton } from '@mui/lab';
import React from 'react';
import { InformationSection, OrderButton } from './Components';

const BottomSection = ({
  disableOrderButton,
  carSupplierInformation,
  importantItems,
  supplier,
  country,
  handleBookButtonClicked,
  carId,
  isLoading,
  apiType,
  apiData,
  reservationInformation,
  sippCode,
}) => {
  return (
    <Grid container justifyContent="space-between">
      <Grid size={{ xs: 8 }} style={{ display: 'flex' }}>
        {isLoading ? (
          <Skeleton variant="text">
            {' '}
            <InformationSection
              supplier={supplier}
              country={country}
              importantItems={importantItems}
            />
          </Skeleton>
        ) : (
          <InformationSection
            apiData={apiData}
            carId={carId}
            carSupplierInformation={carSupplierInformation}
            supplier={supplier}
            country={country}
            apiType={apiType}
            importantItems={importantItems}
            reservationInformation={reservationInformation}
            sippCode={sippCode}
          />
        )}
      </Grid>
      <Grid size={{ xs: 4 }} style={{ display: 'flex', alignItems: 'center' }}>
        <OrderButton
          disableOrderButton={disableOrderButton}
          isLoading={isLoading}
          handleBookButtonClicked={handleBookButtonClicked}
          carId={carId}
        />
      </Grid>
    </Grid>
  );
};

export default BottomSection;
