import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React from 'react';
import { Conditions } from './styledComponents';
import { ExpandedPanel, ParagraphHeading } from './styledComponents';
const RUEuropcarIlRentalConditions = ({ isMobile }) => (
  <Conditions>
    <Typography paragraph>
      <ParagraphHeading>НДС:</ParagraphHeading> Граждане Израиля или лица, имеющие израильское либо двойное
      гражданство, обязаны платить НДС в соответствии с законом. Только лица, имеющие туристическую визу типа
      В2 и В3, освобождены от оплаты НДС. Важно: Для освобождения от оплаты НДС необходимо во время оформления
      контракта на стойке компании иметь при себе Вкладыш с визой .
    </Typography>
    <Typography paragraph>
      <ParagraphHeading>Аэропортовый сбор: </ParagraphHeading>
      Оплата в размере $39 взимается с каждого, кто берет либо возвращает автомобиль в международных
      Аэропортах Тель Авив Бен Гурион и Эйлат Рамон .
    </Typography>
    <Typography paragraph>
      <ParagraphHeading>Аренда в одном направлении</ParagraphHeading> возможна между всеми филиалами
      Бесплатно, кроме г. Эйлат. Сбор за возврат из\в филиал в г.Эйлат будет взиматься в соответствии: 1-2 дня
      аренды: $200, 3 дня аренды и более: бесплатно.
      <br />
    </Typography>

    <Typography paragraph>
      <ParagraphHeading>Доставка и возврат</ParagraphHeading> доступны по предварительной договоренности и
      требуют подтверждения. До 15 км от месторасположения филиала: $15 До 30 км от месторасположения филиала:
      $25 До 50 км и больше от месторасположения филиала: $40
    </Typography>
    <Typography paragraph component={'div'}>
      <Typography variant='h6' style={{ fontWeight: 'bold' }}>
        Страхование
      </Typography>
      Страхование <ParagraphHeading> (CDW/LDW & TP)</ParagraphHeading> покрывают ущерб причиненный автомобилю
      за исключением франшизы, сумма зависит от категории автомобиля.
      <br />
      Полная страховка <ParagraphHeading> (Super CDW)</ParagraphHeading> не обязательная и снижает
      ответственность арендатора до Нуля в случае ущерба автомобилю. Приобрести данный вид страхования
      возможно только в дополнение к базовому полису(CDW/LDW & TP).
      <br />
      Полная страховка <ParagraphHeading>(Super TP)</ParagraphHeading> не обязательная и снижает
      ответственность арендатора до Нуля в случае угона арендованнго автомобиля. Приобрести данный вид
      страхования возможно только в дополнение к базовому полису (CDW/LDW & TP).
      <br />
      <ParagraphHeading>Личная ответственность(франшиза):</ParagraphHeading> Сумма франшизы зависит от
      категории автомобиля $500 - $1000. Данный вид страхования не покрывает повреждения, нанесенные шинам,
      колесам, стеклам, крыше, а так же ходовой части автомобиля. Арендатор обязан выплатить полную сумму
      ущерба, нанесенного этим частям автомобиля.
      <br />
    </Typography>
    <Typography paragraph>
      Модели автомобилей представлены исключительно для примера и при выдаче компания имеет право предоставить
      автомобиль того же класса, но другой марки. Минимальная плата за аренду составляет 24 часа. Опоздание по
      возврата Опоздание в 1 час и более соответствует 1/3 от дневной стоимости. 3 часа и более соответствует
      стоимости полного дня аренды. Ответственность за использование автомобиля ложится на арендатора в
      соответствии с контрактом. Каждый дополнительный водитель должен быть вписан в контракт. Топливо:
      Поставляется с полным баком и рекомендуется вернуть с полным баком. При возврате с недостающим топливом
      взымается дополнительная плата в размере 30% от стоимости. Ранний возврат: В случае возврата раннее
      указанного срока, оплата производиться в соответствии с релевантным тарифом аренды (ежедневно,
      еженедельно, ежемесячно и т.д.) Парковочные штрафы, дорожные штрафы & платные дороги: В случае неуплаты
      одного из нарушений, описанных выше, будет взиматься дополнительная плата в размере 50 ILS. Свободное
      бронирование для следующих категорий: B(EBMR), Q(EBAR), C(ECAR), D(CCAR), E(CDAR, F(IDAR), I(SDAR),
      IW(CWAR), H(FDAR), M(PDAR). Для бронирования остальных категории требуется подтверждение от нашего
      отдела заказов. Отмена предупреждение не позднее чем за 24 часа до получения. Отмена 7-8-9 местных
      автомобилей за 48 часов до получения. Иначе будет оплата в размере одного дня аренды. Минимальный
      возраст и требования к аренде: При получении автомобиля необходимо предъявить действующее водительское
      удостоверение и паспорт. Минимальный возраст водителя составляет 18 год в зависимости от категории
      автомобилей и следующих требований как показано ниже:
    </Typography>
    <Typography variant='body1' paragraph>
      <ParagraphHeading>Категории и возраст</ParagraphHeading>
      <br />
      Категории B(MBMR), Q(MBAR), C(EBAR), D(ECAR), E(EDAR): Минимальный возраст 18 год (с дополнительной
      оплатой за страхование "молодого водителя" ) + Водительский стаж минимум один год.
      <br />
      Категории F(EZAR), KX(DDAR), I(CCAR), IW(CWAR), H(CDAR), M(SDAR), MH(RDAR), J(SFBR), T(CPMR), G(IVMR),
      U(IVAR): Минимальный возраст 21 год (с дополнительной оплатой за страхование "молодого водителя" ) +
      Водительский стаж минимум один год.
      <br />
      Категории K(PDAR), R(SCAR), O(LFBR), V(SVAR), V8(FVAR), Y(FVMR), Z(LVAR): Минимальный возраст 25 лет +
      Водительский стаж минимум один год.
      <br />
      Категории P(LDAR), W(LCBR): Минимальный возраст 28 лет + Водительский стаж минимум 5 лет.
      <br />
    </Typography>

    <Typography variant='body1' paragraph>
      <ParagraphHeading>Молодой водитель:</ParagraphHeading> Дополнительная пошлина в размере $12 в сутки
      (распространяется на водителей в возрасте от 18 до 23 лет).
    </Typography>

    <TableContainer component={Paper} style={{ marginTop: '16px' }}>
      <Table size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <TableCell align='left' style={{ width: '70%', fontWeight: 600 }}>
              Дополнительные опции
            </TableCell>
            <TableCell align='left' style={{ width: '30%', fontWeight: 600 }}>
              Цена за сутки
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component='th' scope='row'>
              <ExpandedPanel
                title='Дополнительный водитель'
                description='Любой, кто хочет стать авторизованным дополнительным водителем, должен явиться во время аренды и соответствовать возрастным требованиям и иметь при себе требуемый пакет документов в оригинале, если не применяются другие условия договора. Если один из водителей является гражданином Израиля паспорта, НДС в размере 17% будет применяться к общей стоимости аренды.                                '
              />
            </TableCell>
            <TableCell align='left'>$6</TableCell>
          </TableRow>

          <TableRow>
            <TableCell component='th' scope='row'>
              <ExpandedPanel
                title='CSI Детское кресло (предназначен для детей в возрасти 0-1 лет).'
                description=' Любой, кто хочет стать авторизованным дополнительным водителем, должен явиться во время аренды и соответствовать возрастным требованиям и иметь при себе требуемый пакет документов в оригинале, если не применяются другие условия договора. Если один из водителей является гражданином Израиля паспорта, НДС в размере 17% будет применяться к общей стоимости аренды.'
              />
            </TableCell>
            <TableCell align='left'>$6</TableCell>
          </TableRow>

          <TableRow>
            <TableCell component='th' scope='row'>
              <ExpandedPanel
                title='CSB Детское кресло (предназначен для детей в возрасти 1-3 лет). '
                description='По закону государства Израиль детское автокресло является обязательные для детей не достигших 9 лет или ростом до 1,45 см во время поездок на транспортных средствах. Компания по прокату автомобилей может предоставить вам детское автокресло, которое будет использоваться для детей в возрасте до одного года и весом до 9 кг. Данный вид автокресел, не подходит детям с весом более 9 кг или ростом 66 см. Заказать автокресло возможно при оформлении бронирования и требует подтверждения от прокатной компании. Обратите внимание, что стиль детского детского сиденья варьируется в зависимости от станции и компании по прокату автомобилей. Детское автокресло должно быть установлено самим клиентом, сотрудникам проката установка сиденья запрещена.
                            Как использовать:  Автокресло люлька должна быть установлена против направления движения, когда ребенок лежит под углом 45 градусов (полулежа), его тело опирается на сиденье, а голова не двигается вперед в случае экстренного торможения или остановки транспортного средства. Автокресло люлька должно быть отрегулировано в соответствии с ростом и весом ребенка и прикреплено специальными ремнями, встроенными в сиденье. Кроме того, автокресло люлька должно быть закреплено на автомобильном сиденье с помощью стандартного ремня безопасности транспортного средства.'
              />
            </TableCell>
            <TableCell align='left'>$6</TableCell>
          </TableRow>

          <TableRow>
            <TableCell component='th' scope='row'>
              CST Детский бустер (предназначен для детей в возрасти 4 лет и до 7).
            </TableCell>
            <TableCell align='left'>$6</TableCell>
          </TableRow>

          <TableRow>
            <TableCell component='th' scope='row'>
              <ExpandedPanel
                title='CBS Детский бустер (предназначен для детей в возрасти 8 лет и до 12).'
                description='Детский бустер - По закону государства Израиль детское автокресло является обязательные для детей не достигших 9 лет или ростом до 1,45 см во время поездок на транспортных средствах. Компания по прокату автомобилей может предоставить вам автокресло, которое можно использовать по направлению движения для детей весом более 15 кг и ростом до 1,45 см. Данный бустер предназначен для того, чтобы поднять ребенка и позволить ему использовать стандартный ремень безопасности, установленный в автомобиле. Заказать бустер возможно при оформлении бронирования и требует подтверждения от прокатной компании. Обратите внимание, что стиль детского бустера варьируется в зависимости от станции и компании по прокату автомобилей. Детское автокресло должно быть установлено самим клиентом, сотрудникам проката установка сиденья запрещена.
                              Как использовать: детский бустер должен позволять ремню безопасности проходить от плеча ребенка к ключице в область таза. Избегайте ситуаций, когда ремень проходит на уровне шеи или головы ребенка.'
              />
            </TableCell>
            <TableCell align='left'>$6</TableCell>
          </TableRow>

          <TableRow>
            <TableCell component='th' scope='row'>
              <ExpandedPanel
                title='Смартфон – Навигационная система/GPS, включая Бесплатный Смартфон, безлимитные местные и международные звонки, безлимитный интернет и точку доступа WiFi!'
                description='в цену включено бесплатное устройство смартфон(фирма не гарантируется), неограниченные местные и международные телефонные звонки, неограниченную передачу данных и точку доступа Wi-Fi до 5 устройств. Самые популярные приложения для навигации такие как: Waze или Google maps уже установлены.'
              />
            </TableCell>
            <TableCell align='left'>$15</TableCell>
          </TableRow>

          <TableRow>
            <TableCell component='th' scope='row'>
              Экстренная помощь на дороге . Экспресс помощь для следующих услуг: Ключи заперты в автомобиле,
              потерены или поломаны, Помощь при пробитом колесе. Закончилось топливо или разрядился
              аккумулятор? Мы пришлем помощь и поможем завести машину как можно быстрее.
            </TableCell>
            <TableCell align='left'>$4(максимум $100 за весь срок аренды)</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    {/**Services table */}
    <TableContainer component={Paper} style={{ marginTop: '16px' }}>
      <Table size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: isMobile ? '20%' : '30%', fontWeight: 600 }}>Сервисы</TableCell>
            <TableCell style={{ width: '10%', fontWeight: 600 }} align='center'>
              Цена
            </TableCell>
            <TableCell style={{ width: isMobile ? '70%' : '60%', fontWeight: 600 }}>Описание</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component='th' scope='row'>
              VIP клуб ( Сервис для прилетающих)
            </TableCell>
            <TableCell align='center'>$179</TableCell>
            <TableCell>
              (сервис предназначен до 6 пассажиров). Вас встретят возле ворот влета, прохождение паспортного
              контроля в отдельной очереди, помощь с багажом & сопровождение до стойки компании по аренде
              авто.
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component='th' scope='row'>
              VIP клуб ( Сервис для вылетающих)
            </TableCell>
            <TableCell align='center'>$179 ( сервис предназначен до 6 пассажиров)</TableCell>
            <TableCell>
              Выход через специальные ворота в терминале 3, экспресс прохождение службы безопасности и
              регистрации на рейс, сопровождение до вылета через магазины беспошлинной торговли.
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component='th' scope='row'>
              GOLD Private terminal & VIP Lounge ( Сервис для прилетающих){' '}
            </TableCell>
            <TableCell align='center'> $399 за пассажира</TableCell>
            <TableCell>Встреча клиента возле ворот прилёта, трансфер до</TableCell>
          </TableRow>

          <TableRow>
            <TableCell component='th' scope='row'>
              GOLD Private Terminal & VIP Lounge (Сервис для вылетающих)
            </TableCell>
            <TableCell align='center'> $399 за каждого пассажира</TableCell>
            <TableCell>
              После возврата арендованного автомобиля, клиента отвезут в Fattal Private Terminal & Lounge.
              Процедура персональной безопасности & Регистрация на рейс прямо в VIP Lounge. Наслаждайтесь едой
              и напитками (включая алкоголь) а мы в это время позаботимся о вашем багаже прохождении
              паспортного контроля и всех остальных организациях в аэропорту. ВИП Автомобиль отвезет вас от
              Fattal Private Terminal Lounge прямо до самолета или посадочных ворот.
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <br />

    <Typography>
      <ParagraphHeading>Способы оплаты:</ParagraphHeading> Для оформления контракта арендатор должен иметь при
      себе зарегистрированную на его имя кредитную карту для оплаты дополнительных услуг (Мы принимаем
      кредитные карты: Visa, MasterCard, Amex, Diners). Мы принимаем кредитные карты: Visa, MasterCard, Amex,
      Diners.
    </Typography>
  </Conditions>
);

export default RUEuropcarIlRentalConditions;
