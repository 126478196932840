import React, { lazy } from 'react';
import styled from 'styled-components';
import { Paper, useMediaQuery, useTheme } from '@mui/material';
const CarPicker = lazy(() => import('./CarPicker/CarPicker'));

const PickerWrapper = styled(Paper)`
  padding: ${({ ismain, ismobile }) => (ismain ? (ismobile ? '16px' : '24px') : '16px')};
  background: ${({ ismobile }) => (ismobile ? 'rgb(250, 250, 250)' : '#fff')};
`;

const Picker = ({ isMain, pickUpLocation, dropOffLocation, ...rest }) => {
  const ismobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  // const [desktopElevation, setDesktopElevation] = React.useState(0);
  return (
    <PickerWrapper variant='outlined' elevation={0} ismain={isMain ? 1 : 0} ismobile={ismobile ? 1 : 0}>
      <CarPicker {...rest} isMain={isMain} />
    </PickerWrapper>
  );
};

export default Picker;
