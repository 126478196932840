import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

export const updateTo = (to, params) => {
  const entries = Object.entries(params);
  let path = `${to}`;

  entries.forEach(([key, value]) => {
    path = path.replace(`:${key}`, `${value}`);
  });
  return path;
};
const Redirect = ({ to, ...rest }) => {
  const params = useParams();
  return <Navigate to={updateTo(to, params)} {...rest} replace />;
};

export default Redirect;
