import React, { useCallback } from 'react';
import {
  Paper,
  Accordion,
  AccordionSummary,
  Typography,
  FormControlLabel,
  useMediaQuery,
  useTheme,
  Grid,
  Divider
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import FilterByGroupPanel from './FilterByGroupPanel';
import { StyledCheckBox } from '../../Components/AddInsurance/Components/styles/Insuranse.styled';
import { CheckboxArea } from './styles/Filters.styled';
import GearBoxPanel from './Components/GearBoxPanel';
import SuppliersPannel from './Components/SuppliersPanel';
import PriceRangeFilter from './Components/PriceRangeFilter/PriceRangeFilter';
import { minMax } from './utils';
import debounce from 'lodash/debounce';
import FuelTypesPanel from './Components/FuelTypesPanel';

const Filters = ({
  value,
  setValue,
  handleFinalPriceRangeChange,
  currency,
  gearBoxes,
  setGearBoxes,
  fuelTypes,
  setFuelTypes,
  carsClasses,
  setCarsClasses,
  setCheckState,
  checkState,
  cars,
  filteredCars: { filteredCars, setFilteredCars },
  suppliers: { suppliersList, setSuppliersList },
  mileage: { MileageChecked, setMileageChecked }
}) => {
  const theme = useTheme();
  const isPC = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFinalValueChange = useCallback(
    debounce(newValue => handleFinalPriceRangeChange(newValue), 500, [{ leading: true, trailing: false }]),
    []
  );
  const handlePriceRangeChange = (event, newValue) => {
    setValue(newValue);
    handleFinalValueChange(newValue);
  };

  const handleSupplierChecked = (supplier, checked) => {
    const tempSuppliers = [...suppliersList];
    const idx = tempSuppliers.findIndex(s => s.supplier === supplier);
    if (idx > -1) {
      tempSuppliers[idx].checked = checked;
    }
    setSuppliersList(tempSuppliers);
  };

  const CheckBoxFilter = ({ label, type, checked, idx, data }) => {
    const theme = useTheme();
    return (
      <FormControlLabel
        control={
          <StyledCheckBox
            size='small'
            checked={checked}
            //   onChange={handleFilter(label, idx, checked, data)}
            value={type}
            data='Manual'
            checkboxcolor={theme.palette.secondary.main}
          />
        }
        label={<Typography variant='body2'>{label}</Typography>}
      />
    );
  };

  const MileagePanel = () => {
    return (
      <Accordion elevation={0} defaultExpanded={true} style={{ marginTop: isPC ? '16px' : '0px' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel2a-content' id='panel2a-header'>
          <Typography style={{ fontWeight: 600 }}>{t('leftPane.mileageLong')}</Typography>
        </AccordionSummary>
        <CheckboxArea>
          <CheckBoxFilter label={t('leftPane.unlimited')} type='mileage' checked={MileageChecked.Unlimited} />
        </CheckboxArea>
      </Accordion>
    );
  };

  return (
    <Paper elevation={0} style={{ height: '100%', marginTop: '4px' }}>
      <Grid container spacing={1}>
        {/**Price range filter */}
        <Grid item xs={12}>
          <PriceRangeFilter
            value={value}
            handlePriceRangeChange={handlePriceRangeChange}
            currency={currency}
            // handleRangeChange={handleFinalPriceRangeChange}
            // cars={filteredCars.length > 0 ? filteredCars : cars.legth > 0 ? cars : []}
            minMaxPrice={minMax(cars)}
          />
          <Divider />
        </Grid>

        {/** Filter by fuel type */}
        {fuelTypes.legth > 0 && (
          <Grid item xs={12}>
            <FuelTypesPanel
              fuelTypes={fuelTypes}
              setFuelTypes={setFuelTypes}
              filteredCars={filteredCars}
              cars={cars}
              isPC={isPC}
            />
          </Grid>
        )}
        {/**End filter by fuel type */}

        <Grid item xs={12}>
          <FilterByGroupPanel
            setCarsClasses={setCarsClasses}
            carsClasses={carsClasses}
            setCheckState={setCheckState}
            checkState={checkState}
            setFilteredCars={setFilteredCars}
            isPC={isPC}
            filteredCars={filteredCars}
            cars={cars}
          />
          <Divider />
        </Grid>

        {suppliersList.length > 1 && (
          <Grid item lg={12} md={12} sm={4} xs={6}>
            <SuppliersPannel
              handleSupplierChecked={handleSupplierChecked}
              suppliersList={suppliersList}
              isPC={isPC}
              t={t}
            />
            <Divider />
          </Grid>
        )}
        <Grid item xs={12}>
          <GearBoxPanel
            gearBoxes={gearBoxes}
            setGearBoxes={setGearBoxes}
            filteredCars={filteredCars}
            cars={cars}
            isPC={isPC}
          />
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <MileagePanel />
          <Divider />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Filters;
