import {
  Popper,
  Paper,
  ClickAwayListener,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import importantSign from './Icons/importantSign.svg';
import conditionsSign from './Icons/conditions.svg';
import AddOnsDialog from './AddOnsDialog/AddOnsDialog';
import LabelImportantOutlinedIcon from '@mui/icons-material/LabelImportantOutlined';
import { Item, StyledLink } from './styles/InformationSections.styled';
import SupplierImage from '../../../LeftSection/Components/SupplierImage';

const RentalConditionsDialog = lazy(() =>
  import('../RentalConditionsDialog/RentalConditionsDialog')
);

const InformationSection = ({
  apiData,
  carId,
  carSupplierInformation,
  importantItems,
  supplier,
  country,
  apiType,
  reservationInformation,
  sippCode,
}) => {
  const [open, setOpen] = React.useState(false);
  const [addOnsDialogOpen, setAddOnsDialogOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const [popperOpen, setPopperOpen] = React.useState(Boolean(anchorEl));
  React.useEffect(() => {
    // console.log('OPENING', Boolean(anchorEl));
    setPopperOpen(Boolean(anchorEl));
  }, [anchorEl]);

  // useEffect(() => {
  //   console.log('OPEN', popperOpen);
  // }, [popperOpen]);
  const id = popperOpen ? carId : undefined;
  const {
    t,
    i18n: {
      languages: [lng],
    },
  } = useTranslation();

  const addOnsDialogClose = () => {
    setAddOnsDialogOpen(false);
  };
  const handleClose = (action) => {
    setOpen(false);
    if (action === 'refresh') {
      window.location.reload();
    }
  };
  const dialogProps = {
    lng,
    supplier,
    country,
    apiType,
    countryID: carSupplierInformation && carSupplierInformation.countryID,
    apiData,
    sippCode,
  };

  const { direction } = useTheme();
  return (
    <>
      <Grid container spacing={1} alignItems="center">
        {supplier && supplier.image && (
          <Grid style={{ paddingLeft: 0 }}>
            <SupplierImage supplier={supplier} alt={supplier} />
          </Grid>
        )}

        {Number(apiType === 0) ? (
          <Grid>
            <StyledLink>
              <Item
                $dir={direction}
                icon={importantSign}
                onClick={() => setAddOnsDialogOpen(true)}>
                Add-ons
              </Item>
            </StyledLink>
          </Grid>
        ) : (
          ''
        )}
        <Grid item>
          <StyledLink>
            <Item
              $dir={direction}
              icon={conditionsSign}
              onClick={() => {
                return setOpen(true);
              }}>
              {t('footer.rentalconditions')}
            </Item>
          </StyledLink>
        </Grid>
        {importantItems && importantItems.length > 0 && (
          <Grid item>
            <StyledLink onClick={handleClick}>
              <Item $dir={direction} icon={importantSign}>
                {t('carcard.importantInfo')}
              </Item>
            </StyledLink>
          </Grid>
        )}
      </Grid>

      {importantItems && (
        <ClickAwayListener onClickAway={() => {}}>
          <Popper
            style={{ zIndex: 100 }}
            id={id}
            open={popperOpen}
            anchorEl={anchorEl}
            placement="bottom"
            disablePortal={false}
            modifiers={{
              name: 'flip',
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: 'document',
                padding: 8,
              },
            }}>
            <Paper
              elevation={4}
              style={{ padding: 16, border: '1px solid #757575' }}>
              <Typography
                variant="h6"
                style={{ paddingLeft: 16, fontWeight: 600 }}>
                Cancellation fee & excess amounts
              </Typography>
              <List dense={true}>
                {importantItems.map((item) => {
                  return (
                    <ListItem key={item.name}>
                      <ListItemIcon>
                        <LabelImportantOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography>
                          {item.name + ': ' + item.description}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </List>
              <Divider style={{ marginBottom: 16 }} />
              <Typography
                variant="h6"
                style={{ paddingLeft: 16, fontWeight: 600 }}>
                {t('carcard.importantInfo')}
              </Typography>
              <List dense={true}>
                {reservationInformation &&
                  reservationInformation.map((item) => {
                    return (
                      <ListItem key={item.id}>
                        <ListItemIcon>
                          <LabelImportantOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography>
                            {item.name}: {item.description}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    );
                  })}
              </List>
            </Paper>
          </Popper>
        </ClickAwayListener>
      )}

      {addOnsDialogOpen && (
        <AddOnsDialog
          open={addOnsDialogOpen}
          handleClose={addOnsDialogClose}
          {...dialogProps}
          setAddOnsDialogOpen={setAddOnsDialogOpen}
        />
      )}
      {open && (
        <Suspense fallback={<div></div>}>
          <RentalConditionsDialog
            open={open}
            t={t}
            handleClose={handleClose}
            handleAcceptConditions={() => setOpen(false)}
            {...dialogProps}
          />
        </Suspense>
      )}
    </>
  );
};

export default InformationSection;
