const { apiUrl } = require('../../../../utils');

export const getExtrasPricesUrl = (selectedCar, searchDetails, lng, query) => {
  // console.log('selected car', selectedCar);
  const {
    carSupplierInformation,
    sipp_code,
    supplier,
    basePrice: { numberOfDays },
    apiType,
    currencyCode: currency,
  } = selectedCar;
  const {
    pickUpDate,
    pickUpTime,
    dropOffDate,
    dropOffTime,
    country,
    pickUpLocationCode,
    dropOffLocationCode,
    driverAge,
  } = searchDetails;

  return apiUrl(
    `prices/extras?supplier=${supplier.code}&country=${
      country.code
    }&currency=${currency}&sipp=${sipp_code}&lng=${lng}&pickUpDay=${
      query.get('pickUpDay') || pickUpDate.getDate()
    }&pickUpYear=${
      query.get('pickUpYear') || pickUpDate.getFullYear()
    }&pickUpMonth=${
      query.get('pickUpMonth') || pickUpDate.getMonth()
    }&pickUpHour=${query.get('pickUpHour') || pickUpTime}&dropOffYear=${
      query.get('dropOffYear') || dropOffDate.getFullYear()
    }&dropOffMonth=${
      query.get('dropOffMonth') || dropOffDate.getMonth()
    }&dropOffDay=${
      query.get('dropOffDay') || dropOffDate.getDate()
    }&dropOffHour=${query.get('dropOffHour') || dropOffTime}&pl=${
      query.get('pl') || pickUpLocationCode
    }&dl=${
      query.get('dl') || dropOffLocationCode
    }&age=${driverAge}&numberOfDays=${numberOfDays}&apiType=${Number(apiType)}${
      carSupplierInformation && carSupplierInformation.subContractID
        ? `&subContractID=${carSupplierInformation.subContractID}`
        : ''
    }${
      apiType === 3
        ? `&supplierCarGroupID=${carSupplierInformation.suppliercargroupid}`
        : ''
    }${apiType === 3 ? `&countryID=${carSupplierInformation.countryID}` : ''}` +
      getApiParameters(apiType, selectedCar)
  );
};

const getSippCode = (selectedCar) => {
  const { apiType, sipp_code, prices } = selectedCar;
  if (apiType !== 4) {
    return sipp_code;
  }
  if (apiType === 4) {
    const fullInsurances =
      prices && prices.protection_coverage
        ? prices.protection_coverage.map((ins) => ins.code).join('')
        : '';
    return sipp_code + fullInsurances;
  }
};
const getApiParameters = (apiType, selectedCar) => {
  const { apiData } = selectedCar;
  if (!selectedCar) return '';
  switch (apiType) {
    case 4: {
      return `&PULocid=${apiData.PULocid}&DOLocid=${apiData.DOLocid}&QuoteId=${apiData.quoteId}`;
    }

    default:
      return '';
  }
};

const getApis = (location, locationType, usingAPIS) => {
  // api[0][type]=3&api[0][id]=67 => api:[{type:3, id:67, LocationCode:"BNG"}]
  let apisUrl = '';
  if (location?.apis && location.apis.length > 0) {
    location.apis.forEach((api, idx) => {
      const { apiType, countryID, LocationCode } = location.apis[idx];
      if (usingAPIS.includes(apiType)) {
        apisUrl += `&apis[${idx}][loctype]=${locationType}&apis[${idx}][type]=${apiType}${
          countryID ? `&apis[${idx}][id]=${countryID}` : ''
        }${`&apis[${idx}][LocationCode]=${
          LocationCode || location.LocationCode
        }`}`;
      }
    });
  }
  return apisUrl;
};
const getSDParameter = (drivingOnSaturday) => {
  return drivingOnSaturday !== undefined ? `&sd=${drivingOnSaturday}` : '';
};

const getUsingApis = (pickUpLocation, dropOffLocation) => {
  const apis = [];
  if (!pickUpLocation) return apis;
  pickUpLocation.apis.forEach((api) => {
    const { apis: dropAPIS } = dropOffLocation;
    const idx = dropAPIS.findIndex(
      (a) => Number(a.apiType) === Number(api.apiType)
    );
    if (idx > -1) {
      apis.push(api.apiType);
    }
  });
  return apis;
};
export const getFetchCarsUrl = (searchDetails, query, lng, currency) => {
  const usingAPIS = getUsingApis(
    searchDetails.pickUpLocation,
    searchDetails.dropOffLocation
  );
  if (usingAPIS.length > 0) {
    const url = apiUrl(
      `search?pl=${searchDetails.pickUpLocation.LocationCode}${
        searchDetails.pickUpLocation.iataCodeId
          ? `&pickUpstationID=${searchDetails.pickUpLocation.iataCodeId}`
          : ''
      }&dl=${searchDetails.dropOffLocation.LocationCode}${
        searchDetails.dropOffLocation.iataCodeId
          ? `&dropOffstationID=${searchDetails.dropOffLocation.iataCodeId}`
          : ''
      }` +
        `&age=${
          searchDetails.driverAge
        }&pickUpYear=${searchDetails.pickUpDate.getFullYear()}&pickUpMonth=${searchDetails.pickUpDate.getMonth()}&pickUpDay=${searchDetails.pickUpDate.getDate()}&pickUpHour=${
          searchDetails.pickUpTime
        }` +
        `&dropOffYear=${searchDetails.dropOffDate.getFullYear()}&dropOffMonth=${searchDetails.dropOffDate.getMonth()}&dropOffDay=${searchDetails.dropOffDate.getDate()}&dropOffHour=${
          searchDetails.dropOffTime
        }` +
        `&lng=${lng}` +
        `${getSDParameter(searchDetails.drivingOnSaturday)}` +
        `&country=${searchDetails.country.code}${getApis(
          searchDetails.pickUpLocation,
          'pu',
          usingAPIS
        )}${
          searchDetails.dropOffLocation.LocationCode !==
          searchDetails.pickUpLocation.LocationCode
            ? getApis(searchDetails.dropOffLocation, 'do', usingAPIS)
            : ''
        }` +
        `&plt=${searchDetails.pickUpLocation.LocationType}&dlt=${searchDetails.dropOffLocation.LocationType}` +
        `&cur=${currency}`
    );
    return url;
  } else return '';
};
