export const minMax = cars => {
  if (cars.legth === 0 || !cars) return [0, 0];
  else {
    const filteredCars = cars.filter(car => {
      return car.basePrice && car.basePrice.totalBasePrice > 0;
    });
    if (filteredCars.length === 0) return [0, 0];
    const minmax = filteredCars.reduce(
      (acc, curr) => {
        const {
          basePrice: { totalBasePrice }
        } = curr;
        acc[0] = totalBasePrice < 0 ? acc[0] : totalBasePrice < acc[0] ? totalBasePrice : acc[0];
        acc[1] = totalBasePrice < 0 ? acc[1] : totalBasePrice > acc[1] ? totalBasePrice : acc[1];
        return acc;
      },
      [filteredCars[0].basePrice.totalBasePrice, 0]
    );
    return [Math.floor(minmax[0]), Math.ceil(minmax[1])];
  }
};
