import { ru, he, enGB } from 'date-fns/locale';

export const timeSlots = Array.from(new Array(24 * 2)).map(
  (_, index) => `${index < 20 ? '0' : ''}${Math.floor(index / 2)}:${index % 2 === 0 ? '00' : '30'}`
);

export const getLocale = lng => {
  switch (lng) {
    case 'ru':
      return ru;
    case 'en':
      return enGB;
    case 'he':
      return he;
    default:
      return enGB;
  }
};
