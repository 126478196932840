//https://pixabay.com/api/?key=23515356-c1921cf426f304b9a78e5056f&q=corfu+greece&image_type=photo&pretty=true&orientation=horizontal&min_width=1366&min_height=768
import axios from 'axios';
export const getImageFromPixabay = (country, location) => {
  const withLocationImage = location ? `+${location}` : '';
  return axios.get(
    `https://pixabay.com/api/?key=23515356-c1921cf426f304b9a78e5056f&q=${country}${withLocationImage}&image_type=photo&pretty=true&orientation=horizontal&min_width=1366&min_height=768`
  );
};

export const keywords = {
  ru: 'Аренда автомобилей, Прокат машин, Аренда авто, Прокат автомобилей, Автопрокат, Аренда авто по всему миру',
  en: 'Car rental, Rent a car, Car hire, Rental cars, Car rental all over the world',
  he: ''
};
