import React, { useState } from 'react';
import {
  Typography,
  CardHeader,
  Grid,
  Divider,
  useMediaQuery,
  useTheme,
  Hidden,
} from '@mui/material';
import airplaneImage from '../images/plane.svg';
import mapMarker from '../images/pin.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { apiUrl, displayPriceWithCurrency } from '../../../../utils';
import StationDescriptionDialog from './Components/StationDescriptionDialog/StationDescriptionDialog';
import axios from 'axios';
import { getPrice } from './helpers/utils';
import {
  CarImage,
  Icon,
  StyledCard,
  StyledLink,
} from './style/OrderSummary.styled';
import { currencyFormatter } from '../../../../utils/currencyHelpers';

const LocationWithLink = ({
  apiType,
  children,
  handleClicked,
  locationType,
  isLoading,
  isMobile,
}) => {
  return apiType === 3 || apiType === 0 || apiType === 1 || apiType === 4 ? (
    <StyledLink
      onClick={() => handleClicked(locationType, apiType)}
      component="button"
      disabled={isLoading}
      color="inherit"
      underline="always">
      <Typography variant={isMobile ? 'body1' : 'body2'}>{children}</Typography>
    </StyledLink>
  ) : (
    children
  );
};
const SummaryCard = ({
  searchDetails,
  requestedCar,
  extrasPrices,
  currencyCode,
}) => {
  const {
    pickUpDate,
    dropOffDate,
    dropOffTime,
    pickUpTime,
    pickUpLocation,
    dropOffLocation,
  } = searchDetails;

  const {
    carSupplierInformation,
    prices,
    basePrice: { totalBasePriceDisp },
    locations,
    supplier: { name: supplierName },
  } = requestedCar;

  const vatLevel = prices ? prices.vatLevel : undefined;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t, i18n } = useTranslation();
  const lng = i18n.languages[0];
  const [open, setOpen] = useState({ status: false, locationType: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [stationsHTML, setStationsHTML] = useState({
    pickUpStationHTML: {
      title: '',
      page: '',
      zip: '',
      address: '',
      city: '',
      state: '',
    },
    dropOffStationHTML: {
      title: '',
      page: '',
      zip: '',
      address: '',
      city: '',
      state: '',
    },
  });
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const {
    palette: { secondary },
    direction,
  } = theme;

  const getStationsDescription = (url, locationType) => {
    if (stationsHTML.pickUpStationHTML.title) {
      setOpen({ status: true, locationType });
    } else {
      setIsLoading(true);
      axios
        .get(url)
        .then((res) => {
          if (!res.data.pickUpStationHTML || !res.data.dropOffStationHTML) {
            //TODO handle error
          } else {
            setStationsHTML(res.data);
            setOpen({ status: true, locationType });
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(new Error(err));
          setIsLoading(false);
        });
    }
  };

  const handleClicked = (locationType, apiType) => {
    const url = apiUrl(
      `getstationdetails?lng=${lng}&apiType=${apiType}&pickupIataID=${
        pickUpLocation.iataCodeId
      }&dropIataID=${
        dropOffLocation.iataCodeId
      }&pickupDate=${pickUpDate.toLocaleDateString(
        'en-GB'
      )}&pickupHour=${pickUpTime}&dropDate=${dropOffDate.toLocaleDateString(
        'en-GB'
      )}&dropHour=${dropOffTime}${
        carSupplierInformation
          ? `&supplierCarGroupID=${carSupplierInformation.suppliercargroupid}`
          : ''
      }${
        carSupplierInformation
          ? `&supplierPriceCatalogueID=${carSupplierInformation.SupplierPricecatalogueId}`
          : ''
      }&pickUpLocationCode=${pickUpLocation.LocationCode}&dropOffLocationCode=${
        dropOffLocation.LocationCode
      }`
    );
    switch (apiType) {
      case 0:
      case 1:
      case 2:
      case 3:
        getStationsDescription(url, locationType);
        break;

      case 4:
        setStationsHTML({
          pickUpStationHTML: {
            showMap: true,
            page: '',
            coordinates: locations //{latitude: Number, longitude: Number}
              ? {
                  latitude: Number(locations.pickUp.coordinates.lat),
                  longitude: Number(locations.pickUp.coordinates.lng),
                }
              : [],
            city: locations ? locations.pickUp.city : '',
            zip: locations?.pickUp?.zip,
            address: locations?.pickUp?.address,
            title: locations?.pickUp?.title,
            openingHours: locations?.pickUp?.openingHours,
            phone: locations?.pickUp?.phone,
            supplier: supplierName,
          },
          dropOffStationHTML: {
            showMap: true,
            page: '',
            coordinates: locations
              ? {
                  latitude: locations.dropOff.coordinates.lat,
                  longitude: locations.dropOff.coordinates.lng,
                }
              : '',
            city: locations ? locations.dropOff.city : '',
            zip: locations?.dropOff?.zip,
            address: locations?.dropOff?.address,
            title: locations?.dropOff?.title,
            openingHours: locations?.dropOff?.openingHours,
            phone: locations?.dropOff?.phone,
            supplier: supplierName,
          },
        });
        setOpen({ status: true, locationType });
        break;

      default:
        getStationsDescription(url, locationType);

        return;
    }
  };
  const Details = () => {
    const { basePrice } = requestedCar || {};
    const styles = {
      descriptionMargin: `0 ${direction === 'rtl' ? '8px' : 0} 0 ${
        direction === 'ltr' ? '8px' : 0
      }`,
    };
    return (
      <Grid
        container
        direction={'column'}
        spacing={1}
        style={{ paddingTop: 8 }}>
        <Grid item>
          <FontAwesomeIcon icon={faCheck} style={{ color: secondary.main }} />
          <Typography
            component="span"
            variant={isMobile ? 'body2' : 'body1'}
            style={{ margin: styles.descriptionMargin }}>
            {basePrice &&
              displayPriceWithCurrency(
                '',
                getPrice(basePrice, vatLevel, false),
                currencyCode
              )}{' '}
            {t('carcard.priceperday')}
          </Typography>
        </Grid>
        <Grid item>
          <FontAwesomeIcon icon={faCheck} style={{ color: secondary.main }} />
          <Typography
            component={'span'}
            variant={isMobile ? 'body2' : 'body1'}
            style={{ margin: styles.descriptionMargin }}>
            {totalBasePriceDisp
              ? currencyFormatter(currencyCode, totalBasePriceDisp)
              : displayPriceWithCurrency(
                  '',
                  getPrice(basePrice, vatLevel, true),
                  currencyCode
                )}{' '}
            {t('common.perdays.days', {
              count: basePrice ? basePrice.numberOfDays : 0,
            })}
          </Typography>
        </Grid>
        {(requestedCar.mileage || requestedCar.isUnlimitedMileage) && (
          <Grid item>
            <FontAwesomeIcon icon={faCheck} style={{ color: secondary.main }} />
            <span style={{ margin: styles.descriptionMargin }}>
              {t('leftPane.mileageShort')}:{' '}
              {requestedCar.isUnlimitedMileage
                ? t('common.unlimited')
                : basePrice.numberOfDays < 3
                ? `${requestedCar.mileage.daily} ${t('common.kmperday')}`
                : basePrice.numberOfDays > 29
                ? `${requestedCar.mileage.monthly} ${t('common.kmpermonth')}`
                : t('common.unlimited')}
            </span>
          </Grid>
        )}
      </Grid>
    );
  };
  return (
    <StyledCard variant="outlined">
      <CardHeader
        title={requestedCar ? requestedCar.vehicle_type : ''}
        subheader={
          requestedCar
            ? requestedCar.isModelGuaranteed
              ? t('carcard.modelGuaranteed')
              : t('carcard.orsimilar')
            : ''
        }
      />

      <Grid container>
        <Grid item sm={5}>
          <Grid
            container
            justifyContent={!isMobile ? 'space-between' : 'flex-start'}
            style={{
              heigth: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: '8px',
            }}>
            <Grid item xs={12}>
              <CarImage
                $dir={theme.direction}
                ismobile={isMobile.toString()}
                component="img"
                image={requestedCar ? requestedCar.image : ''}
                title="requested car"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Hidden smDown>
            <Divider orientation="vertical" />
          </Hidden>
        </Grid>
        <Grid item sm>
          <Grid container direction="column" style={{ padding: '8px' }}>
            {/**Pick up details */}
            <Grid
              container
              direction="column"
              spacing={1}
              style={{ marginBottom: '8px' }}>
              <Grid item style={{ display: 'flex' }}>
                <Icon src={airplaneImage} alt="plane" />
                <Typography
                  variant={isMobile ? 'body2' : 'body1'}
                  style={{
                    marginLeft: theme.direction === 'ltr' && '8px',
                    marginRight: theme.direction === 'rtl' && '8px',
                  }}>
                  {' '}
                  {t('common.pickUpOnDate')}:{' '}
                  {pickUpDate.toLocaleDateString(i18n.language, options)}{' '}
                </Typography>
              </Grid>
              <Grid item style={{ display: 'flex' }}>
                <Icon src={mapMarker} alt="location" />
                <Typography
                  variant={isMobile ? 'body2' : 'body1'}
                  style={{
                    marginLeft: theme.direction === 'ltr' && '8px',
                    marginRight: theme.direction === 'rtl' && '8px',
                  }}>
                  {' '}
                  <LocationWithLink
                    isMobile={isMobile}
                    isLoading={isLoading}
                    handleClicked={handleClicked}
                    apiType={requestedCar.apiType}
                    locationType="pickUpLocation">
                    {searchDetails.pickUpLocation.LocationName[
                      i18n.languages[0]
                    ] +
                      ', ' +
                      searchDetails.pickUpLocation.country.name[lng]}
                  </LocationWithLink>
                </Typography>
              </Grid>
            </Grid>

            <Divider style={{ margin: '8px 0' }} />

            {/**Drop off details */}

            <Grid
              container
              spacing={1}
              direction="column"
              style={{ marginBottom: '8px' }}>
              <Grid item style={{ display: 'flex' }}>
                <Icon src={airplaneImage} alt="plane" />
                <Typography
                  variant={isMobile ? 'body2' : 'body1'}
                  style={{
                    marginLeft: theme.direction === 'ltr' && '8px',
                    marginRight: theme.direction === 'rtl' && '8px',
                  }}>
                  {t('common.dropOffOnDate')}:{' '}
                  {dropOffDate.toLocaleDateString(
                    i18n.language.includes('ru') ? 'ru' : i18n.language,
                    options
                  )}{' '}
                </Typography>
              </Grid>
              <Grid item style={{ display: 'flex' }}>
                <Icon src={mapMarker} alt="location" />
                <Typography
                  variant={isMobile ? 'body2' : 'body1'}
                  style={{
                    marginLeft: theme.direction === 'ltr' && '8px',
                    marginRight: theme.direction === 'rtl' && '8px',
                  }}>
                  <LocationWithLink
                    isLoading={isLoading}
                    handleClicked={handleClicked}
                    apiType={requestedCar.apiType}
                    locationType="dropOffLocation">
                    {searchDetails.dropOffLocation.LocationName[
                      i18n.languages[0]
                    ] +
                      ', ' +
                      searchDetails.dropOffLocation.country.name[lng]}
                  </LocationWithLink>
                </Typography>
              </Grid>
            </Grid>
            <Divider />

            <Details />
            {/* <Extras arrayOfExtras={displaySelectedExtras()} /> */}
          </Grid>
        </Grid>
      </Grid>
      <StationDescriptionDialog
        location={
          open.locationType === 'pickUpLocation'
            ? pickUpLocation
            : dropOffLocation
        }
        open={open.status}
        setOpen={setOpen}
        htmlPage={
          open.locationType === 'pickUpLocation'
            ? stationsHTML.pickUpStationHTML
            : stationsHTML.dropOffStationHTML
        }
      />
    </StyledCard>
  );
};

export default SummaryCard;
