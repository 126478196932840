import styled from 'styled-components';

export const Content = styled.div`
  color: ${({ $ismobile }) => ($ismobile ? 'rgba(0,0,0,0.87)' : '#fff')};
  padding: ${({ $ismobile }) => ($ismobile ? '48px 16px 16px 16px' : 'auto')};
`;
export const Description = styled.h2`
  font-size: 1.25rem;
  // font-family: Montserrat;
  font-weight: 500;
  line-height: 1.6;
`;
export const Mark = styled.div`
  /* background-color: rgba(20, 194, 169, 0.5); */
  /* color: white; */
  /* padding: 0px 8px; */
`;
