import React from 'react';
import StructuredDataSchema from './StructuredDataSchema';

export const crumb = (position, name, url) => {
  return {
    '@type': 'ListItem',
    position,
    name,
    ...(url && { item: url })
  };
};

const BreadcrumbsSchema = ({ crumbs }) => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [crumbs]
  };
  return <StructuredDataSchema schema={schema} />;
};

export default BreadcrumbsSchema;
