import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import ReactHtmlParser from 'html-react-parser';
import useFetch from '../../../../../../../utils/hooks/useFetch';
import axios from 'axios';
import { apiUrl } from '../../../../../../../utils';

function decodeHtml(html) {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}

const DescriptionDialog = ({ open, setDialogOpen, lng, optionId }) => {
  const [dialogContent, setDialogContent] = React.useState({
    title: '',
    description: ''
  });

  //   useEffect(() => {
  //     console.log('TITLE', dialogContent.title, 'description', dialogContent.description);
  //   }, [dialogContent]);

  React.useEffect(() => {
    if (open) {
      axios
        .get(apiUrl(`additionaloptiondescription?lng=${lng}&id=${optionId}`))
        .then(
          ({
            data: {
              dialogContent: { title, description }
            }
          }) => {
            //   console.log('HEY', res.data.dialogContent.title);
            setDialogContent({ title, description: decodeHtml(description) });
          }
        )
        .catch(err => console.log(err));
    }
  }, [optionId, open]);

  return (
    <Dialog open={open} onClose={() => setDialogOpen(false)}>
      <DialogTitle>{dialogContent.title}</DialogTitle>
      <DialogContent>
        <DialogContentText component={'span'} style={{ color: 'inherit' }}>
          {ReactHtmlParser(dialogContent.description)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDialogOpen(false)}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DescriptionDialog;
