import React from 'react';
import SingleStationDescriptionDialog from './SingleStationDescriptionDialog';
const CommonDialog = ({ dialogOpen: { type, status, payload }, ...rest }) => {
  switch (type) {
    case 'singleStationDescription':
      return (
        <SingleStationDescriptionDialog
          status={status}
          payload={payload}
          {...rest}
        />
      );
    default:
      return null;
  }
};

export default CommonDialog;
