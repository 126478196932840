import countryFlags from '../images/assets/roundBorderFlags';
import countriesFlags from '../images/assets/roundBorderFlags';
// const countries = [
//   { code: 'AD', name: 'Andorra' },
//   { code: 'AF', name: 'Afghanistan' },
//   { code: 'AG', name: 'Antigua and Barbuda', phone: '1-268' },
//   { code: 'AI', name: 'Anguilla', phone: '1-264' },
//   { code: 'AM', name: 'Armenia' },
//   { code: 'AO', name: 'Angola' },
//   { code: 'AQ', name: 'Antarctica' },
//   { code: 'AS', name: 'American Samoa', phone: '1-684' },
//   { code: 'AU', name: 'Australia', suggested: true },
//   { code: 'AW', name: 'Aruba' },
//   { code: 'AX', name: 'Alland Islands' },
//   { code: 'AZ', name: 'Azerbaijan' },
//   { code: 'BA', name: 'Bosnia and Herzegovina' },
//   { code: 'BB', name: 'Barbados', phone: '1-246' },
//   { code: 'BD', name: 'Bangladesh' },
//   { code: 'BF', name: 'Burkina Faso' },
//   { code: 'BH', name: 'Bahrain' },
//   { code: 'BI', name: 'Burundi' },
//   { code: 'BJ', name: 'Benin' },
//   { code: 'BL', name: 'Saint Barthelemy' },
//   { code: 'BM', name: 'Bermuda', phone: '1-441' },
//   { code: 'BN', name: 'Brunei Darussalam' },
//   { code: 'BO', name: 'Bolivia' },
//   { code: 'BR', name: 'Brazil' },
//   { code: 'BS', name: 'Bahamas', phone: '1-242' },
//   { code: 'BT', name: 'Bhutan' },
//   { code: 'BV', name: 'Bouvet Island' },
//   { code: 'BW', name: 'Botswana' },
//   { code: 'BY', name: 'Belarus' },
//   { code: 'BZ', name: 'Belize' },
//   { code: 'CA', name: 'Canada', suggested: true },
//   { code: 'CC', name: 'Cocos (Keeling) Islands' },
//   { code: 'CD', name: 'Congo, Democratic Republic of the' },
//   { code: 'CF', name: 'Central African Republic' },
//   { code: 'CG', name: 'Congo, Republic of the' },
//   { code: 'CI', name: "Cote d'Ivoire" },
//   { code: 'CK', name: 'Cook Islands' },
//   { code: 'CL', name: 'Chile' },
//   { code: 'CM', name: 'Cameroon' },
//   { code: 'CN', name: 'China' },
//   { code: 'CO', name: 'Colombia' },
//   { code: 'CR', name: 'Costa Rica' },
//   { code: 'CU', name: 'Cuba' },
//   { code: 'CV', name: 'Cape Verde' },
//   { code: 'CW', name: 'Curacao' },
//   { code: 'CX', name: 'Christmas Island' },
//
//   { code: 'DJ', name: 'Djibouti' },
//   { code: 'DK', name: 'Denmark' },
//   { code: 'DM', name: 'Dominica', phone: '1-767' },
//   { code: 'DO', name: 'Dominican Republic', phone: '1-809' },
//   { code: 'DZ', name: 'Algeria' },
//   { code: 'EC', name: 'Ecuador' },
//   { code: 'EG', name: 'Egypt' },
//   { code: 'EH', name: 'Western Sahara' },
//   { code: 'ER', name: 'Eritrea' },
//   { code: 'ET', name: 'Ethiopia' },
//   { code: 'FJ', name: 'Fiji' },
//   { code: 'FK', name: 'Falkland Islands (Malvinas)' },
//   { code: 'FM', name: 'Micronesia, Federated States of' },
//   { code: 'FO', name: 'Faroe Islands' },
//   { code: 'GA', name: 'Gabon' },
//   { code: 'GD', name: 'Grenada', phone: '1-473' },
//   { code: 'GE', name: 'Georgia' },
//   { code: 'GF', name: 'French Guiana' },
//   { code: 'GG', name: 'Guernsey' },
//   { code: 'GH', name: 'Ghana' },
//   { code: 'GI', name: 'Gibraltar' },
//   { code: 'GL', name: 'Greenland' },
//   { code: 'GM', name: 'Gambia' },
//   { code: 'GN', name: 'Guinea' },
//   { code: 'GQ', name: 'Equatorial Guinea' },
//   { code: 'GS', name: 'South Georgia and the South Sandwich Islands' },
//   { code: 'GT', name: 'Guatemala' },
//   { code: 'GU', name: 'Guam', phone: '1-671' },
//   { code: 'GW', name: 'Guinea-Bissau' },
//   { code: 'GY', name: 'Guyana' },
//   { code: 'HK', name: 'Hong Kong' },
//   { code: 'HM', name: 'Heard Island and McDonald Islands' },
//   { code: 'HN', name: 'Honduras' },
//   { code: 'HT', name: 'Haiti' },
//   { code: 'ID', name: 'Indonesia' },
//   { code: 'IM', name: 'Isle of Man' },
//   { code: 'IN', name: 'India' },
//   { code: 'IO', name: 'British Indian Ocean Territory' },
//   { code: 'IQ', name: 'Iraq' },
//   { code: 'IR', name: 'Iran, Islamic Republic of' },
//   { code: 'JE', name: 'Jersey' },
//   { code: 'JO', name: 'Jordan' },
//   { code: 'JP', name: 'Japan', suggested: true },
//   { code: 'KE', name: 'Kenya' },
//   { code: 'KG', name: 'Kyrgyzstan' },
//   { code: 'KH', name: 'Cambodia' },
//   { code: 'KI', name: 'Kiribati' },
//   { code: 'KM', name: 'Comoros' },
//   { code: 'KN', name: 'Saint Kitts and Nevis', phone: '1-869' },
//   { code: 'KP', name: "Korea, Democratic People's Republic of" },
//   { code: 'KR', name: 'Korea, Republic of' },
//   { code: 'KW', name: 'Kuwait' },
//   { code: 'KY', name: 'Cayman Islands', phone: '1-345' },
//   { code: 'KZ', name: 'Kazakhstan' },
//   { code: 'LA', name: "Lao People's Democratic Republic" },
//   { code: 'LB', name: 'Lebanon' },
//   { code: 'LC', name: 'Saint Lucia', phone: '1-758' },
//   { code: 'LI', name: 'Liechtenstein' },
//   { code: 'LK', name: 'Sri Lanka' },
//   { code: 'LR', name: 'Liberia' },
//   { code: 'LS', name: 'Lesotho' },
//   { code: 'LY', name: 'Libya' },
//   { code: 'MC', name: 'Monaco' },
//   { code: 'MD', name: 'Moldova, Republic of' },
//   { code: 'MG', name: 'Madagascar' },
//   { code: 'MH', name: 'Marshall Islands' },
//   { code: 'ML', name: 'Mali' },
//   { code: 'MM', name: 'Myanmar' },
//   { code: 'MN', name: 'Mongolia' },
//   { code: 'MO', name: 'Macao' },
//   { code: 'MP', name: 'Northern Mariana Islands', phone: '1-670' },
//   { code: 'MQ', name: 'Martinique' },
//   { code: 'MR', name: 'Mauritania' },
//   { code: 'MS', name: 'Montserrat', phone: '1-664' },
//   { code: 'MU', name: 'Mauritius' },
//   { code: 'MV', name: 'Maldives' },
//   { code: 'MW', name: 'Malawi' },
//
//   { code: 'MY', name: 'Malaysia' },
//   { code: 'MZ', name: 'Mozambique' },
//   { code: 'NA', name: 'Namibia' },
//   { code: 'NC', name: 'New Caledonia' },
//   { code: 'NE', name: 'Niger' },
//   { code: 'NF', name: 'Norfolk Island' },
//   { code: 'NG', name: 'Nigeria' },
//   { code: 'NI', name: 'Nicaragua' },
//   { code: 'NO', name: 'Norway' },
//   { code: 'NP', name: 'Nepal' },
//   { code: 'NR', name: 'Nauru' },
//   { code: 'NU', name: 'Niue' },
//   { code: 'NZ', name: 'New Zealand' },
//   { code: 'OM', name: 'Oman' },
//   { code: 'PA', name: 'Panama' },
//   { code: 'PF', name: 'French Polynesia' },
//   { code: 'PG', name: 'Papua New Guinea' },
//   { code: 'PH', name: 'Philippines' },
//   { code: 'PK', name: 'Pakistan' },
//   { code: 'PM', name: 'Saint Pierre and Miquelon' },
//   { code: 'PN', name: 'Pitcairn' },
//   { code: 'PR', name: 'Puerto Rico' },
//   { code: 'PS', name: 'Palestine, State of' },
//   { code: 'PW', name: 'Palau' },
//   { code: 'PY', name: 'Paraguay' },
//   { code: 'QA', name: 'Qatar' },
//   { code: 'RE', name: 'Reunion' },
//   { code: 'RU', name: 'Russian Federation' },
//   { code: 'RW', name: 'Rwanda' },
//   { code: 'SA', name: 'Saudi Arabia' },
//   { code: 'SB', name: 'Solomon Islands' },
//   { code: 'SC', name: 'Seychelles' },
//   { code: 'SD', name: 'Sudan' },
//   { code: 'SG', name: 'Singapore' },
//   { code: 'SH', name: 'Saint Helena' },
//   { code: 'SJ', name: 'Svalbard and Jan Mayen' },
//   { code: 'SL', name: 'Sierra Leone' },
//   { code: 'SM', name: 'San Marino' },
//   { code: 'SN', name: 'Senegal' },
//   { code: 'SO', name: 'Somalia' },
//   { code: 'SR', name: 'Suriname' },
//   { code: 'SS', name: 'South Sudan' },
//   { code: 'ST', name: 'Sao Tome and Principe' },
//   { code: 'SV', name: 'El Salvador' },
//   { code: 'SX', name: 'Sint Maarten (Dutch part)', phone: '1-721' },
//   { code: 'SY', name: 'Syrian Arab Republic' },
//   { code: 'SZ', name: 'Swaziland' },
//   { code: 'TC', name: 'Turks and Caicos Islands', phone: '1-649' },
//   { code: 'TD', name: 'Chad' },
//   { code: 'TF', name: 'French Southern Territories' },
//   { code: 'TG', name: 'Togo' },
//   { code: 'TH', name: 'Thailand' },
//   { code: 'TJ', name: 'Tajikistan' },
//   { code: 'TK', name: 'Tokelau' },
//   { code: 'TL', name: 'Timor-Leste' },
//   { code: 'TM', name: 'Turkmenistan' },
//   { code: 'TN', name: 'Tunisia' },
//   { code: 'TO', name: 'Tonga' },
//   { code: 'TT', name: 'Trinidad and Tobago', phone: '1-868' },
//   { code: 'TV', name: 'Tuvalu' },
//   { code: 'TW', name: 'Taiwan, Province of China' },
//   { code: 'TZ', name: 'United Republic of Tanzania' },
//   { code: 'UG', name: 'Uganda' },
//   { code: 'UY', name: 'Uruguay' },
//   { code: 'UZ', name: 'Uzbekistan' },
//   { code: 'VA', name: 'Holy See (Vatican City State)' },
//   { code: 'VC', name: 'Saint Vincent and the Grenadines', phone: '1-784' },
//   { code: 'VE', name: 'Venezuela' },
//   { code: 'VG', name: 'British Virgin Islands', phone: '1-284' },
//   { code: 'VI', name: 'US Virgin Islands', phone: '1-340' },
//   { code: 'VN', name: 'Vietnam' },
//   { code: 'VU', name: 'Vanuatu' },
//   { code: 'WF', name: 'Wallis and Futuna' },
//   { code: 'WS', name: 'Samoa' },
//   { code: 'XK', name: 'Kosovo' },
//   { code: 'YE', name: 'Yemen' },
//   { code: 'YT', name: 'Mayotte' },
//   { code: 'ZA', name: 'South Africa' },
//   { code: 'ZM', name: 'Zambia' },
//   { code: 'ZW', name: 'Zimbabwe' }
// ];
const currentCountries = [
  { code: 'ch', name: 'Switzerland', flag: countryFlags.ch },

  { code: 'al', name: { ru: 'Албания', en: 'Albania', he: 'אלבניה' }, flag: countryFlags.al },
  //   { code: 'ar', name: { ru: 'Аргентина', en: 'Argentina', he: 'ארגנטינה' } },
  {
    code: 'at',
    name: { en: 'Austria', ru: 'Австрия', he: 'אוסטריה' },
    flag: countryFlags.at,
    isLanding: false
  },
  { code: 'be', name: { en: 'Belgium', ru: 'Бельгия', he: 'בלגיה' }, flag: countriesFlags.be, image: '' },
  {
    code: 'bg',
    name: { ru: 'Болгария', en: 'Bulgaria', he: 'בולגריה' },
    flag: countryFlags.bg,
    image: 'https://i.imgur.com/cm1nONn.jpg'
  },
  {
    code: 'cy',
    name: { en: 'Cyprus', ru: 'Кипр', he: 'קפריסין' },
    flag: countryFlags.cy,
    image: 'https://i.imgur.com/FivgM0U.jpg'
  },
  {
    code: 'cz',
    name: { en: 'Czech Republic', ru: 'Чехия', he: "צ'כיה" },
    flag: countriesFlags.cz,
    image: ''
  },
  { code: 'gb', name: { en: 'United Kingdom', ru: '', he: '' }, flag: countryFlags.gb },

  { code: 'ie', name: { en: 'Ireland', ru: 'Ирландия', he: 'אירלנד' }, flag: countryFlags.ie },
  { code: 'nl', name: { en: 'Netherlands', ru: 'Нидерланды', he: 'הולנד' }, flag: countryFlags.nl },

  { code: 'sk', name: { en: 'Slovakia', ru: 'Словакия', he: 'סלובקיה' }, flag: countriesFlags.sk },
  { code: 'se', name: { en: 'Sweden', ru: 'Швеция', he: 'שבדיה' }, flags: countriesFlags.se },

  {
    code: 'de',
    name: { ru: 'Германия', en: 'Germany', he: 'גרמניה' },
    flag: countryFlags.de,
    isLanding: false
  },

  { code: 'il', name: { ru: 'Израиль', en: 'Israel', he: 'ישראל' }, flag: countryFlags.il },
  {
    code: 'hr',
    name: { ru: 'Хорватия', en: 'Croatia', he: 'קרואטיה' },
    flag: countryFlags.hr,
    image: 'https://i.imgur.com/T3n11mo.jpg'
  },
  {
    code: 'ee',
    name: { ru: 'Эстония', en: 'Estonia', he: 'אסטוניה' },
    flag: countryFlags.ee,
    image: 'https://i.imgur.com/rjI2jhM.jpg'
  },
  { code: 'es', name: { en: 'Spain', ru: 'Испания', he: 'ספרד' }, flag: countryFlags.es, isLanding: true },

  {
    code: 'fi',
    name: { ru: 'Финляндия', en: 'Finland', he: 'פינלנד' },
    image: 'https://i.imgur.com/uzuZxh9.jpg',
    flag: countryFlags.fi
  },
  {
    code: 'fr',
    name: { en: 'France', ru: 'Франция', he: 'צפרת' },
    flag: countryFlags.fr,
    isLanding: true,
    image: 'https://i.imgur.com/Z57IFvY.jpg'
  },

  {
    code: 'gr',
    name: { ru: 'Греция', en: 'Greece', he: 'יוון' },
    image: 'https://i.imgur.com/PFYeNSe.jpg',
    flag: countryFlags.gr
  },
  { code: 'hu', name: { en: 'Hungary', ru: 'Венгрия', he: 'הונגריה' }, flag: countriesFlags.hu },

  { code: 'gp', name: { ru: 'Гваделупа', en: 'Guadeloupe', he: 'גוואדלופה' } },
  { code: 'is', name: { ru: 'Исландия', en: 'Iceland', he: 'איסלנד' }, flag: countryFlags.is },
  { code: 'it', name: { en: 'Italy', ru: 'Италия', he: 'איטליה' }, isLanding: false, flag: countryFlags.it },

  { code: 'jm', name: { ru: 'Ямайка', en: 'Jamaica', he: 'ג׳מייקה' } },
  { code: 'lv', name: { ru: 'Латвия', en: 'Latvia', he: 'לטביה' }, flag: countryFlags.lv },
  { code: 'lt', name: { ru: 'Литва', en: 'Lithuania', he: 'ליטא' }, flag: countriesFlags.lt },
  { code: 'lu', name: { en: 'Luxembourg', ru: 'Люксембург', he: 'לוקסמבורג' }, flag: countriesFlags.lu },

  {
    code: 'mk',
    name: { ru: 'Македония', en: 'Macedonia the Former Yugoslav Republic of', he: 'מקדוניה' },
    phone: '389'
  },

  { code: 'mt', name: { ru: 'Мальта', en: 'Malta', he: 'מלטה' }, flag: countryFlags.mt },
  { code: 'mx', name: 'Mexico', flag: countriesFlags.mx },
  { code: 'me', name: { ru: 'Черногория', en: 'Montenegro', he: 'מונטנגרו' }, flag: countryFlags.me },
  { code: 'ma', name: { ru: 'Мороко', en: 'Morocco', he: 'מרוקו' } },
  { code: 'pe', name: { ru: 'Перу', en: 'Peru', he: 'פרו' } },
  { code: 'pl', name: { ru: 'Польша', en: 'Poland', he: 'פולין' }, flag: countryFlags.pl },
  {
    code: 'pt',
    name: { en: 'Portugal', ru: 'Португалия', he: 'פורטוגל' },
    flag: countryFlags.pt,
    isLanding: true
  },
  { code: 'ro', name: { ru: 'Румыния', en: 'Romania', he: 'רומניה' }, flag: countryFlags.ro },
  // { code: 'mf', name: { ru: 'Сен-Мартен', en: 'Saint Martin (French part)', he: 'סן-מרטן' } },
  { code: 'si', name: { en: 'Slovenia', ru: 'Словения', he: 'סלובניה' }, flag: countryFlags.si },

  { code: 'rs', name: { ru: 'Сербия', en: 'Serbia', he: 'סרביה' }, flag: countryFlags.rs },
  { code: 'tr', name: { ru: 'Турция', en: 'Turkey', he: 'טורקיה' } },
  // { code: 'ua', name: { ru: 'Украина', en: 'Ukraine', he: 'אוקראינה' }, flag: countryFlags.ua },
  {
    code: 'us',
    name: { en: 'United States', ru: 'США', he: 'ארצות הברית' },
    isLanding: true,
    flag: countryFlags.us
  },

  {
    code: 'ae',
    name: {
      ru: 'Объединенные Арабские Эмираты',
      en: 'United Arab Emirates',
      he: 'איחוד האמירויות הערביות׳'
    },
    image: 'https://i.imgur.com/bgqpBFG.jpg',
    flag: countryFlags.ae
  }
];
const sortCountriesByName = lng => {
  const sortedCountries = currentCountries.sort((a, b) =>
    a.name[lng].localeCompare(b.name[lng], lng, { sensitivity: 'base' })
  );
  return sortedCountries;
};
export const getCountryByISOCode = ISOCode => {
  return currentCountries.find(country => country.code === ISOCode);
};
const getCountries = lng => {
  return sortCountriesByName(lng);
};
export const getCountriesWithFlags = lng => {
  const sortedCountries = sortCountriesByName(lng);
  return sortedCountries.map(country => {
    country.flag = countriesFlags[country.code];
    return country;
  });
};
export const getCountryFlagByCode = code => {
  const country = currentCountries.find(country => country.code === code);
  if (!country) return null;
  else return country.flag;
};
export const getCountryNameByCode = code => {
  return currentCountries.find(country => country.code === code).name;
};
export default getCountries;
