import React from 'react';

const RouteWithLayout = ({ layout: Layout, component: Component, ...rest }) => {
  return (
    <>
      {Layout ? (
        <Layout title={rest.title} {...rest}>
          <Component {...rest} />
        </Layout>
      ) : (
        <Component {...rest} />
      )}
    </>
  );
};

export default RouteWithLayout;
