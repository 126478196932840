import {
  Button,
  Dialog,
  Divider,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import ReactHtmlParser from 'html-react-parser';
import LocationMap from './LocationMap';
import StationDescriptionContent from './StationDescriptionContent';

const StationDescriptionDialog = ({
  open,
  setOpen,
  htmlPage,
  location,
  lng,
}) => {
  const {
    zip,
    address,
    city,
    state,
    coordinates,
    showMap,
    phone,
    openingHours,
    supplier,
  } = htmlPage;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { direction } = theme;
  return (
    <Dialog
      dir={direction}
      fullScreen={isMobile}
      open={open}
      onClose={() => setOpen({ status: false, locationType: '' })}>
      <DialogTitle>
        <Typography component="span" variant="h5">
          {htmlPage.title}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent
        style={{
          padding: isMobile ? '8px 8px' : '8px 24px',
          whiteSpace: 'normal',
        }}>
        {htmlPage.page ? (
          ReactHtmlParser(htmlPage.page)
        ) : (
          <StationDescriptionContent {...htmlPage} />
        )}
        {showMap && (
          <LocationMap
            title={htmlPage.title}
            isMobile={isMobile}
            locationName={htmlPage.title}
            location={location}
            zip={zip}
            address={address}
            city={city}
            state={state}
            coordinates={coordinates}
            phone={phone}
            openingHours={openingHours}
            supplier={supplier}
          />
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setOpen({ status: false, locationType: '' })}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StationDescriptionDialog;
