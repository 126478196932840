import { Typography } from '@mui/material';
import React from 'react';
import { StyledTooltip } from '../../PCCarCard/Components/LeftSection/Components/Title/Title.styled';

const OrSimilarTitle = ({ t }) => {
  return (
    <Typography variant='body2' style={{ color: 'black' }}>
      {t('carcard.orsimilarTitle')}
    </Typography>
  );
};

const OrSimilar = ({ t, isModelGuaranteed }) => {
  return (
    <StyledTooltip   title={<OrSimilarTitle t={t} />} arrow>
      <>{t('carcard.orsimilar')}</>
    </StyledTooltip>
  );
};

const Subtitle = ({ isModelGuaranteed, year, t, sipp_code }) => {
  const carYear = year ? ' | ' + year : '';
  return (
    <>
      {isModelGuaranteed ? t('carcard.modelGuaranteed') : <OrSimilar t={t} />} {carYear} | {sipp_code}
    </>
  );
};

export default Subtitle;
