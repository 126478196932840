import { handleSelectedExtras, handleSelectedInsurances } from './utils';
import { apiUrl, getCarClassBySippCode } from '../../../../utils';
import { format } from 'date-fns';
import axios from 'axios';
import { createLead } from '../../../../apiRequests';

const validateAndSendEmail = async ({
  isLead,
  orderData: {
    extrasPrices,
    searchDetails,
    userOrder,
    currencyCode,
    personalDetails,
    requestedCar,
    uuid,
    additionalOptions,
    totalPrice,
  },
  setIsLoading,
  lng,
  t,
  sendEmail,
  agent,
}) => {
  if (!isLead) {
    setIsLoading(true);
  }
  const { apiType } = requestedCar;
  const dateFormat = 'yyyy-MM-dd';
  let userExtras = handleSelectedExtras(extrasPrices, lng, t, apiType);
  const { insurances, carBasePrice, ...otherDetails } = userOrder;
  const userInsurances = handleSelectedInsurances(insurances);
  let { pickUpDate, dropOffDate, ...rest } = searchDetails;
  pickUpDate = format(searchDetails.pickUpDate, dateFormat);
  dropOffDate = format(searchDetails.dropOffDate, dateFormat);
  let data = {
    uuid,
    carBasePrice,
    currencyCode,
    pickUpDate,
    dropOffDate,
    ...rest,
    sippCode: requestedCar.sipp_code,
    otherDetails,
    userExtras,
    userInsurances,
    RequestRate: requestedCar.RequestRate,
    total: totalPrice,
    ...personalDetails,
    language: lng,
    supplier: requestedCar.supplier,
    vehicle_type: requestedCar.vehicle_type,
    carGroup: getCarClassBySippCode(
      requestedCar.sipp_code,
      requestedCar.car_group,
      t
    ),
    vehicleImageURL: requestedCar.image,
    requestedCar,
    additionalOptions,
    agent,
  };
  let url;

  console.log("data", data);

  if (isLead) {
    return createLead(data);
  }
  url = apiUrl(sendEmail ? 'confirmorder' : 'saveneworder');
  return axios.post(url, data);
};

export default validateAndSendEmail;
