import React from 'react';
import { Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import Header from '../Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { CurrencyContext } from '../../SharedState';
import { BlueHeader, BodyWrapper, ContentBackground } from './styles/StaticPage.styled';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
const Footer = React.lazy(() => import('../Footer/Footer'));

const StaticPage = ({ title, language, children }) => {
  const params = useParams();
  const [currency, setCurrency] = React.useState({
    code: 'USD',
    sign: '$',
    icon: <FontAwesomeIcon style={{ fontSize: '20px' }} icon={faEuroSign} />
  });
  const { t } = useTranslation();
  if (params.company) {
    const { companyname } = params;
    title = t('common.aboutCompany', { company: companyname });
  }
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <CurrencyContext.Provider value={[currency.icon, setCurrency]}>
        <Header withShadow={false} withoutLogo={false} />
      </CurrencyContext.Provider>

      <div>
        <BlueHeader $ismobile={isMobile}>
          <Container>
            <Typography
              variant='h1'
              style={{
                fontSize: isMobile ? '32px' : '48px',
                color: 'rgb(250,250,250)',
                textAlign: 'center',
                fontWeight: 900,
                marginTop: isMobile ? '0px' : '64px'
              }}
            >
              {title}
            </Typography>
          </Container>
        </BlueHeader>
        <BodyWrapper ismobile={isMobile ? 1 : 0}>
          <ContentBackground ismobile={isMobile ? 1 : 0}>{children}</ContentBackground>
        </BodyWrapper>
      </div>
      <React.Suspense fallback={<div></div>}>
        <Footer />
      </React.Suspense>
    </>
  );
};

export default StaticPage;
