import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'html-react-parser';
import { Container } from '@mui/material';
import { getLocationPage } from '../../../../../../apiRequests';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const AboutLocationSection = ({ country, location }) => {
  const [page, setPage] = useState({ html: '' });
  const {
    i18n: {
      languages: [lng]
    }
  } = useTranslation();
  useEffect(() => {
    getLocationPage(country, location, lng)
      .then(({ data: { success, page } }) => {
        if (success) {
          setPage(page);
        } else {
          setPage({ html: '' });
        }
      })
      .catch(err => console.log(err));
  }, [location, country, lng]);

  return (
    <>
      {page.html && (
        <div style={{ backgroundColor: '#fafafa' }}>
          <Container style={{ whiteSpace: 'normal', paddingTop: 32 }}>{ReactHtmlParser(page.html)}</Container>
        </div>
      )}
    </>
  );
};

export default AboutLocationSection;
