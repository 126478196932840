export const getSteps = (isMobile, t, isPaymentRequired) => {
  const steps = [
    t(`stepper.${isMobile ? 'mobile' : 'nonMobile'}.chooseYourCar`),
    t(`stepper.${isMobile ? 'mobile' : 'nonMobile'}.additionalOptions`),
    t(`stepper.${isMobile ? 'mobile' : 'nonMobile'}.orderSummary`)
  ];
  if (isPaymentRequired) {
    return [...steps, t('stepper.nonMobile.payment')];
  } else return [...steps, t(`stepper.${isMobile ? 'mobile' : 'nonMobile'}.orderConfirmation`)];
};
/**
 * Generates uuid for identifying in db after payment
 * @returns uuid
 */
export const getUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

// export const getLocationByLocationCode = (locationCode, country, language) => {
//   return (country === 'il' ? europcarLocations : country === 'bg' ? trcLocations : []).find(
//     ({ LocationCode }) => LocationCode === locationCode
//   );
// };

export const removeDuplicates = array => {
  array = array.filter(supplier => supplier.supplier !== undefined);
  return Array.from(new Set(array.map(a => a.supplier))).map(supplier => {
    return array.find(a => a.supplier === supplier);
  });
};

export const processInsurances = (insurances, descriptions) => {
  if (insurances.length === 0) {
    return [];
  } else {
    return insurances
      .map((insurance, idx) => {
        const insuranceDescriptionItem = descriptions.find(item => item.code === insurance.code);
        return {
          ...insurance,
          name: insuranceDescriptionItem ? insuranceDescriptionItem.name : insurance.name,
          price: insurance.price,
          description: insuranceDescriptionItem
            ? insuranceDescriptionItem.description
            : insurance.description
            ? insurance.description
            : '',
          monthly: insurance.monthly,
          isChecked: parseFloat(insurance.price) === 0 || insurance.hasToBeIncluded || false
        };
      })
      .sort((a, b) => (a.hasToBeIncluded && b.hasToBeIncluded) || a.price - b.price);
  }

  //

  // return insurances.map(insurance => {
  //   return { ...insurance, isChecked: insurance.price === 0 ? true : false };
  // });
};

/**
 *
 * @param {Object} extras
 * @param {Number} driverAge
 * @param {String} supplier
 * @param {String} country
 * @param {String} pickUpLocation
 * @param {String} dropOffLocation
 * @param {Date} pickUpDate
 * @param {Date} dropOffDate
 */
export const processExtras = (
  extras,
  driverAge,
  supplier,
  country,
  pickUpLocation,
  dropOffLocation,
  pickUpDate,
  dropOffDate,
  selectedCarExtras
) => {
  const allExtras = selectedCarExtras ? [...extras, ...selectedCarExtras] : extras;
  allExtras.forEach((extra, idx) => {
    allExtras[idx].isChecked = allExtras[idx].required || false;
    allExtras[idx].count = 0;
    if (extra.required) {
      extra.count = 1;
      extra.isChecked = true;
    }
  });
  return allExtras;
};

export const handleSelectedExtras = (userExtras, language, t, apiType) => {
  return userExtras.reduce((acc, currentExtra) => {
    if (currentExtra.count > 0) {
      const name = currentExtra.name;
      const { description, ...extra } = currentExtra;
      extra.name = name ? name : currentExtra.name.en;
      if (currentExtra.code === 'CRB') {
        if (apiType !== 3) {
          currentExtra.countries.forEach(country => (country.name = t(`${country.name}`, { lng: 'en' })));
        }
        extra.countries = currentExtra.countries.filter(country => country.isChecked);
      }
      return [...acc, extra];
    } else return acc;
  }, []);
};

export const handleSelectedInsurances = insurances => {
  return insurances.reduce((acc, currentInsurance) => {
    if (currentInsurance.isChecked) {
      const { description, isChecked, isPricePerRent, hasToBeIncluded, monthly, ...insurance } =
        currentInsurance;
      return [...acc, insurance];
    } else return acc;
  }, []);
};

export const getPathNameByStep = step => {
  switch (step) {
    case 0:
      return 'cc'; //car choose
    case 1:
      return 'ei'; //extras and insurances
    case 2:
      return 'os'; //order summary
    case 3:
      return 'p'; //payment
    case 4:
      return 'c'; //confirmation
  }
};
export const getStepByParam = param => {
  switch (param) {
    case 'cc':
      return 0;
    case 'ei':
      return 1;
    case 'os':
      return 2;
    case 'p':
      return 3;
    case 'c':
      return 4;
  }
};

/**
 * Calculates the one-way fee based on the given user order, requested car, and prices.
 *
 * @param {Object} userOrder - The user order object.
 * @param {Object} requestedCar - The requested car object.
 * @param {Object} prices - The prices object.
 * @return {number} The calculated one-way fee.
 */
export const getOneWayFee = (userOrder, requestedCar, prices) => {
  const { apiType } = requestedCar;
  switch (apiType) {
    case 0:
      return prices.find(extra => extra.code === 'OWF')?.count === 1
        ? prices.find(extra => extra.code === 'OWF').price
        : 0;

    case 4: {
      const owf = requestedCar.prices.fees?.find(f => f.code === 'OWF');
      if (owf) {
        return owf.price;
      } else return 0;
    }
    default:
      return 0;
  }
};
