import { Container, Stepper } from '@mui/material';
import styled from 'styled-components';

export const StyledContainer = styled(Container)`
  padding-top: 50px;
  margin-top: ${props => (props.ismobile ? '0px' : '50px')};
`;
export const StyledStepper = styled(Stepper)`
  &.MuiStepper-root {
    .MuiStepIcon-active {
      color: ${({ circlecolor }) => circlecolor};
    }
    .MuiStepIcon-text {
    font-size: 13px;
  }
    padding: ${({ ismobile }) => (ismobile ? '4px' : '16px')};
  }
  .MuiStepLabel-label {
    font-size: ${({ ismobile }) => (ismobile ? '12px' : 'inherit')};
  }
`;
