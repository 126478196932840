import { useTheme } from '@mui/material';
import { Skeleton } from '@mui/lab';
import React from 'react';
import styled from 'styled-components';

const Image = styled.img`
  display: ${({ isLoading }) => (!isLoading ? 'none' : 'block')};
  width: 350px;
  /* max-width: 350px;
  max-height: 150px; */
  margin-top: ${({ isModelGuaranteed }) => (isModelGuaranteed ? 5 : 0)}rem;
  /* margin-top: ${({ isModelGuaranteed }) => (isModelGuaranteed ? -30 : 0)}px;
  margin-bottom: ${({ isModelGuaranteed }) =>
    isModelGuaranteed ? 30 : 0}px; */
  transform: ${({ direction }) =>
    direction === 'rtl' ? 'scaleX(-1)' : 'none'};
`;

const CarImage = ({ image, carModel, isLoading, isModelGuaranteed }) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);

  const { direction } = useTheme();
  return (
    <div style={{ textAlign: direction === 'ltr' ? 'left' : 'right' }}>
      {!imageLoaded && <Skeleton variant="rect" width={350} height={165} />}
      <Image
        isModelGuaranteed={isModelGuaranteed}
        isLoading={imageLoaded}
        alt={carModel}
        src={image}
        direction={direction}
        onLoad={() => {
          return setImageLoaded(true);
        }}
        onError={() => console.log('ERROR LOAD IMAGE')}
      />
    </div>
  );
};

export default CarImage;
