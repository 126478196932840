import React from 'react';
import { useTheme, Grid, useMediaQuery } from '@mui/material';
import Picker from '../../../Layouts/Picker/Picker';
import { useTranslation } from 'react-i18next';
import LocationDescription from './Components/LocationDescription';
import { PickerContainer, StyledContainer, StyledSection } from './styles/MainSection.styled';
import BreadCrumbs from './Components/BreadCrumbs/BreadCrumbs';
// const PromoSlider = lazy(() => import('./PromoSlider'));
// const Companies = lazy(() => import('./Companies'));

const MainSection = ({ country, location, title, locationName, ...rest }) => {
  const {
    t,
    i18n: { languages }
  } = useTranslation();
  const lng = languages[0];
  // const [countryName, setCountryName] = useState('');

  // useEffect(() => {
  //   // const lang=lng==='ru'?'rus':lng===''
  //   if (country) {
  //     const url = `https://restcountries.com/v3.1/alpha/${country}`;
  //     console.log(url);
  //     axios
  //       .get(url)
  //       .then(({ data }) => console.log(data))
  //       .catch(err => console.log(err));
  //   }
  // }, [country]);
  const theme = useTheme();
  const ismobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <StyledSection ismobile={ismobile ? 1 : 0}>
      <StyledContainer $ismobile={ismobile ? 1 : 0}>
        <Grid
          container
          direction='column'
          justifyContent='center'
          style={{ height: '100%', alignContent: 'center' }}
        >
          <BreadCrumbs locationName={locationName} country={country} isMobile={ismobile} />

          <Grid item style={{ marginBottom: 24 }}>
            <LocationDescription country={country} lng={languages[0]} title={title} ismobile={ismobile} />
          </Grid>
          
          <PickerContainer container ismobile={ismobile ? 1 : 0}>
            <Grid
              item
              xs={12}
              sm={10}
              md={12}
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Picker isMain={true} {...rest} />
            </Grid>
          </PickerContainer>
        </Grid>
        {/* <Hidden mdDown>
          <Grid item>
            <Companies />
          </Grid>
        </Hidden> */}
      </StyledContainer>
    </StyledSection>
  );
};

export default MainSection;
