import { Grid, Typography } from "@mui/material";
import { Skeleton } from "@mui/lab";
import React from "react";
import styled from "styled-components";
import { displayPriceWithCurrency } from "../../../../../utils";
import { useTranslation } from "react-i18next";

const TotalPrice = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  text-align: ${({ $direction }) => ($direction === "rtl" ? "right" : "left")};
`;

const WrappedCompoenent = ({ children, isMobile, direction }) => {
  return isMobile ? (
    children
  ) : (
    <TotalPrice $direction={direction}>{children}</TotalPrice>
  );
};

const TotalPricePerPeriod = ({
  displayPrice,
  isLoading,
  numberOfDays,
  price,
  totalBasePrice,
  currencyCode,
  isMobile,
  direction,
  vatLevel,
}) => {
  const { t } = useTranslation();
  return (
    <Grid item>
      {isLoading ? (
        <Skeleton variant="rect" width={230} height={45} />
      ) : (
        <WrappedCompoenent isMobile={isMobile} direction={direction}>
          {displayPrice ? (
            <Typography variant="body2">
              {displayPriceWithCurrency(
                undefined,
                totalBasePrice
                  ? totalBasePrice
                  : parseFloat((price * numberOfDays).toFixed(2)),
                currencyCode
              )}{" "}
              {t("common.perdays.days", { count: numberOfDays })}
            </Typography>
          ) : (
            <></>
          )}
        </WrappedCompoenent>
      )}
    </Grid>
  );
};

export default TotalPricePerPeriod;
