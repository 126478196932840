import styled from 'styled-components';

export const StyledApp = styled.div`
  background-image: ${({ ismobile, withoutBackground, $backgroundImage }) => {
    return ismobile || withoutBackground ? 'transparent' : `url(${$backgroundImage})`;
  }};
  background-color: rgb(250, 250, 250);
  background-size: cover;
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
  &:before {
    content: '';
    background-color: ${({ withoutBackground, ismobile }) => {
      return withoutBackground || ismobile ? 'none' : 'black';
    }};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0.7;
  }
`;
