import React from 'react';

import countryFlags from '../../../../../images/assets/roundBorderFlags';
const Flag = ({ language }) => {
  const fl =
    countryFlags[
      language === 'he' ? 'il' : language === 'en' ? 'us' : language
    ];
  if (!language) return <></>;

  return <img src={fl} alt="flag" style={{ width: '20px' }}></img>;
};

export default Flag;
