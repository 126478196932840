import { List, ListItem, useTheme } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { getSippCodeFullDescription } from '../../../../../../../../../../utils/utils';

const Item = styled.div`
  font-size: 14px;
`;

const Wrapper = styled.div`
  width: 250px;
  padding: 4px;
  direction: ${({ $direction }) => $direction};
`;

const LI = styled(ListItem)`
  &.MuiListItem-dense {
    padding-top: 2px;
    padding-bottom: 2px;
  }
`;

const SippCodesTooltipContent = ({ sipp_code, t, lng }) => {
  const descriptions = getSippCodeFullDescription(sipp_code, t, lng);
  const { direction } = useTheme();

  return (
    <Wrapper $direction={direction}>
      {' '}
      <List dense>
        {descriptions.map((description, idx) => {
          return (
            <LI disableGutters key={idx}>
              <Item>
                <b>{description.letter}</b> <span>&#8594;</span>{' '}
                {description.name}
              </Item>
            </LI>
          );
        })}
      </List>
      <Item>
        {' '}
        {t('common.readMoreAboutSippCodes')}{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.we4rent.com/${lng}/articles/sipp-codes`}>
          {t('common.link')}
        </a>
      </Item>
    </Wrapper>
  );
};

export default SippCodesTooltipContent;
