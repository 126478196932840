import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import suppliers from '../../../images/suppliers/suppliers';
import { useTranslation } from 'react-i18next';
import MobileCard from './Components/MobileCard/MobileCard';
import PCCarCard from './Components/PCCarCard/PCCarCard';

const CarCard = ({
  reservationInformation,
  importantItems,
  numberOfSeats,
  numberOfLargeBags,
  numberOfSmallBags,
  numberOfDoors,
  typeOfGearBox,
  supplier,
  basePrice,
  isLoading,
  isComercial,
  specifications,
  numberOfAdults,
  numberOfKids,
  ...rest
}) => {
  const { period } = basePrice || '';
  const {
    i18n: { languages }
  } = useTranslation();
  const theme = useTheme();
  const supplierImagePath = suppliers[supplier];
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const carFeaturesProps = {
    transmissionType: typeOfGearBox,
    numberOfSeats,
    numberOfLargeBags,
    numberOfSmallBags,
    numberOfDoors,
    numberOfAdults,
    numberOfKids
  };
  
  const cardProps = !isLoading && {
    carFeaturesProps,
    supplierImage: supplierImagePath,
    supplier,
    period,
    basePrice,
    language: languages[0],
    importantItems,
    reservationInformation
  };
  let comercialFeaturesProps = {};
  if (isComercial) {
    comercialFeaturesProps = {
      transmissionType: typeOfGearBox,
      numberOfSeats,
      weight: specifications.weight,
      maxLoading: specifications.maxLoading,
      loadCapacity: specifications.loadCapacity,
      trunkLength: specifications.trunkLength,
      internalHeight: specifications.internalHeight,
      licenseType: specifications.licenseType
    };
  }
  return (
    <>
      {isMobile ? (
        <MobileCard {...cardProps} isLoading={isLoading} carFeaturesProps={carFeaturesProps} {...rest} />
      ) : (
        <>
          <PCCarCard {...cardProps} isLoading={isLoading} carFeaturesProps={carFeaturesProps} {...rest} />
        </>
      )}
    </>
  );
};

export default CarCard;
