import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import  {faCircleMinus} from '@fortawesome/free-solid-svg-icons/faCircleMinus';
import  {faCircleCheck}  from '@fortawesome/free-solid-svg-icons/faCircleCheck';

const SelectedIcon = ({ selected, mouseOver }) => {
  return selected ? (
    <IconButton style={{ width: 12, height: 12 }}>
      {mouseOver ? (
        <FontAwesomeIcon
          icon={faCircleMinus}
          style={{
            color: '#ff1744',
            width: 14,
            height: 14
          }}
        />
      ) : (
        <FontAwesomeIcon icon={faCircleCheck} style={{ color: '#50C2A9', width: 14, height: 14 }} />
      )}
    </IconButton>
  ) : (
    <div style={{ height: 12, width: 12 }}></div>
  );
};
export default SelectedIcon;
