import { Link } from 'react-router-dom';

const Body = ({ language }) => {
  switch (language) {
    case 'ru':
      return (
        <>
          <p>Мы не смогли найти страницу, которую вы искали.</p>
          <p style={{ lineHeight: 1.4 }}>
            {' '}
            Если вы ищете машину на прокат, то перейдите на главную страницу по <Link to='/'>ссылке</Link> или
            воспользуйтесь формой ниже.
            <br />
            Вы также можете связаться с нами, чтобы заказать машину или по другим вопросам через форму на{' '}
            <Link to='/contact'>этой</Link> странице или по телефону{' '}
            <a href='tel:+972542041614'>+972542041614</a>
          </p>
        </>
      );
    case 'en':
      return (
        <>
          <p>We can't find the page you are looking for.</p>
          <p style={{ lineHeight: 1.4 }}>
            If you are looking for car hire, please visit our <Link to='/'>home page</Link> or use the webform
            down on the page. <br />
            You also cant contact us to rent a car through the <Link to='/contact'>form</Link> on our website
            or by phone<a href='tel:+972542041614'>+972542041614</a>
          </p>
        </>
      );
    case 'he':
      return (
        <>
          <p> אנו מתנצלים, אך לא הצלחנו לאתר את הדף שאתה מחפש</p>
          <p style={{ lineHeight: 1.4 }}>
            אם את/ה מחפש/ת רכב להשכרה ניתן לעשות זאת מדף <Link to='/'>הבית</Link> או בטופס למטה
            <br />
            את/ה מוזמנ/ת ליצור קשר דרך <Link to='/contact'>העמוד</Link> באתר או דרך הטלפון{' '}
            <a href='tel:+972542041614'>+972542041614</a>
          </p>
        </>
      );
    default:
      return <></>;
  }
};
export default Body;
