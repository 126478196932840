import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

export const LoadingItem = styled.div`
  width: 70px;
  height: 60px;
`;

export const StyledCircularProgress = styled(CircularProgress)`
  color: black;
  opacity: 0.7;
`;
