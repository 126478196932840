import React from 'react';
import { Typography, List, useTheme, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';
import {
  LinkColor,
  StyledDivider,
  StyledLink,
  StyledListItem,
} from './styles/QuickLinks.styled';

const PopularDestination = ({ url, destinationName }) => (
  <StyledListItem>
    <StyledLink to={url}>
      <LinkColor>{destinationName}</LinkColor>
    </StyledLink>
  </StyledListItem>
);

const QuickLinks = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { t } = useTranslation();
  const destinations = [
    { url: '/country/bg', destinationName: t('countries.bg.bg') },
    {
      url: '/israel/populardestinations/tel-aviv',
      destinationName: t('cities.tel-aviv'),
    },
    {
      url: '/bulgaria/populardestinations/bansko',
      destinationName: t('cities.bansko'),
    },
    {
      url: '/bulgaria/populardestinations/sofia',
      destinationName: t('cities.sofia'),
    },
    {
      url: '/uae/populardestinations/dubai',
      destinationName: t('cities.dubai'),
    },
    {
      url: '/slovenia/populardestinations/slovenia',
      destinationName: t('countries.slovenia'),
    },
  ];
  return (
    <Grid container alignContent="flex-start">
      <Grid style={{ padding: `16px 16px 16px ${isMobile ? '16px' : '0px'}` }}>
        <Typography style={{ color: '#fafafa', opacity: 0.7, fontSize: 16 }}>
          {t('footer.helpLinks')}
        </Typography>
        <StyledDivider />
        <List dense>
          <StyledListItem>
            <StyledLink to="/about">
              <LinkColor>{t('footer.about')}</LinkColor>
            </StyledLink>
          </StyledListItem>
          <StyledListItem>
            <StyledLink to="/simcards">
              <LinkColor>{t('promotions.simcard.simcardsInIsrael')}</LinkColor>
            </StyledLink>
          </StyledListItem>
          <StyledListItem>
            <StyledLink to="/fleet">
              <LinkColor>{t('fleet.ourFleet')}</LinkColor>
            </StyledLink>
          </StyledListItem>
          <StyledListItem>
            <StyledLink to="/contact">
              <LinkColor>{t('footer.contact')}</LinkColor>
            </StyledLink>
          </StyledListItem>
          <StyledListItem>
            <StyledLink to="/articles">
              <LinkColor>{t('breadcrumbs.articles')}</LinkColor>
            </StyledLink>
          </StyledListItem>
          <StyledListItem>
            <StyledLink to="/all-countries">
              <LinkColor>{t('footer.allcountries')}</LinkColor>
            </StyledLink>
          </StyledListItem>
        </List>
      </Grid>
      <Grid style={{ padding: '16px' }}>
        <Typography
          style={{
            color: '#fafafa',
            opacity: 0.7,
            textAlign: 'center',
            fontSize: 16,
          }}>
          {t('footer.carRentalCompanies')}
        </Typography>
        <StyledDivider />
        <Grid container>
          <Grid>
            <List dense>
              <StyledListItem>
                <StyledLink to="/partners/avis/name/Avis" replace>
                  Avis
                </StyledLink>
              </StyledListItem>
              <StyledListItem>
                <StyledLink to="/partners/locauto/name/Locauto" replace>
                  Locauto
                </StyledLink>
              </StyledListItem>
              <StyledListItem>
                <StyledLink
                  to="/partners/sicilybycar/name/Sicily by Car"
                  replace>
                  Sicily by Car
                </StyledLink>
              </StyledListItem>

              <StyledListItem>
                <StyledLink to="/partners/toprentacar/name/Top%20Rent%20A%20Car">
                  Top Rent A Car
                </StyledLink>
              </StyledListItem>
              <StyledListItem>
                <StyledLink href="#">Alamo</StyledLink>
              </StyledListItem>
              <StyledListItem>
                <StyledLink href="#">Europcar</StyledLink>
              </StyledListItem>
            </List>
          </Grid>
          <Grid>
            <List dense>
              <StyledListItem>
                <StyledLink to="/partners/hertz/name/Hertz">Hertz</StyledLink>
              </StyledListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ padding: '16px' }}>
        <Typography
          style={{
            color: '#fafafa',
            opacity: 0.7,
            textAlign: 'center',
            fontSize: 16,
          }}>
          {t('home.secondsection.head')}
        </Typography>
        <StyledDivider />
        <List dense>
          {destinations.map((destination, idx) => (
            <PopularDestination
              destinationName={destination.destinationName}
              url={destination.url}
              key={idx}
            />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default QuickLinks;
