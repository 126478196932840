import { getUUID } from '../../utils/utils';

const handleSubmitDriverDetaillsForm = ({
  values,
  setPersonalDetails,
  personalDetails,
  handleSaveNewOrder,
  paymentRequired,
  isLead,
}) => {
  let { phone, phoneCode, ...rest } = values;
  phone = '+' + phoneCode.code + '-' + phone;
  const uuid = getUUID();
  setPersonalDetails({
    ...personalDetails,
    ...rest,
    phone,
    uuid,
  });
  const orderData = { ...rest, phone };
  handleSaveNewOrder(
    orderData, //Driver details
    !paymentRequired, //send email
    uuid, //uuid
    isLead
  );
};
export default handleSubmitDriverDetaillsForm;
