import fi from './finland.svg';
import bg from './bulgaria.svg';
import il from './israel.svg';
import gr from './greece.svg';
import hr from './croatia.svg';
import lt from './lithuania.svg';
import lv from './latvia.svg';
import mt from './malta.svg';
// import ae from './united-arab-emirates.svg';
import ee from './estonia.svg';
import pl from './poland.svg';
import ua from './ukraine.svg';
import rs from './serbia.svg';
import ro from './romania.svg';
import me from './montenegro.svg';
import is from './iceland.svg';
import al from './albania.svg';
import it from './italy.svg';
import de from './germany.svg';
import fr from './france.svg';
import at from './austria.svg';
import es from './spain.svg';
import pt from './portugal.svg';
import us from './usa.svg';
import cy from './cy.svg';
import be from './belgium.svg';
import cz from './czech-republic.svg';
import hu from './hungary.svg';
import mx from './252-mexico.svg';
import ch from './205-switzerland.svg';
import ru from './russia.svg';
import tr from './218-turkey.svg';
import si from './010-slovenia.svg';
import sk from './091-slovakia.svg';
import lu from './luxembourg.svg';
import se from './184-sweden.svg';
import ie from './179-ireland.svg';
import nl from './237-netherlands.svg';
import uae from './uae.svg';
import gb from './uk.svg';
import no from './no.svg';

const countryFlags = {
  no,
  gb,
  nl,
  ie,
  se,
  lu,
  sk,
  si,
  tr,
  ru,
  ch,
  mx,
  hu,
  cz,
  be,
  cy,
  is,
  me,
  ro,
  rs,
  ua,
  pl,
  ee,
  fi,
  bg,
  il,
  gr,
  hr,
  lv,
  lt,
  mt,
  uae,
  al,
  it,
  de,
  fr,
  at,
  es,
  pt,
  us
};
export default countryFlags;
