import { getCountryNameByCode } from '../../../data';
import { generateOrderId } from '../../../utils/utils';
import { getInitialLocation } from './getInitialLocation';
import { addDays } from 'date-fns';

export const initLocation = {
  name: { en: '', ru: '', he: '' },
  LocationName: {
    en: '',
    ru: '',
    he: '',
  },
  country: { code: '', name: '' },
  city: { en: '', ru: '', he: '' },
  LocationCode: '',
  iataCodeId: '',
};
export const getInitialState = async (urlParams) => {
  const date = new Date();
  const pickUpTime = `${('0' + ((date.getHours() + 4) % 24)).slice(-2)}:00`;
  const pickupLocationCode = urlParams.get('pl') || null;
  const dropOffLocationCode = urlParams.get('dl') || null;
  // const dropOffDate = new Date(date.setDate(date.getDate() + 3));
  // console.log('HEY DATE', addDays(date, 3));
  if (!pickupLocationCode && !dropOffLocationCode) {
    return { state: {}, shouldUpdate: false };
  }
  const state = {
    currency: '',
    country: {
      code: urlParams.get('country') || null,
      name: urlParams.get('country')
        ? getCountryNameByCode(urlParams.get('country'))
        : null,
    },
    pickUpLocation: await getInitialLocation(urlParams, true),
    dropOffLocation: await getInitialLocation(urlParams, false),
    pickUpDate: new Date(),
    pickUpTime: urlParams.get('pickUpHour') || pickUpTime,
    dropOffDate: addDays(date, 3),
    dropOffTime: urlParams.get('dropOffHour') || pickUpTime,
    driverAge: urlParams.get('age') || '',
    drivingOnSaturday: true,
    landing: {
      isLanding: false,
      selectedCar: '',
    },
    orderId: generateOrderId(),
  };
  return { state, shouldUpdate: true };
};
