import React from 'react';
import { currencyFormatter } from '../../../../../../../utils/currencyHelpers';
import { List, ListItem, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Policies = ({ policies, currency }) => {
  const { t } = useTranslation();
  return (
    <Paper>
      <Typography variant='h6' style={{ padding: '0 16px' }}>
        {t('common.policies')}
      </Typography>
      <List dense>
        {policies &&
          policies.length > 0 &&
          policies.map(item => (
            <ListItem>
              <Typography>
                {item.name}:{' '}
                <span style={{ fontWeight: 500 }}>
                  {item.price > 0 ? currencyFormatter(currency.code, item.price) : item.restrictions}
                </span>
              </Typography>
            </ListItem>
          ))}
      </List>
    </Paper>
  );
};

export default Policies;
