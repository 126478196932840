import { TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  displayPriceWithCurrency,
  getMaxPrice,
} from '../../../../../../../utils';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { StyledTooltip } from '../../../../../../Layouts/CarCard/Components/PCCarCard/Components/LeftSection/Components/Title/Title.styled';

const ExtrasRow = ({
  orderItem: {
    code,
    count,
    price,
    maxPrice,
    isPricePerRent,
    isPayOnArrival,
    name,
  },
  isInsurance,
  currency,
  numberOfDays,
  description,
}) => {
  const { t } = useTranslation();
  return (
    <TableRow>
      <TableCell
        component="th"
        scope="row"
        style={{ display: 'flex', alignItems: 'center' }}>
        {name}
        {count > 1 ? ` x${count}` : ''}
        {description && (
          <StyledTooltip
            enterTouchDelay={0}
            title={
              <Typography variant="body2" style={{ color: 'black' }}>
                {description}
              </Typography>
            }
            arrow
            style={{ color: 'black' }}>
            <HelpOutlineIcon fontSize="small" style={{ marginLeft: 4 }} />
          </StyledTooltip>
        )}
      </TableCell>
      <TableCell align="right">
        {price === 0
          ? t('common.included')
          : isPayOnArrival
          ? t('common.payOnArrival') +
            `(${displayPriceWithCurrency(
              '',
              +getMaxPrice(
                price,
                !isInsurance ? maxPrice : 0,
                numberOfDays,
                isPricePerRent,
                !isInsurance ? count : 1,
                code
              ).toFixed(2),
              currency
            )})`
          : displayPriceWithCurrency(
              '',
              +getMaxPrice(
                price,
                !isInsurance ? maxPrice : 0,
                numberOfDays,
                isPricePerRent,
                !isInsurance ? count : 1,
                code
              ).toFixed(2),
              currency
            )}
      </TableCell>
    </TableRow>
  );
};

export default ExtrasRow;
