import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  Typography,
  FormControlLabel,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { StyledCheckBox } from '../../Components/AddInsurance/Components/styles/Insuranse.styled';
import { CheckboxArea } from './styles/Filters.styled';

const FilterByGroupPanel = ({ setCarsClasses, carsClasses, isPC }) => {
  const [expanded, setExpanded] = useState(true);
  const { t } = useTranslation();

  const handleFilterByGroup = (checked, value, type) => {
    let tempClasses = [...carsClasses];
    if (type === 'e') {
      //electric
      const idx = tempClasses.findIndex((c) => c.type === 'e');
      tempClasses[idx].isChecked = checked;
    } else {
      const idx = tempClasses.findIndex((cl) => cl.carClass === value);
      tempClasses[idx].isChecked = checked;
    }
    setCarsClasses(tempClasses);
  };
  const theme = useTheme();
  return (
    <Accordion
      elevation={0}
      onChange={(e, isExpanded) => setExpanded(isExpanded)}
      expanded={expanded}
      style={{ marginTop: isPC ? '16px' : '0px' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography style={{ fontWeight: 600 }}>
          {isPC
            ? t('leftPane.filterByGroupCarLong')
            : t('leftPane.filterByGroupCarShort')}
        </Typography>
      </AccordionSummary>

      <CheckboxArea style={{ display: 'flex', flexDirection: 'column' }}>
        {carsClasses.map((item, idx) => {
          return (
            <FormControlLabel
              key={item.carClass}
              control={
                <StyledCheckBox
                  size="small"
                  checked={carsClasses[idx].isChecked}
                  onChange={({ target }) =>
                    handleFilterByGroup(
                      target.checked,
                      target.value,
                      target.name
                    )
                  }
                  value={item.carClass}
                  checkboxcolor={theme.palette.secondary.main}
                  name={item.type}
                />
              }
              label={<Typography variant="body2">{item.carClass}</Typography>}
            />
          );
        })}
      </CheckboxArea>
    </Accordion>
  );
};

export default FilterByGroupPanel;
