import isracardLogo from './isra-logo.png';
import lockLogo from './lock-logo.png';
import mastercardLogo from './mastercard-logo.png';
import pciLogo from './Pci-logo.png';
import visaLogo from './visa-logo.png';

const logos = [
  { src: isracardLogo, alt: 'isracard logo' },
  { src: mastercardLogo, alt: 'mastercard logo' },
  { src: visaLogo, alt: 'visa logo' },
  { src: pciLogo, alt: 'pci logo' },
  { src: lockLogo, alt: 'lock logo' }
];

export default logos;
