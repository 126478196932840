import Grid from '@mui/material/Grid2';
import styled from 'styled-components';

export const PriceSection = styled(Grid)`
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  line-height: 48px;
  color: #14c2a9;
`;

export const PriceContainer = styled(Grid)`
  /* border: 1px solid; */
  /* height: 100%; */
`;
