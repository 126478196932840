import React from 'react';
import { IconButton, Hidden, Container, useTheme, Link, useMediaQuery } from '@mui/material';
import logo from './images/logo.svg';
import NavMenu from './NavMenu/NavMenu';
import MenuIcon from '@mui/icons-material/Menu';
import { Logo, StyledAppBar, SytledToolbar } from './styles/Navbar.styled';

const NavBar = ({
  toggleDrawer,
  menuItems,
  withShadow,
  withoutLogo,
  isTransparent,
  position,
  headerWithBackground
}) => {
  const ismobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  return (
    <StyledAppBar
      style={{ background: !isTransparent ? '#16596B' : 'transparent' }}
      // $showgradient={ismobile || headerWithBackground}
      elevation={withShadow ? 1 : 0}
      position='relative'
      color={isTransparent || headerWithBackground ? 'transparent' : 'secondary'}>
      <Container>
        <SytledToolbar>
          <Hidden smUp>
            <IconButton
              aria-labelledby='Hamburger Menu'
              onClick={toggleDrawer(true)}
              edge='start'
              color='inherit'>
              <MenuIcon />
            </IconButton>
          </Hidden>
          {!withoutLogo ? (
            <Link href='/' style={{ cursor: 'pointer', textDecoration: 'none' }}>
              <Logo ismobile={ismobile ? 1 : 0} loading='eager' src={logo} alt='We4rent logo'></Logo>
            </Link>
          ) : (
            <div></div>
          )}

          <NavMenu menuItems={menuItems} />
        </SytledToolbar>
      </Container>
    </StyledAppBar>
  );
};

export default NavBar;
