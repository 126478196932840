import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  RUEuropcarIlRentalConditions,
  ENEuropcarIlRentalConditions,
} from './suppliers/europcar';

import SupplierRentalConditions from './suppliers/topRentACar/SupplierRentalConditions';

export default function RentalConditionsText({
  supplier: requestedSupplier,
  country: requestedCountry,
  apiType,
  ...rest
}) {
  const supplier = requestedSupplier.code
    ? requestedSupplier.code
    : requestedSupplier;
  const country = requestedCountry.code
    ? requestedCountry.code
    : requestedCountry;
  const {
    i18n: {
      languages: [lng],
    },
  } = useTranslation();

  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  if (apiType === 0) {
    if (lng === 'ru') {
      return <RUEuropcarIlRentalConditions isMobile={isMobile} />;
    }
    if (lng === 'he') {
      return (
        <SupplierRentalConditions {...rest} country="il" supplier={supplier} />
      );
    } else {
      return <ENEuropcarIlRentalConditions isMobile={isMobile} />;
    }
  } else
    return (
      <SupplierRentalConditions
        {...rest}
        apiType={apiType}
        country={country}
        supplier={supplier}
      />
    );
}
