import { Divider, ListItem } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledListItem = styled(ListItem)`
  &:hover: {
    color: #14c2a9;
  }
  &.MuiListItem-gutters {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #fafafa;
  opacity: 0.7;
  &:hover {
    color: #14c2a9;
  }
`;
export const LinkColor = styled.span`
  color: #fafafa;
  &:hover {
    color: #14c2a9;
  }
`;
export const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;
