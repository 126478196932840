import { AppBar, Toolbar } from '@mui/material';
import styled from 'styled-components';

export const StyledAppBar = styled(AppBar)`
  /* background: ${props => props.$showgradient && props.theme.palette.primary.mainGradient}; */
  display: flex;
  z-index: 1100;
`;

export const SytledToolbar = styled(Toolbar)`
  color: white;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
`;

export const Logo = styled.img`
  width: 110px;
  height: ${({ ismobile }) => (ismobile ? '20px' : '25px')};
`;
