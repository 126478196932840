import Avis from './avis.png';
import Hertz from './hertz.png';
import Thrifty from './thrifty.png';
import Europcar from './europcar.png';
import Budget from './budget.svg';
import Dollar from './dollar.gif';
import TopRentACar from './toprentacar.png';
import Locauto from './locauto.png';
import SicilyByCar from './sicilybycar.png';
import addCar from './addCar.png';
import enterprice from './enterprise.gif';

const toprentacarSupplier = {
  supplierCode: 'toprentacar',
  supplierName: 'Top Rent A Car',
  logo: TopRentACar
};
const sicilybycarSupplier = {
  supplierCode: 'sicilybycar',
  supplierName: 'Sicily By Car',
  logo: SicilyByCar
};

const suppliers = [
  { supplierCode: 'avis', supplierName: 'Avis', logo: Avis },
  { supplierCode: 'hertz', supplierName: 'Hertz', logo: Hertz },
  { supplierCode: 'thrifty', supplierName: 'Thrifty', logo: Thrifty },
  { supplierCode: 'europcar', supplierName: 'Europcar', logo: Europcar },
  { supplierCode: 'toprentacar', supplierName: 'Top Rent A Car', logo: TopRentACar },
  { supplierCode: 'locauto', supplierName: 'Locauto', logo: Locauto },
  { supplierCode: 'sicilybycar', supplierName: 'Sicily by Car', logo: SicilyByCar },
  { supplierCode: 'addcar', supplierName: 'addCar', logo: addCar },
];
const topSuppliers = [
  { supplierCode: 'europcar', supplierName: 'Europcar', logo: Europcar },
  { supplierCode: 'avis', supplierName: 'Avis', logo: Avis },
  { supplierCode: 'hertz', supplierName: 'Hertz', logo: Hertz },
  { supplierCode: 'thrifty', supplierName: 'Thrifty', logo: Thrifty },
  { supplierCode: 'locauto', supplierName: 'Locauto', logo: Locauto },
  { supplierCode: 'addcar', supplierName: 'addCar', logo: addCar },
  { supplierCode: 'enterprise', supplierName: 'Enterprice', logo: enterprice }
];
export const getSupplierBySupplierCode = supplierCode => {
  return suppliers.find(supplier => supplier.supplierCode === supplierCode);
};

export const getSearchAnimationSuppliers = country => {
  const suppliers = [...topSuppliers];
  switch (country) {
    case 'bg':
      suppliers.unshift(toprentacarSupplier);
      break;
    case 'it':
      suppliers.unshift(sicilybycarSupplier);
      break;
    default:
      break;
  }
  return suppliers;
};
export default suppliers;
