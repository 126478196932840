import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import RentalConditionsText from '../../../../../../../../StaticPages/RentalConditionsText/RentalConditionsText';
import {
  StyledDialog,
  StyledTitle,
} from './style/RentalConditionsDialog.styled';

const RentalConditionsDialog = ({
  rentalConditions,
  supplier,
  country,
  handleClose,
  t,
  lng,
  open,
  handleAcceptConditions,
  ...rest
}) => {
  const theme = useTheme();
  const { direction, breakpoints } = theme;
  const ismobile = useMediaQuery(breakpoints.down('sm'));
  const [action, setAction] = React.useState(null);

  return (
    <StyledDialog
      fullScreen={ismobile}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={()=>handleClose(action)}
      scroll="paper">
      <StyledTitle
        dir={direction}
        style={{ textAlign: direction === 'rtl' ? 'right' : 'left' }}>
        {supplier ? supplier.name : ''} {t('carcard.policies.rentalconditions')}
      </StyledTitle>
      <DialogContent dividers={true}>
        {
          <RentalConditionsText
            supplier={supplier}
            country={country}
            lng={lng}
            setAction={setAction}
            {...rest}
          />
        }
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (action === 'refresh') {
              handleClose('refresh');
              window.location.reload();
            }
            return handleAcceptConditions();
          }}>
          Ok
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default RentalConditionsDialog;
